import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"

export const SET_SHOWN_TILES = "SET_SHOWN_TILES"

export type ReduxInitAction = { type: "@@INIT" }

export type setShownTilesAction = {
  type: typeof SET_SHOWN_TILES
  shownTiles: MY_SCREEN_TILE_IDS[]
}

export type myScreenState = {
  shownTiles: MY_SCREEN_TILE_IDS[]
}

export type Action = ReduxInitAction | setShownTilesAction

export type Store = ReduxStore<myScreenState, Action>

export type Dispatch = ReduxDispatch<Action>

export enum MY_SCREEN_TILE_IDS {
  MEDIA_PLAYER = "media",
  NAVIGATION = "map",
  CAR_DRIVE_MODE = "drivemode",
  WEATHER = "weather",
  CONCIERGE = "concierge",
  TRIP = "trip",
  CHARGE = "charge",
  PHONE = "phone",
  APPLE_CAR_PLAY = "carplay",
  ANDROID_AUTO = "androidauto",
  AMBIENT_LIGHTING = "ambientlighting"
}
