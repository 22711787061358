import {
  MainViewSettingType,
  MainState,
  SET_MAINSTATE_MAINVIEW,
  SET_PASSENGER_SCREEN_STANDBY,
  SET_AUTHORIZED
} from "../../types/mainState/types"
import { isDevMode } from "../../../../App"

const initialState: MainState = {
  mainView: "running", // change to 'off' for the IAA simulation build
  passengerScreenView: "running",
  authorized: isDevMode ? true : false
}

export default Object.freeze(function MainStateReducer(state = initialState, action: MainViewSettingType): MainState {
  switch (action.type) {
    case SET_AUTHORIZED:
      return {
        ...state,
        authorized: action.payload,
      }
    case SET_MAINSTATE_MAINVIEW:
      return {
        ...state,
        mainView: action.payload
      }
    case SET_PASSENGER_SCREEN_STANDBY:
      return {
        ...state,
        passengerScreenView: action.payload
      }
    default:
      return state
  }
})
