import { Coordinate } from "./types"
import * as turf from "@turf/turf"

const earthRadiusKm = 6371

function degreesToRadians(degrees: any) {
  return (degrees * Math.PI) / 180
}

export function distanceInMeterBetweenEarthCoordinates(lon1: number, lat1: number, lon2: number, lat2: number) {
  const dLat = degreesToRadians(lat2 - lat1)
  const dLon = degreesToRadians(lon2 - lon1)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat2))
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return earthRadiusKm * c * 1000
}

export function bearingBetween(coordinate1: Coordinate, coordinate2: Coordinate) {
  var point1 = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [coordinate1[0], coordinate1[1]]
    }
  }
  var point2 = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [coordinate2[0], coordinate2[1]]
    }
  }

  // @ts-ignore
  return turf.bearing(point1, point2)
}
