import * as React from "react";
import { RouteComponentProps, withRouter } from 'react-router';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AppState } from "../components/redux/reducer";
import { connect } from "react-redux"
import { Dispatch, AnyAction } from "redux";

const mapper = (state: AppState) => ({ authorized: state.mainState.authorized });

export interface AuthorizedRouteProps extends RouteProps {
    authorized: boolean;
    dispatch: Dispatch<AnyAction>;
    componentProps?: object;

    loginRoute: string;
}

type x = keyof AuthorizedRouteProps;

const AuthorizedRoute: React.FC<AuthorizedRouteProps & RouteComponentProps<any>> = (authRouteProps: AuthorizedRouteProps & RouteComponentProps<any>) => {
    const { component: Component, render, ...rest } = authRouteProps;
    return (
        <Route
            {...rest}
            render={(routeComponentProps: RouteComponentProps<any>): React.ReactNode => {
                const combinedProps = {
                    ...routeComponentProps,
                    ...authRouteProps.componentProps
                };
                if (authRouteProps.authorized) {
                    if (Component)
                        return <Component {...combinedProps} />;
                    if (render)
                        return render(combinedProps);
                }
                return <Redirect to={{ pathname: authRouteProps.loginRoute, state: { from: routeComponentProps.location } }} />
            }}
        />
    );
};
export default connect(mapper)(withRouter(AuthorizedRoute));
