import React from "react"
import cx from "classnames"

import Key, { EKeyType } from "./CopyKey"

//import "@pag/center/components/speller/keypad/keypad.scss"

const layoutPhone = [
  [
    { text: "1", secondaryLabel: " ", width: 8 },
    { text: "2", secondaryLabel: "abc", width: 8 },
    { text: "3", secondaryLabel: "def", width: 8 }
  ],
  [
    { text: "4", secondaryLabel: "ghi", width: 8 },
    { text: "5", secondaryLabel: "jkl", width: 8 },
    { text: "6", secondaryLabel: "mno", width: 8 }
  ],
  [
    { text: "7", secondaryLabel: "pqrs", width: 8 },
    { text: "8", secondaryLabel: "tuv", width: 8 },
    { text: "9", secondaryLabel: "wxyz", width: 8 }
  ]
]

export interface IKeypad {
  className?: string
  disabled?: boolean
  onClick: (text: string) => void
  altLastRow?: boolean
}

const Keypad: React.FC<IKeypad> = (props: IKeypad) => {
  const renderRows = (altLastRow?: boolean) => {
    let rows: React.ReactElement<any>[] = []

    layoutPhone.map(function (row, index) {
      let keys: React.ReactElement<any>[] = []
      let keypadRowClass: string = "keypad__row-" + (index + 1)

      row.forEach((key: any, idx: number) => {
        keys.push(
          <Key
            className={idx === 1 ? "key-in-the-middle" : ""} // hacky
            key={`keypad-key-${index}-${idx}`}
            keyType={EKeyType.Character}
            keyWidth={key.width}
            keyContent_text={key.text}
            secondaryLabel={key.secondaryLabel}
            secondaryLabel_icon={key.secondaryLabel_icon}
            clickHandler={() => props.onClick(key.text)}
          />
        )
      })
      rows.push(
        <div key={keypadRowClass} className={cx("keypad__row", keypadRowClass)}>
          {keys}
        </div>
      )
      return null
    })
    rows.push(keypadLastRow(layoutPhone.length + 1))

    return rows
  }

  const keypadLastRow = (lastRowNumber: number) => {
    return (
      <div key={"keypad__row-" + lastRowNumber} className={cx("keypad__row")}>
        <Key
          className="green"
          keyType={EKeyType.Icon}
          keyWidth={8}
          keyContent_icon={"icon-arrow_forward" /*"icon-microphone_crossed"*/}
          clickHandler={() => props.onClick("send")}
        />
        <Key
          className="key-in-the-middle"
          key={`keypad-key-zero`}
          keyType={EKeyType.Character}
          keyWidth={8}
          keyContent_text={"0"}
          secondaryLabel={""}
          secondaryLabel_icon={""}
          clickHandler={() => props.onClick("0")}
        />
        <Key
          className="red"
          keyType={EKeyType.Icon}
          keyWidth={8}
          keyContent_icon={"icon-delete_number"}
          clickHandler={() => props.onClick("<-")}
        />
      </div>
    )
  }

  return (
    <div className="keypad__pad">
      <div className={cx("keypad__wrapper", props.className || "")}>
        <div className="keypad__remote-container">{renderRows(props.altLastRow)}</div>
      </div>
    </div>
  )
}

export default Keypad
