import React from "react"
import { useSelector } from "react-redux"

import { AppState } from "../../../../redux/reducer"
import units from "@pag/center/components/unit/units"
import { UnitKind } from "@pag/center/components/unit/types"
import I18nLabel from "@pag/center/components/I18nLabel/I18nLabel"

const tires = [
  {
    position: [ "front", "left" ],
    pressure: 190000
  },
  {
    position: [ "front", "right" ],
    pressure: 220000
  },
  {
    position: [ "rear", "left" ],
    pressure: 210000
  },
  {
    position: [ "rear", "right" ],
    pressure: 220000
  }
];

const TyrePressureDetailScreen: React.FC = () => {
  const converter = units.useBaseConverter(UnitKind.Pressure)
  const pressure = useSelector((state: AppState) => state.settings.Settings_System_Units_Pressure)

  return (
    <div className="content__tyre--pressure">
      {tires.map((tire) => (
        <span
          key={tire.position.join("-")}
          className={"content__value--position " + tire.position.map((pos) => `content__value--position-${pos}`).join(" ")}
        >
         {converter.from(tire.pressure)}
        </span>
      ))}
      {<I18nLabel className="content__unit" text={pressure.label} />}
    </div>
  )
}

export default TyrePressureDetailScreen
