import {
  MAKE_ROOM,
  CREATE_TIMER,
  EDIT_TIMER,
  TOGGLE_TIMER,
  REMOVE_TIMER,
  CREATE_PROFILE,
  EDIT_PROFILE,
  TOGGLE_PROFILE,
  REMOVE_PROFILE,
  SET_TARGET_CHARGE,
  ChargingScreenState,
  Action,
  Timer,
  Profile,
  SET_DIRECT_CHARGING
} from "./types"
import data from "@pag/center/views/chargingScreen/data"

const { newProfile, newTimer } = data

const includes = (array_of_objects: { id: object }[], object: { id: object }) => (
  array_of_objects.find((candidate) => candidate.id === object.id) !== undefined
)

const generalProfile = newProfile()
generalProfile.name = "ChargingRouter_SubTabBar_pag3-i18n-ChargingManagementProfileOverview_list_ProfileList_label_UniversalProfileLabel"

// @NOTE(kirill): Those should use proper translations but it should be done
// through dispatcher via language change event.

const workProfile = newProfile()
// workProfile.name = "ChargingRouter_SubTabBar_pag3-i18n-ChargingManagementProfileOverview_list_label_BusinessTitle"
workProfile.name = "Work"

const homeProfile = newProfile()
// homeProfile.name = "ChargingRouter_SubTabBar_pag3-i18n-ChargingManagementProfileOverview_list_label_HomeTitle"
homeProfile.name = "Home"

// @NOTE(kirill): The general profile isn’t removable so we have to expose its
// ID for later checks.

export const generalProfileId = generalProfile.id

// ASSEMBLE CHARGING STATE

const initialChargingScreenState: ChargingScreenState = Object.create(null)

initialChargingScreenState.targetCharge = 0.75

initialChargingScreenState.directCharging = false

initialChargingScreenState.makeRoomForFullScreenComponent = false

initialChargingScreenState.timers = Object.freeze([ newTimer() ])
initialChargingScreenState.selectedTimers = initialChargingScreenState.timers.slice(0, 1)
initialChargingScreenState.nextTimer = data.getForemostTimer(initialChargingScreenState.timers.slice(0, 1))

initialChargingScreenState.profiles = [ Object.freeze(generalProfile), Object.freeze(workProfile), Object.freeze(homeProfile) ]
initialChargingScreenState.selectedProfiles = initialChargingScreenState.profiles.slice(0, 1)

export function chargingScreenReducer(
  state = initialChargingScreenState,
  action: Action
): ChargingScreenState {
  switch (action.type) {

    case SET_TARGET_CHARGE:
    return {
      ...state,
      targetCharge: action.value
    }

    case MAKE_ROOM:
    return {
      ...state,
      makeRoomForFullScreenComponent: action.value
    }

    case CREATE_TIMER:
    return {
      ...state,
      timers: state.timers.concat([ action.timer ])
    }

    case TOGGLE_TIMER:
    if (state.selectedTimers.includes(action.timer)) {
      const selectedTimers = state.selectedTimers.filter((timer: Timer) => timer !== action.timer)
      return {
        ...state,
        selectedTimers,
        nextTimer: data.getForemostTimer(selectedTimers)
      }
    } else {
      const selectedTimers = state.selectedTimers.concat([ action.timer ])
      return {
        ...state,
        selectedTimers,
        nextTimer: data.getForemostTimer(selectedTimers)
      }
    }

    case REMOVE_TIMER: {
      const selectedTimers =  state.selectedTimers.filter((timer: Timer) => timer !== action.timer)
      return {
        ...state,
        timers: state.timers.filter((timer: Timer) => timer !== action.timer),
        selectedTimers: state.selectedTimers.filter((timer: Timer) => timer !== action.timer),
        nextTimer: data.getForemostTimer(selectedTimers)
      }
    }

    case EDIT_TIMER: {
      Object.assign(action.timer, action.data)

      action.timer.weekdays.sort((first, second) => first.date_index - second.date_index);

      const timers = state.timers.slice()
      const selectedTimers = state.selectedTimers.slice()

      return {
        ...state,
        timers,
        selectedTimers,
        nextTimer: data.getForemostTimer(selectedTimers)
      }
    }

    case SET_DIRECT_CHARGING:
    return {
      ...state,
      directCharging: action.value
    }

    case CREATE_PROFILE:
    return {
      ...state,
      profiles: state.profiles.concat([ Object.freeze(newProfile()) ])
    }

    case TOGGLE_PROFILE:
    if (includes(state.selectedProfiles, action.profile)) {
      return {
        ...state,
        selectedProfiles: state.selectedProfiles.filter((profile: Profile) => profile.id !== action.profile.id)
      }
    } else {
      return {
        ...state,
        selectedProfiles: state.selectedProfiles.concat([ action.profile ])
      }
    }

    case REMOVE_PROFILE:
    return (
      action.profile === generalProfile
      ? state
      : {
        ...state,
        profiles: state.profiles.filter((profile: Profile) => profile.id !== action.profile.id)
      }
    )

    case EDIT_PROFILE:
    // @ts-ignore
    const stale_profile = state.profiles.find((profile: Profile) => profile.id === action.profile.id)
    if (stale_profile === undefined) {
      return state
    }

    const edited_profile = Object.keys(stale_profile).reduce(
      function (profile: Profile, key: string) {
        profile[key] = (
          action.data[key] !== undefined
          ? action.data[key]
          : stale_profile[key]
        )
        return profile
      },
      newProfile(action.profile.id)
    )
    Object.freeze(edited_profile)

    const new_profile_list = state.profiles.slice()
    new_profile_list.splice(new_profile_list.indexOf(stale_profile), 1, edited_profile)

    let new_selected_profiles
    if (state.selectedProfiles.includes(stale_profile)) {
      new_selected_profiles = state.selectedProfiles.slice()
      new_selected_profiles.splice(new_selected_profiles.indexOf(stale_profile), 1, edited_profile)
    }

    return {
      ...state,
      profiles: new_profile_list,
      selectedProfiles: (
        new_selected_profiles === undefined
        ? state.selectedProfiles
        : new_selected_profiles
      )
    }

    default:
    return state
  }
}
