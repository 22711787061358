import objtools from "@pag/utils/object-tools"
import icon from "@pag/center/components/icon/icons"

const kinds = objtools.populate()(
  [],
  [],
  []
)

const [
  warn_kind,
  message_kind,
  info_kind
] = kinds

const tabs = objtools.populate(
  "icon",               "kind"
)(
  [ icon.all,           undefined    ],
  [ icon.car_front,  warn_kind     ],
  [ icon.communication, message_kind ],
  [ icon.bubble_info,   info_kind    ]
)

export default Object.freeze({
  tabs,
  kinds,
  warn_kind,
  message_kind,
  info_kind
})
