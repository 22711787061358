import React from "react"
import ClassNames from "classnames"

import "./distanceDestination.scss"

interface IDistanceDestinationProps {
  formattedDistance: string
  className?: string
}

const DistanceDestination: React.FC<IDistanceDestinationProps> = ({ formattedDistance, className }) => {
  return <span className={ClassNames(className, "distance__value")}>{formattedDistance}</span>
}

export default React.memo(DistanceDestination)
