import object_tools from "@pag/utils/object-tools"
import icon from "@pag/center/components/icon/icons"
import { List_Entry } from "@pag/center/components/player/audioPlayer/types"

import podcast from "@pag/center/assets/mp3/podcasts/covers/podcast_cover.jpg"

const importAll = (r: any) => r.keys().reduce(
  (songs: { [index: string]: any }, item: any) => {
    songs[item.replace("./", "")] = r(item)
    return songs
  },
  Object.create(null)
)
const assets = importAll(require.context("@pag/center/assets/mp3/podcasts", false, /\.(mp3)$/))

const populate_artists = object_tools.populate_loose(
    "label",                                                       "icon",  "list"
)

const artists: Readonly<List_Entry[]> = populate_artists(
  [ "Next Visions — Today’s Masterminds about Topics of Tomorrow", podcast, [] ]
)

const populate_songs = object_tools.populate_loose(
    "label",                   "artist",   "source",                                        "genre"
)

export const songs: Readonly<List_Entry[]> = populate_songs(
  [ "About leaders who care",  artists[0], assets["podcast.mp3"],                           "Society & Culture" ],
  [ "About intersectionality", artists[0], assets["podcast_about_intersectionality_1.mp3"], "Technology"        ],
  [ "About next rolemodels",   artists[0], assets["podcast_about_next_role_models_1.mp3"],  "Science"           ],
  [ "About new currencies",    artists[0], assets["podcast__about_new_currencies_1.mp3"],   "Science"           ]
)

artists.forEach((artist) => {
  artist.list = Object.freeze(songs.filter((song) => song.artist === artist))
  Object.freeze(artist)
})

const listen_now = Object.freeze([
  Object.freeze({ label: "New Episodes" }),
  ...songs
])

const populate_list = object_tools.populate(
    "label",                    "icon",       "list"
)

const overview = populate_list(
  [ "Apple_Podcasts_ListenNow", icon.apple_podcasts_listennow,  listen_now ],
  [ "Apple_Podcasts_Library",   icon.apple_podcasts_library,      artists.slice() ],
  [ "Top Charts",               icon.channel, [] ]
)

export default overview
