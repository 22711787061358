import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"

export const SET_BALANCE = "SET_BALANCE"
export const SET_FADE = "SET_FADE"
export const SET_BASS = "SET_BASS"
export const SET_TREBLE = "SET_TREBLE"

export type ReduxInitAction = { type: "@@INIT" }

interface SetBalance {
  type: typeof SET_BALANCE
  payload: number
}

interface SetFade {
  type: typeof SET_FADE
  payload: number
}

interface SetBass {
  type: typeof SET_BASS
  payload: number
}

interface SetTreble {
  type: typeof SET_TREBLE
  payload: number
}

export interface MediaSoundState {
  balance: number
  fade: number
  bass: number
  treble: number
}

export type Action = ReduxInitAction | SetBalance | SetFade

export type Store = ReduxStore<MediaSoundState, Action>

export type Dispatch = ReduxDispatch<Action>

export type BalanceAndFaderType = SetBalance | SetFade
export type BassAndTrebleType = SetBass | SetTreble
