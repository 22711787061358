import {
  Account,
  AccountsState,
  Action,
  PRESELECT_ACCOUNT,
  SELECT_ACCOUNT,
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  SET_ACCOUNT_LOADING
} from "./types"

export const guest_account: Account = Object.freeze({
  name: "",
  label: "Settings_Accounts_Guest",
  useCode: true,
  autoLogIn: true
})

const accounts: Account[] = [
  guest_account,
  {
    name: "Maria Bianchi",
    kind: "Settings_Accounts_SecondaryUser",
    useCode: true,
    autoLogIn: true
  },
  {
    name: "John Doe",
    kind: "Settings_Accounts_SecondaryUser",
    useCode: true,
    autoLogIn: true
  },
  {
    name: "John Smith",
    kind: "Settings_Accounts_PrimaryUser",
    useCode: true,
    autoLogIn: true
  },
];

const initialState: AccountsState = {
  list: accounts,
  selected: accounts[3],
  toBeSelected: undefined,
  loading: false
}

export function accountsReducer(
  state: AccountsState = initialState,
  action: Action
) {
  switch (action.type) {

  case CREATE_ACCOUNT:
    return (
      state.list.includes(action.account)
      ? state
      : Object.assign({}, state, { list: state.list.slice().concat([ action.account ]) })
    )

  case PRESELECT_ACCOUNT:
    return (
      (
        action.account === undefined ||
        state.list.includes(action.account)
      )
      ? Object.assign({}, state, { toBeSelected: action.account })
      : state
    )

  case SELECT_ACCOUNT:
    return (
      state.toBeSelected !== undefined
      ? Object.assign({}, state, {
        selected: state.toBeSelected,
        toBeSelected: undefined
      })
      : state
    )

  case SET_ACCOUNT_LOADING:
    return (
      Object.assign({}, state, { loading: action.payload })
    )

  case UPDATE_ACCOUNT:
    const list = state.list.slice()
    const account: Account | undefined = list.find((item) => item === action.account)
    if (account === undefined) {
      return state
    } else {
      Object.assign(account, action.data)
      return Object.assign({}, state, { list })
    }

  default:
    return state
  }
}
