import React from "react"

import icons from "@pag/center/components/icon/icons"
import { IconType } from "@pag/center/components/icon/types"
import { ROUTES } from "@pag/center/views/routes"
import lists from "@pag/center/views/settingsScreen/listContents/settingsListContents"
import Tile from "@pag/center/components/tile/TileContainer"
import { LauncherTile } from "@pag/center/views/launcherConfig/types"

function tile(text: string, icon: IconType | IconType[], url: string): LauncherTile {
  return Object.freeze({ text, icon, url }) as LauncherTile
}

function createTileComponent(tile: LauncherTile) {
  return (
    <div key={tile.url} className="tile-list__item">
      <Tile
        isSmall={false}
        text={tile.text}
        icon={tile.icon}
        url={tile.url}
      />
    </div>
  )
}

export const carTile =           tile("CenterMainScreen_Tiles_Car",           icons.car_front_app_tile,            ROUTES.CAR)
export const mediaTile =         tile("CenterMainScreen_Tiles_Media",         icons.note_app_tile,                 ROUTES.MEDIA_PLAYER)
export const navigationTile =    tile("CenterMainScreen_Tiles_Navigation",    icons.north_arrow_app_tile,          ROUTES.NAVIGATION)
export const phoneTile =         tile("CenterMainScreen_Tiles_Phone",         icons.phone_receiver_app_tile,       ROUTES.PHONE)
export const carPlayTile =       tile("CenterMainScreen_Tiles_Carplay",     [ icons.apple_carplay,
                                                                              icons.apple_carplay_app_tile ],      ROUTES.CARPLAY)
export const androidAutoTile =   tile("CenterMainScreen_Tiles_AndroidAuto", [ icons.logo_android_auto,
                                                                              icons.logo_android_auto_app_tile ],  ROUTES.ANDROID_AUTO)
export const breakDownCallTile = tile("CenterMainScreen_Tiles_BCall",         icons.road_side_assistance_app_tile, ROUTES.PHONE)
export const manualTile = Object.freeze({ text:"CenterMainScreen_Tiles_eBAL", icon: icons.manual_app_tile, settings_list: lists.Settings_Info })

// all available tiles in their initial order
export const tiles: Readonly<LauncherTile[]> = Object.freeze([
  carTile,
  mediaTile,
  navigationTile,
  phoneTile,
  carPlayTile,
  androidAutoTile,
  tile("CenterMainScreen_Tiles_Settings",     icons.settings_app_tile,               ROUTES.SETTINGS),
  tile("CenterMainScreen_Tiles_Charge",       icons.charging_active_app_tile,        ROUTES.CHARGING),
  tile("CenterMainScreen_Tiles_AC",           icons.climate_app_tile,                ROUTES.CLIMATE),
  tile("CenterMainScreen_Tiles_Devices",      icons.cell_phone_app_tile,             ROUTES.DEVICES),
  tile("CenterMainScreen_Tiles_Service",      icons.predictive_maintenance_app_tile, ROUTES.MAINTENANCE),
  tile("CenterMainScreen_Tiles_Updates",      icons.updates_app_tile,                ROUTES.UPDATES),
  tile("CenterMainScreen_Tiles_Notification", icons.notification_app_tile,           ROUTES.NOTIFICATION_CENTER),
  tile("CenterMainScreen_Tiles_Parking",      icons.parking_app_tile,                ROUTES.PARKING),
  Object.freeze({ text: "Info",         icon: icons.info_outline_app_tile, settings_list: lists.Settings_Info }),
  tile("CenterMainScreen_Tiles_Calendar",     icons.organizer_app_tile,              ROUTES.CALENDAR),
  tile("CenterMainScreen_Tiles_Weather",      icons.weather_app_tile,                ROUTES.WEATHER),
  tile("CenterMainScreen_Tiles_News",         icons.news_app_tile,                   ROUTES.NEWS),
  breakDownCallTile,
  tile("CenterMainScreen_Tiles_SportChrono",  icons.timer_app_tile,                  ROUTES.SPORTS_CHRONO),
  tile("CenterMainScreen_Tiles_HomeLink",     icons.homelink_app_tile,               ROUTES.HOME_LINK),
  manualTile,
  tile("Streaming",                           icons.streaming_app_tile,              ROUTES.STREAMING)
])

export const bigTiles: Readonly<LauncherTile[]> = Object.freeze([
  carTile,
  mediaTile,
  navigationTile,
  phoneTile,
  carPlayTile,
  androidAutoTile
])

// @NOTE(kirill): it’s only used in centerPassenger
export function bigTileList(showAppleCarplay: boolean) {
  if (showAppleCarplay) {
    return bigTiles.map((tile) => createTileComponent(tile))
  }
  return bigTiles.filter((tile) => tile !== carPlayTile).map((tile) => createTileComponent(tile))
}
