import { titlePlayTimeState, UPDATE_TIME, UPDATE_VOLUME, PlayTimeActionType } from "./types"

const initialState: titlePlayTimeState = {
  duration: 0,
  currentPlayTime: 0,
  volume: 15,
  currentTime: 0,
  progressTime: 0
}

export default Object.freeze(function titlePlayTimeReducer(state = initialState, action: PlayTimeActionType): titlePlayTimeState {
  switch (action.type) {
    case UPDATE_TIME:
      return {
        ...state,
        duration: action.duration,
        currentPlayTime: action.currentTime,
        progressTime: action.progressTime
      }
    case UPDATE_VOLUME:
      return {
        ...state,
        volume: action.volume
      }
    default:
      return state
  }
})
