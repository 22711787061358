import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"
import { WarnMessage } from "./messages/warn-messages"
import { GenericMessage } from "./messages/generic-messages"

export const SET_NOTIFICATION_FILTER = "SET_NOTIFICATION_FILTER"
export const MARK_NOTIFICATION_READ = "MARK_NOTIFICATION_READ"
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"

export type ReduxInitAction = { type: "@@INIT" }

export type setFilterAction = {
  type: typeof SET_NOTIFICATION_FILTER
  value: any
}

export type markReadAction = {
  type: typeof MARK_NOTIFICATION_READ
  value: any
}

export type deleteNotificationAction = {
  type: typeof DELETE_NOTIFICATION
  value: any
}

export type ActiviveNotificationState = {
  notification: WarnMessage | GenericMessage
}

export type NotificationState = {
  list: Readonly<object[]>,
  unread: Readonly<object[]>,
  filter: any
}

export type Store = ReduxStore<NotificationState, Action>

export type Dispatch = ReduxDispatch<Action>

export type State = NotificationState

export type Action = 
  ReduxInitAction |
  setFilterAction |
  markReadAction |
  deleteNotificationAction
