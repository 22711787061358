import ETC_app_tile from "@pag/center/assets/icon-master/home/ETC_app_tile.png"
import ac_fan_only from "@pag/center/assets/icon-master/ac/icon-ac_fan_only.svg"
import air_distribution_diffuse from "@pag/center/assets/icon-master/ac/icon-air_distribution_diffuse.svg"
import air_distribution_focus from "@pag/center/assets/icon-master/ac/icon-air_distribution_focus.svg"
import ambient_light_app_tile_70 from "@pag/center/assets/icon-master/home/70/ambient_light_app_tile_70.png"
import apple_add_to_library from "@pag/center/assets/icon-master/media/icon-apple_add_to_library.svg"
import apple_carplay_app_tile from "@pag/center/assets/icon-master/home/apple_carplay_app_tile.png"
import apple_for_you from "@pag/center/assets/icon-master/media/icon-apple_for_you.svg"
import apple_radio from "@pag/center/assets/icon-master/media/icon-apple_radio.svg"
import arrow_n_button from "@pag/center/assets/icon-master/assist/icon-arrow_n_button.svg"
import arrow_n_button_Red from "@pag/center/assets/icon-master/assist/icon-arrow_n_button_Red.svg"
import car_front_911_app_tile from "@pag/center/assets/icon-master/home/car_front_911_app_tile.png"
import car_front_E3_PA_app_tile from "@pag/center/assets/icon-master/home/car_front_E3_PA_app_tile.png"
import car_front_E3_app_tile from "@pag/center/assets/icon-master/home/car_front_E3_app_tile.png"
import car_front_G2PA_app_tile from "@pag/center/assets/icon-master/home/car_front_G2PA_app_tile.png"
import car_front_G3_app_tile from "@pag/center/assets/icon-master/home/car_front_G3_app_tile.png"
import car_front_J1_app_tile from "@pag/center/assets/icon-master/home/car_front_J1_app_tile.png"
import cell_phone_app_tile from "@pag/center/assets/icon-master/home/cell_phone_app_tile.png"
import charging_active_app_tile from "@pag/center/assets/icon-master/home/charging_active_app_tile.png"
import city_events_app_tile from "@pag/center/assets/icon-master/home/city_events_app_tile.png"
import climate_app_app_tile_70 from "@pag/center/assets/icon-master/home/70/climate_app_app_tile_70.png"
import climate_app_tile from "@pag/center/assets/icon-master/home/climate_app_tile.png"
import codriver_cockpit_app_tile from "@pag/center/assets/icon-master/home/codriver_cockpit_app_tile.png"
import codriver_cockpit_app_tile_70 from "@pag/center/assets/icon-master/home/70/codriver_cockpit_app_tile_70.png"
import concierge_app_tile from "@pag/center/assets/icon-master/home/concierge_app_tile.png"
import data_activity_2g_active from "@pag/center/assets/icon-master/phone/data_activity_2g_active.svg"
import data_activity_2g_inactive from "@pag/center/assets/icon-master/phone/data_activity_2g_inactive.svg"
import data_activity_3g_active from "@pag/center/assets/icon-master/phone/data_activity_3g_active.svg"
import data_activity_3g_inactive from "@pag/center/assets/icon-master/phone/data_activity_3g_inactive.svg"
import data_activity_4g_active from "@pag/center/assets/icon-master/phone/data_activity_4g_active.svg"
import data_activity_4g_inactive from "@pag/center/assets/icon-master/phone/data_activity_4g_inactive.svg"
import data_activity_5g_active from "@pag/center/assets/icon-master/phone/data_activity_5g_active.svg"
import data_activity_5g_inactive from "@pag/center/assets/icon-master/phone/data_activity_5g_inactive.svg"
import data_activity_wifi_active from "@pag/center/assets/icon-master/phone/data_activity_wifi_active.svg"
import data_activity_wifi_inactive from "@pag/center/assets/icon-master/phone/data_activity_wifi_inactive.svg"
import destination_flag_offroad from "@pag/center/assets/icon-master/map/icon-destination_flag_offroad.svg"
import flights_app_tile from "@pag/center/assets/icon-master/home/flights_app_tile.png"
import google_search_active from "@pag/center/assets/icon-master/navi/google_search_Active.svg"
import google_search_default from "@pag/center/assets/icon-master/navi/google_search_Default.svg"
import google_search_disabled from "@pag/center/assets/icon-master/navi/google_search_Disabled.svg"
import google_search_pressed from "@pag/center/assets/icon-master/navi/google_search_Pressed.svg"
import guidance_arrow from "@pag/center/assets/icon-master/map/icon-guidance_arrow.svg"
import home_app_tile from "@pag/center/assets/icon-master/home/home_app_tile.svg"
import home_app_tile_active from "@pag/center/assets/icon-master/home/home_app_tile_active.svg"
import home_app_tile_default from "@pag/center/assets/icon-master/home/home_app_tile_default.svg"
import home_app_tile_disabled from "@pag/center/assets/icon-master/home/home_app_tile_disabled.svg"
import home_app_tile_pressed from "@pag/center/assets/icon-master/home/home_app_tile_pressed.svg"
import homelink_app_tile from "@pag/center/assets/icon-master/home/homelink_app_tile.svg"
import icon_contacts from "@pag/center/assets/icon-master/home/icon-contacts.svg"
import indicator_warning from "@pag/center/assets/icon-master/general/icon-indicator_warning.svg"
import info_outline_70 from "@pag/center/assets/icon-master/home/70/info_outline_70.png"
import info_outline_app_tile from "@pag/center/assets/icon-master/home/info_outline_app_tile.png"
import lgi_aquaplaning from "@pag/center/assets/icon-master/general/icon-lgi_aquaplaning.svg"
import lgi_falschfahrer from "@pag/center/assets/icon-master/traffic_signs/lgi_falschfahrer.svg"
import lgi_panne from "@pag/center/assets/icon-master/map/icon-lgi_panne.svg"
import lgi_sicht from "@pag/center/assets/icon-master/map/icon-lgi_sicht.svg"
import lgi_traktion from "@pag/center/assets/icon-master/map/icon-lgi_traktion.svg"
import lgi_unfall from "@pag/center/assets/icon-master/map/icon-lgi_unfall.svg"
import lgw_notbremsung_voraus from "@pag/center/assets/icon-master/assist/icon-lgw_notbremsung_voraus.svg"
import lgw_notfallfahrzeug_hinten from "@pag/center/assets/icon-master/assist/icon-lgw_notfallfahrzeug_hinten.svg"
import lgw_notfallfahrzeug_links from "@pag/center/assets/icon-master/assist/icon-lgw_notfallfahrzeug_links.svg"
import lgw_notfallfahrzeug_rechts from "@pag/center/assets/icon-master/assist/icon-lgw_notfallfahrzeug_rechts.svg"
import lgw_notfallfahrzeug_voraus from "@pag/center/assets/icon-master/assist/icon-lgw_notfallfahrzeug_voraus.svg"
import lgw_notfallfahrzeug_vorne from "@pag/center/assets/icon-master/assist/icon-lgw_notfallfahrzeug_vorne.svg"
import lgw_stau_voraus from "@pag/center/assets/icon-master/assist/icon-lgw_stau_voraus.svg"
import logo_7eleven from "@pag/center/assets/icon-master/connect/news/icon-logo_7eleven.svg"
import logo_OpisNavx from "@pag/center/assets/icon-master/connect/news/icon-logo_OpisNavx.svg"
import logo_SID from "@pag/center/assets/icon-master/connect/news/icon-logo_SID.svg"
import logo_Tagesschau from "@pag/center/assets/icon-master/connect/news/icon-logo_Tagesschau.svg"
import logo_Xinhua from "@pag/center/assets/icon-master/connect/news/icon-logo_Xinhua.svg"
import logo_afp from "@pag/center/assets/icon-master/connect/news/icon-logo_afp.svg"
import logo_android_auto_app_tile from "@pag/center/assets/icon-master/home/logo_android_auto_app_tile.png"
import logo_ansa from "@pag/center/assets/icon-master/connect/news/icon-logo_ansa.svg"
import logo_apple_music_color from "@pag/center/assets/icon-master/media/icon-logo_apple_music_color.svg"
import logo_apple_podcasts_color from "@pag/center/assets/icon-master/media/icon-logo_apple_podcasts_color.svg"
import logo_db_bahn from "@pag/center/assets/icon-master/connect/news/icon-logo_db_bahn.svg"
import logo_deutsche_welle from "@pag/center/assets/icon-master/connect/news/icon-logo_deutsche_welle.svg"
import logo_eventseeker from "@pag/center/assets/icon-master/connect/news/icon-logo_eventseeker.svg"
import logo_flight_stats from "@pag/center/assets/icon-master/connect/news/icon-logo_flight_stats.svg"
import logo_inrix from "@pag/center/assets/icon-master/connect/news/icon-logo_inrix.svg"
import logo_merian from "@pag/center/assets/icon-master/connect/news/icon-logo_merian.svg"
import logo_meteoGroup from "@pag/center/assets/icon-master/connect/news/icon-logo_meteoGroup.svg"
import logo_npr from "@pag/center/assets/icon-master/connect/news/icon-logo_npr.svg"
import logo_opisnavx from "@pag/center/assets/icon-master/navi/icon-logo_opisnavx.svg"
import logo_pag from "@pag/center/assets/icon-master/connect/news/icon-logo_pag.svg"
import logo_parkopedia from "@pag/center/assets/icon-master/navi/logo_parkopedia.svg"
import logo_plugshare from "@pag/center/assets/icon-master/navi/logo_plugshare.svg"
import logo_rss from "@pag/center/assets/icon-master/connect/news/icon-logo_rss.svg"
import logo_screenhits from "@pag/center/assets/icon-master/media/logo_screenhits.svg"
import logo_spotify_color from "@pag/center/assets/icon-master/media/logo_spotify_color.svg"
import logo_twitter from "@pag/center/assets/icon-master/connect/news/icon-logo_twitter.svg"
import logo_wefind from "@pag/center/assets/icon-master/connect/news/icon-logo_wefind.svg"
import manual_app_tile from "@pag/center/assets/icon-master/home/manual_app_tile.png"
import mobile_device_key_app_tile from "@pag/center/assets/icon-master/home/mobile_device_key_app_tile.png"
import network_0_arrow_forward from "@pag/center/assets/icon-master/sidebars/icon-network_0_arrow_forward.svg"
import network_1 from "@pag/center/assets/icon-master/sidebars/icon-network_1.svg"
import network_1_arrow_forward from "@pag/center/assets/icon-master/sidebars/icon-network_1_arrow_forward.svg"
import network_2 from "@pag/center/assets/icon-master/sidebars/icon-network_2.svg"
import network_2_arrow_forward from "@pag/center/assets/icon-master/sidebars/icon-network_2_arrow_forward.svg"
import network_3 from "@pag/center/assets/icon-master/sidebars/icon-network_3.svg"
import network_3_arrow_forward from "@pag/center/assets/icon-master/sidebars/icon-network_3_arrow_forward.svg"
import network_4 from "@pag/center/assets/icon-master/sidebars/icon-network_4.svg"
import network_4_arrow_forward from "@pag/center/assets/icon-master/sidebars/icon-network_4_arrow_forward.svg"
import network_5_arrow_forward from "@pag/center/assets/icon-master/sidebars/icon-network_5_arrow_forward.svg"
import news_app_tile from "@pag/center/assets/icon-master/home/news_app_tile.png"
import north_arrow_app_tile from "@pag/center/assets/icon-master/home/north_arrow_app_tile.png"
import north_arrow_app_tile_70 from "@pag/center/assets/icon-master/home/70/north_arrow_app_tile_70.png"
import note_app_tile from "@pag/center/assets/icon-master/home/note_app_tile.png"
import note_app_tile_70 from "@pag/center/assets/icon-master/home/70/note_app_tile_70.png"
import notification_app_tile from "@pag/center/assets/icon-master/home/notification_app_tile.png"
import organizer_app_tile from "@pag/center/assets/icon-master/home/organizer_app_tile.png"
import parking_app_tile from "@pag/center/assets/icon-master/home/parking_app_tile.png"
import pdx_app_tile from "@pag/center/assets/icon-master/home/pdx_app_tile.png"
import phone_pause_call from "@pag/center/assets/icon-master/phone/icon-phone_pause_call.svg"
import phone_reactivate_call from "@pag/center/assets/icon-master/phone/icon-phone_reactivate_call.svg"
import phone_receiver_app_tile from "@pag/center/assets/icon-master/home/phone_receiver_app_tile.png"
import predictive_maintenance_992_app_tile from "@pag/center/assets/icon-master/home/predictive_maintenance_992_app_tile.png"
import predictive_maintenance_E3PA_app_tile from "@pag/center/assets/icon-master/home/predictive_maintenance_E3PA_app_tile.png"
import predictive_maintenance_E3_app_tile from "@pag/center/assets/icon-master/home/predictive_maintenance_E3_app_tile.png"
import predictive_maintenance_G2PA_app_tile from "@pag/center/assets/icon-master/home/predictive_maintenance_G2PA_app_tile.png"
import predictive_maintenance_G3_app_tile from "@pag/center/assets/icon-master/home/predictive_maintenance_G3_app_tile.png"
import predictive_maintenance_J1_app_tile from "@pag/center/assets/icon-master/home/predictive_maintenance_J1_app_tile.png"
import prioritysign_arrow_na from "@pag/center/assets/icon-master/map/icon-prioritysign_arrow_na.svg"
import prioritysign_roundabout from "@pag/center/assets/icon-master/map/icon-prioritysign_roundabout.svg"
import processing from "@pag/center/assets/icon-master/general/icon-processing.svg"
import processing_123 from "@pag/center/assets/icon-master/general/icon-processing_123.png"
import processing_123_red from "@pag/center/assets/icon-master/general/icon-processing_123_red.png"
import processing_70 from "@pag/center/assets/icon-master/general/icon-processing_70.png"
import processing_red from "@pag/center/assets/icon-master/general/icon-processing_red.png"
import qrcode_app_tile from "@pag/center/assets/icon-master/home/qrcode_app_tile.png"
import rating_yelp_0 from "@pag/center/assets/icon-master/navi/icon-rating_yelp_0.svg"
import rating_yelp_1 from "@pag/center/assets/icon-master/navi/icon-rating_yelp_1.svg"
import rating_yelp_1_5 from "@pag/center/assets/icon-master/navi/icon-rating_yelp_1_5.svg"
import rating_yelp_2 from "@pag/center/assets/icon-master/navi/icon-rating_yelp_2.svg"
import rating_yelp_2_5 from "@pag/center/assets/icon-master/navi/icon-rating_yelp_2_5.svg"
import rating_yelp_3 from "@pag/center/assets/icon-master/navi/icon-rating_yelp_3.svg"
import rating_yelp_3_5 from "@pag/center/assets/icon-master/navi/icon-rating_yelp_3_5.svg"
import rating_yelp_4 from "@pag/center/assets/icon-master/navi/icon-rating_yelp_4.svg"
import rating_yelp_4_5 from "@pag/center/assets/icon-master/navi/icon-rating_yelp_4_5.svg"
import rating_yelp_5 from "@pag/center/assets/icon-master/navi/icon-rating_yelp_5.svg"
import readout_app_tile_70 from "@pag/center/assets/icon-master/home/70/readout_app_tile_70.png"
import readout_low_app_tile_70 from "@pag/center/assets/icon-master/home/70/readout_low_app_tile_70.png"
import readout_mute_app_tile_70 from "@pag/center/assets/icon-master/home/70/readout_mute_app_tile_70.png"
import road_side_assistance_app_tile from "@pag/center/assets/icon-master/home/road_side_assistance_app_tile.png"
import seat_comfort_left_app_tile_70 from "@pag/center/assets/icon-master/home/70/seat_comfort_left_app_tile_70.png"
import settings_app_tile from "@pag/center/assets/icon-master/home/settings_app_tile.png"
import settings_app_tile_70 from "@pag/center/assets/icon-master/home/70/settings_app_tile_70.png"
import sirius_xm from "@pag/center/assets/icon-master/media/icon-sirius_xm.svg"
import sirius_xm_no_signal from "@pag/center/assets/icon-master/media/icon-sirius_xm_no_signal.svg"
import soc_destination_0_yellow from "@pag/center/assets/icon-master/navi/soc_destination_0_yellow.svg"
import soc_destination_1_white_C8_0 from "@pag/center/assets/icon-master/navi/soc_destination_1_white_C8.0.svg"
import soc_destination_1_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_1_white_C8.2.svg"
import soc_destination_1_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_1_white_C8.4.svg"
import soc_destination_1_white_C8_6 from "@pag/center/assets/icon-master/navi/soc_destination_1_white_C8.6.svg"
import soc_destination_1_yellow from "@pag/center/assets/icon-master/navi/soc_destination_1_yellow.svg"
import soc_destination_2_white_C8_0 from "@pag/center/assets/icon-master/navi/soc_destination_2_white_C8.0.svg"
import soc_destination_2_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_2_white_C8.2.svg"
import soc_destination_2_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_2_white_C8.4.svg"
import soc_destination_2_white_C8_6 from "@pag/center/assets/icon-master/navi/soc_destination_2_white_C8.6.svg"
import soc_destination_3_white_C8_0 from "@pag/center/assets/icon-master/navi/soc_destination_3_white_C8.0.svg"
import soc_destination_3_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_3_white_C8.2.svg"
import soc_destination_3_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_3_white_C8.4.svg"
import soc_destination_3_white_C8_6 from "@pag/center/assets/icon-master/navi/soc_destination_3_white_C8.6.svg"
import soc_destination_4_white_C8_0 from "@pag/center/assets/icon-master/navi/soc_destination_4_white_C8.0.svg"
import soc_destination_4_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_4_white_C8.2.svg"
import soc_destination_4_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_4_white_C8.4.svg"
import soc_destination_4_white_C8_6 from "@pag/center/assets/icon-master/navi/soc_destination_4_white_C8.6.svg"
import soc_destination_5_white_C8_0 from "@pag/center/assets/icon-master/navi/soc_destination_5_white_C8.0.svg"
import soc_destination_5_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_5_white_C8.2.svg"
import soc_destination_5_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_5_white_C8.4.svg"
import soc_destination_5_white_C8_6 from "@pag/center/assets/icon-master/navi/soc_destination_5_white_C8.6.svg"
import soc_destination_blank_red from "@pag/center/assets/icon-master/navi/soc_destination_blank_red.svg"
import soc_destination_blank_white_C8_0 from "@pag/center/assets/icon-master/navi/soc_destination_blank_white_C8.0.svg"
import soc_destination_blank_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_blank_white_C8.2.svg"
import soc_destination_blank_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_blank_white_C8.4.svg"
import soc_destination_blank_white_C8_6 from "@pag/center/assets/icon-master/navi/soc_destination_blank_white_C8.6.svg"
import soc_destination_flag_0_yellow_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_flag_0_yellow_C8.2.svg"
import soc_destination_flag_0_yellow_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_flag_0_yellow_C8.4.svg"
import soc_destination_flag_1_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_flag_1_white_C8.2.svg"
import soc_destination_flag_1_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_flag_1_white_C8.4.svg"
import soc_destination_flag_1_yellow_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_flag_1_yellow_C8.2.svg"
import soc_destination_flag_1_yellow_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_flag_1_yellow_C8.4.svg"
import soc_destination_flag_2_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_flag_2_white_C8.2.svg"
import soc_destination_flag_2_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_flag_2_white_C8.4.svg"
import soc_destination_flag_3_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_flag_3_white_C8.2.svg"
import soc_destination_flag_3_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_flag_3_white_C8.4.svg"
import soc_destination_flag_4_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_flag_4_white_C8.2.svg"
import soc_destination_flag_4_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_flag_4_white_C8.4.svg"
import soc_destination_flag_5_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_flag_5_white_C8.2.svg"
import soc_destination_flag_5_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_flag_5_white_C8.4.svg"
import soc_destination_flag_blank_red_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_flag_blank_red_C8.2.svg"
import soc_destination_flag_blank_red_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_flag_blank_red_C8.4.svg"
import soc_destination_flag_blank_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_destination_flag_blank_white_C8.2.svg"
import soc_destination_flag_blank_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_destination_flag_blank_white_C8.4.svg"
import soc_stopover_flag_0_yellow_C8_2 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_0_yellow_C8.2.svg"
import soc_stopover_flag_0_yellow_C8_4 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_0_yellow_C8.4.svg"
import soc_stopover_flag_1_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_1_white_C8.2.svg"
import soc_stopover_flag_1_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_1_white_C8.4.svg"
import soc_stopover_flag_1_yellow_C8_2 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_1_yellow_C8.2.svg"
import soc_stopover_flag_1_yellow_C8_4 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_1_yellow_C8.4.svg"
import soc_stopover_flag_2_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_2_white_C8.2.svg"
import soc_stopover_flag_2_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_2_white_C8.4.svg"
import soc_stopover_flag_3_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_3_white_C8.2.svg"
import soc_stopover_flag_3_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_3_white_C8.4.svg"
import soc_stopover_flag_4_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_4_white_C8.2.svg"
import soc_stopover_flag_4_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_4_white_C8.4.svg"
import soc_stopover_flag_5_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_5_white_C8.2.svg"
import soc_stopover_flag_5_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_5_white_C8.4.svg"
import soc_stopover_flag_blank_red_C8_2 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_blank_red_C8.2.svg"
import soc_stopover_flag_blank_red_C8_4 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_blank_red_C8.4.svg"
import soc_stopover_flag_blank_white_C8_2 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_blank_white_C8.2.svg"
import soc_stopover_flag_blank_white_C8_4 from "@pag/center/assets/icon-master/navi/soc_stopover_flag_blank_white_C8.4.svg"
import speed_limit_60 from "@pag/center/assets/icon-master/map/icon-speed_limit_60.svg"
import speed_limit_rain from "@pag/center/assets/icon-master/map/icon-speed_limit_rain.svg"
import speed_limit_warning_vae from "@pag/center/assets/icon-master/map/icon-speed_limit_warning_vae.svg"
import stopover_flag_offroad from "@pag/center/assets/icon-master/map/icon-stopover_flag_offroad.svg"
import streaming_app_tile from "@pag/center/assets/icon-master/home/streaming_app_tile.svg"
import timer_app_tile from "@pag/center/assets/icon-master/home/timer_app_tile.png"
import traffic_info_green_ece from "@pag/center/assets/icon-master/map/icon-traffic_info_green_ece.svg"
import traffic_info_nar from "@pag/center/assets/icon-master/map/icon-traffic_info_nar.svg"
import traffic_info_red from "@pag/center/assets/icon-master/map/icon-traffic_info_red.svg"
import traffic_info_selena_ece from "@pag/center/assets/icon-master/map/icon-traffic_info_selena_ece.svg"
import traffic_info_selena_nar from "@pag/center/assets/icon-master/map/icon-traffic_info_selena_nar.svg"
import traffic_sign_additional_icy_NZL from "@pag/center/assets/icon-master/traffic_signs/traffic_sign_additional_icy_NZL.svg"
import traffic_sign_additional_night_nar from "@pag/center/assets/icon-master/map/icon-traffic_sign_additional_night_nar.svg"
import traffic_sign_additional_school_nar from "@pag/center/assets/icon-master/map/icon-traffic_sign_additional_school_nar.svg"
import traffic_sign_additional_wet_NZL from "@pag/center/assets/icon-master/traffic_signs/traffic_sign_additional_wet_NZL.svg"
import traffic_sign_additional_work_zone_nar from "@pag/center/assets/icon-master/map/icon-traffic_sign_additional_work_zone_nar.svg"
import traffic_sign_animal_eu from "@pag/center/assets/icon-master/map/icon-traffic_sign_animal_eu.svg"
import traffic_sign_city_back_eu from "@pag/center/assets/icon-master/map/icon-traffic_sign_city_back_eu.svg"
import traffic_sign_city_eu from "@pag/center/assets/icon-master/map/icon-traffic_sign_city_eu.svg"
import traffic_sign_danger_eu from "@pag/center/assets/icon-master/map/icon-traffic_sign_danger_eu.svg"
import traffic_sign_exit_nar from "@pag/center/assets/icon-master/map/icon-traffic_sign_exit_nar.svg"
import traffic_sign_exit_only_nar from "@pag/center/assets/icon-master/map/icon-traffic_sign_exit_only_nar.svg"
import traffic_sign_give_way_eu from "@pag/center/assets/icon-master/map/icon-traffic_sign_give_way_eu.svg"
import traffic_sign_kmh_EU_CN from "@pag/center/assets/icon-master/traffic_signs/traffic_sign_kmh_EU_CN.svg"
import traffic_sign_mph_EU from "@pag/center/assets/icon-master/traffic_signs/traffic_sign_mph_EU.svg"
import traffic_sign_mph_USA from "@pag/center/assets/icon-master/traffic_signs/traffic_sign_mph_USA.svg"
import traffic_sign_no_entry_eu from "@pag/center/assets/icon-master/map/icon-traffic_sign_no_entry_eu.svg"
import traffic_sign_no_passing_end_nar from "@pag/center/assets/icon-master/map/icon-traffic_sign_no_passing_end_nar.svg"
import traffic_sign_no_passing_nar from "@pag/center/assets/icon-master/map/icon-traffic_sign_no_passing_nar.svg"
import traffic_sign_pedestrians_eu from "@pag/center/assets/icon-master/map/icon-traffic_sign_pedestrians_eu.svg"
import traffic_sign_speed_limit_advisory_kmh_nar_can from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_advisory_kmh_nar_can.svg"
import traffic_sign_speed_limit_advisory_mph_nar from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_advisory_mph_nar.svg"
import traffic_sign_speed_limit_advisory_nar from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_advisory_nar.svg"
import traffic_sign_speed_limit_advisory_nar_can from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_advisory_nar_can.svg"
import traffic_sign_speed_limit_cn from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_cn.svg"
import traffic_sign_speed_limit_end_mph_nar from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_end_mph_nar.svg"
import traffic_sign_speed_limit_end_nar from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_end_nar.svg"
import traffic_sign_speed_limit_eu from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_eu.svg"
import traffic_sign_speed_limit_eu_kmh from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_eu_kmh.svg"
import traffic_sign_speed_limit_eu_mph from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_eu_mph.svg"
import traffic_sign_speed_limit_generic_nar_can from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_generic_nar_can.svg"
import traffic_sign_speed_limit_kmh_nar_can from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_kmh_nar_can.svg"
import traffic_sign_speed_limit_mph_nar from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_mph_nar.svg"
import traffic_sign_speed_limit_nar from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_nar.svg"
import traffic_sign_speed_limit_nar_can from "@pag/center/assets/icon-master/map/icon-traffic_sign_speed_limit_nar_can.svg"
import traffic_sign_stop_eu from "@pag/center/assets/icon-master/map/icon-traffic_sign_stop_eu.svg"
import traffic_sign_traffic_lights_eu from "@pag/center/assets/icon-master/map/icon-traffic_sign_traffic_lights_eu.svg"
import traffic_sign_traffic_lights_free_right_turn_eu from "@pag/center/assets/icon-master/map/icon-traffic_sign_traffic_lights_free_right_turn_eu.svg"
import traffic_sign_traffic_lights_no_turn_nar from "@pag/center/assets/icon-master/map/icon-traffic_sign_traffic_lights_no_turn_nar.svg"
import update_error from "@pag/center/assets/icon-master/ota/update_error.svg"
import updates_app_tile from "@pag/center/assets/icon-master/home/updates_app_tile.png"
import videostreaming_app_tile from "@pag/center/assets/icon-master/home/videostreaming_app_tile.png"
import view from "@pag/center/assets/icon-master/map/icon-view.svg"
import warning_red from "@pag/center/assets/icon-master/home/warning_red.png"
import warning_sign from "@pag/center/assets/icon-master/assist/icon-warning_sign.svg"
import warning_white from "@pag/center/assets/icon-master/home/warning_white.png"
import warning_yellow from "@pag/center/assets/icon-master/home/warning_yellow.png"
import warnmarker_red from "@pag/center/assets/icon-master/home/warnmarker_red.png"
import warnmarker_white from "@pag/center/assets/icon-master/home/warnmarker_white.png"
import warnmarker_yellow from "@pag/center/assets/icon-master/home/warnmarker_yellow.png"
import weather_app_tile from "@pag/center/assets/icon-master/home/weather_app_tile.png"
import weather_app_tile_70 from "@pag/center/assets/icon-master/home/70/weather_app_tile_70.png"
import wheel from "@pag/center/assets/icon-master/assist/icon-wheel.svg"

import config from "../../../../config.json"
import { IconType } from "./types"

const icon_prototype = Object.create(
  null,
  {
    valueOf: {
      value: function () {
        return this.value
      },
      writable: false,
      enumerable: false,
      configurable: false
    },
    toString: {
      value: function () {
        if (this.value === undefined) {
          return ""
        }
        return (
          typeof this.value === "number"
          // @ts-ignore
          ? this.value.toString(16).toUpperCase()
          : this.value
        );
      },
      writable: false,
      enumerable: false,
      configurable: false
    }
  }
)

function make_icon(value: number | string): IconType {
  return Object.freeze(Object.create(
    icon_prototype,
    {
      value: {
        value, 
        writable: false,
        configurable: false,
        enumerable: true
      }
    }
  ))
}

const symbols: { [index: string]: number } = {
  "04_00_p_TurnArrow_ChangeLane40":                     0xEAD8,
  "04_00_p_TurnArrow_ChangeLaneC0":                     0xEAD7,
  "04_00_p_TurnArrow_ExitLeft00":                       0xEAD6,
  "04_00_p_TurnArrow_ExitLeftt20_40":                   0xEAD5,
  "04_00_p_TurnArrow_ExitRight00":                      0xEAD4,
  "04_00_p_TurnArrow_ExitRightC0_E0":                   0xEAD3,
  "04_00_p_TurnArrow_FollowStreet40":                   0xEAD2,
  "04_00_p_TurnArrow_FollowStreetC0":                   0xEAD1,
  "04_00_p_TurnArrow_FollowStreetFF":                   0xEAD0,
  "04_00_p_TurnArrow_Fork40":                           0xEACF,
  "04_00_p_TurnArrow_ForkC0":                           0xEACE,
  "04_00_p_TurnArrow_MichiganUTurn40":                  0xEACD,
  "04_00_p_TurnArrow_MichiganUTurnC0":                  0xEACC,
  "04_00_p_TurnArrow_OffRoad00":                        0xEACB,
  "04_00_p_TurnArrow_OffRoad10_30":                     0xEACA,
  "04_00_p_TurnArrow_OffRoad40":                        0xEAC9,
  "04_00_p_TurnArrow_OffRoad50_70":                     0xEAC8,
  "04_00_p_TurnArrow_OffRoad80":                        0xEAC7,
  "04_00_p_TurnArrow_OffRoad90_B0":                     0xEAC6,
  "04_00_p_TurnArrow_OffRoadC0":                        0xEAC5,
  "04_00_p_TurnArrow_OffRoadD0_F0":                     0xEAC4,
  "04_00_p_TurnArrow_RoundAboutTrsLeft00":              0xEAC3,
  "04_00_p_TurnArrow_RoundAboutTrsLeft10_30":           0xEAC2,
  "04_00_p_TurnArrow_RoundAboutTrsLeft40":              0xEAC1,
  "04_00_p_TurnArrow_RoundAboutTrsLeft50_70":           0xEAC0,
  "04_00_p_TurnArrow_RoundAboutTrsLeft80":              0xEABF,
  "04_00_p_TurnArrow_RoundAboutTrsLeft90_B0":           0xEABE,
  "04_00_p_TurnArrow_RoundAboutTrsLeftC0":              0xEABD,
  "04_00_p_TurnArrow_RoundAboutTrsLeftD0_F0":           0xEABC,
  "04_00_p_TurnArrow_RoundAboutTrsRight00":             0xEABB,
  "04_00_p_TurnArrow_RoundAboutTrsRight10_30":          0xEABA,
  "04_00_p_TurnArrow_RoundAboutTrsRight40":             0xEAB9,
  "04_00_p_TurnArrow_RoundAboutTrsRight50_70":          0xEAB8,
  "04_00_p_TurnArrow_RoundAboutTrsRight80":             0xEAB7,
  "04_00_p_TurnArrow_RoundAboutTrsRight90_B0":          0xEAB6,
  "04_00_p_TurnArrow_RoundAboutTrsRightC0":             0xEAB5,
  "04_00_p_TurnArrow_RoundAboutTrsRightD0_F0":          0xEAB4,
  "04_00_p_TurnArrow_ServiceRoadLeft00":                0xEAB3,
  "04_00_p_TurnArrow_ServiceRoadRight00":               0xEAB2,
  "04_00_p_TurnArrow_SquareTrsLeft00":                  0xEAB1,
  "04_00_p_TurnArrow_SquareTrsLeft10":                  0xEAB0,
  "04_00_p_TurnArrow_SquareTrsLeft20":                  0xEAAF,
  "04_00_p_TurnArrow_SquareTrsLeft30_50":               0xEAAE,
  "04_00_p_TurnArrow_SquareTrsLeft60_70":               0xEAAD,
  "04_00_p_TurnArrow_SquareTrsLeft80":                  0xEAAC,
  "04_00_p_TurnArrow_SquareTrsLeft90_A0":               0xEAAB,
  "04_00_p_TurnArrow_SquareTrsLeftB0_D0":               0xEAAA,
  "04_00_p_TurnArrow_SquareTrsLeftE0":                  0xEAA9,
  "04_00_p_TurnArrow_SquareTrsLeftF0":                  0xEAA8,
  "04_00_p_TurnArrow_SquareTrsRight00":                 0xEAA7,
  "04_00_p_TurnArrow_SquareTrsRight10":                 0xEAA6,
  "04_00_p_TurnArrow_SquareTrsRight20":                 0xEAA5,
  "04_00_p_TurnArrow_SquareTrsRight30_50":              0xEAA4,
  "04_00_p_TurnArrow_SquareTrsRight60_70":              0xEAA3,
  "04_00_p_TurnArrow_SquareTrsRight80":                 0xEAA2,
  "04_00_p_TurnArrow_SquareTrsRight90_A0":              0xEAA1,
  "04_00_p_TurnArrow_SquareTrsRightB0_D0":              0xEAA0,
  "04_00_p_TurnArrow_SquareTrsRightE0":                 0xEA9F,
  "04_00_p_TurnArrow_SquareTrsRightF0":                 0xEA9E,
  "04_00_p_TurnArrow_StandardTurn00":                   0xEA9D,
  "04_00_p_TurnArrow_StandardTurn20":                   0xEA9C,
  "04_00_p_TurnArrow_StandardTurn40":                   0xEA9B,
  "04_00_p_TurnArrow_StandardTurn60":                   0xEA9A,
  "04_00_p_TurnArrow_StandardTurnA0":                   0xEA99,
  "04_00_p_TurnArrow_StandardTurnC0":                   0xEA98,
  "04_00_p_TurnArrow_StandardTurnE0":                   0xEA97,
  "04_00_p_TurnArrow_UTurn40":                          0xEA96,
  "04_00_p_TurnArrow_UTurnC0":                          0xEA95,
  "04_base_layer_RoundAbout":                           0xEA94,
  "04_base_layer_Square":                               0xEB85,
  "3D_map":                                             0xE9A4,
  "3g":                                                 0xE979,
  "4G":                                                 0xE96B,
  "4WD":                                                0xEAF5,
  NPR_CN_active:                                        0xEC75,
  NPR_CN_inactive:                                      0xEC74,
  PoweredByGracenote_Logo:                              0xE9E1,
  abs_failure:                                          0xEAE7,
  abs_na:                                               0xEAE8,
  ac:                                                   0xE965,
  ac_eco:                                               0xE966,
  ac_max:                                               0xEA32,
  aca_hands_off_warning:                                0xEB23,
  aca_lks:                                              0xE99B,
  acc:                                                  0xEAEA,
  active_safe:                                          0xED00,
  ad_h2ionx:                                            0xEC4B,
  ad_h2o:                                               0xEDA5,
  add_bluetooth:                                        0xEB33,
  add_headset:                                          0xECBA,
  address_home:                                         0xE988,
  address_work:                                         0xE989,
  addressbook:                                          0xE9E9,
  aero_spoiler:                                         0xECA3,
  ahk_warning:                                          0xEC1B,
  air:                                                  0xEBD9,
  air_center_devided:                                   0xEC38,
  air_center_j1:                                        0xEA21,
  air_distance_filled:                                  0xE983,
  air_distance_filled_circle:                           0xEDBA,
  air_distance_filled_circle_small:                     0xEDBB,
  air_distance_to_poi:                                  0xE986,
  air_distribution_left:                                0xE995,
  air_distribution_left_down:                           0xEA2D,
  air_distribution_left_mid:                            0xEA2C,
  air_distribution_left_up:                             0xEA2B,
  air_distribution_right:                               0xE9AB,
  air_distribution_right_down:                          0xEA2A,
  air_distribution_right_mid:                           0xEA29,
  air_distribution_right_up:                            0xEA28,
  air_flow_off:                                         0xECEF,
  air_flow_on:                                          0xECEE,
  air_footwell_left:                                    0xEB52,
  air_footwell_left_cold:                               0xE91F,
  air_footwell_left_warm:                               0xEBA2,
  air_footwell_right:                                   0xEB55,
  air_footwell_right_cold:                              0xEBA3,
  air_footwell_right_warm:                              0xEBA4,
  air_style_left_1:                                     0xEA25,
  air_style_left_2:                                     0xEA26,
  air_style_left_3:                                     0xEA27,
  air_style_right:                                      0xE9C0,
  air_style_right_1:                                    0xEA22,
  air_style_right_2:                                    0xEA23,
  air_style_right_3:                                    0xEA24,
  airbag_warning:                                       0xEAEB,
  aktien_flop:                                          0xEC0C,
  aktien_top:                                           0xEC0D,
  all:                                                  0xE9EA,
  all_outline:                                          0xE9EB,
  altitude:                                             0xE9B9,
  am_linking:                                           0xEC7C,
  am_radio:                                             0xEBFA,
  ambient_light:                                        0xE9D9,
  angle_of_gradient:                                    0xEC95,
  apple_add_to_playlist:                                0xEBAA,
  apple_autoplay:                                       0xED2C,
  apple_carplay:                                        0xE93B,
  apple_create_station:                                 0xEBAB,
  apple_dislike:                                        0xEBD5,
  apple_explicit:                                       0xEBC5,
  apple_go_to_show:                                     0xED43,
  apple_icloud:                                         0xE9E6,
  apple_library:                                        0xEBAC,
  apple_love:                                           0xEBD4,
  apple_lyrics:                                         0xEBAD,
  apple_play_later:                                     0xEBAE,
  apple_play_next:                                      0xEBAF,
  apple_podcasts_library:                               0xEC7B,
  apple_podcasts_listennow:                             0xEC7A,
  apple_remove_from_playlist:                           0xEBB0,
  ara_parking:                                          0xEC6A,
  arrow_down:                                           0xEA1A,
  arrow_open_down:                                      0xE9AA,
  arrow_open_full_left:                                 0xEADA,
  arrow_open_full_right:                                0xEAD9,
  arrow_open_left:                                      0xE93C,
  arrow_open_right:                                     0xE93D,
  arrow_open_up:                                        0xE9A9,
  arrow_scroll_up:                                      0xECE4,
  arrow_up:                                             0xE900,
  art_culture:                                          0xE913,
  ass_basic:                                            0xE9C5,
  ass_basic_e3_pa:                                      0xECED,
  ass_basic_g3:                                         0xECEC,
  ass_disabled_e3_pa:                                   0xECE3,
  ass_disabled_g3:                                      0xECE2,
  ass_disabled_j1:                                      0xEA16,
  ass_e3_pa:                                            0xECE1,
  ass_g3:                                               0xECE0,
  ass_j1:                                               0xE9BA,
  ass_limousine:                                        0xEC69,
  ass_sport:                                            0xEC68,
  ass_suv:                                              0xEC67,
  assist_on_off:                                        0xEBC6,
  assist_settings_e3_pa:                                0xECDF,
  assist_settings_g3:                                   0xECDE,
  asterisk:                                             0xE9EC,
  asterisk_asia:                                        0xE9ED,
  at:                                                   0xE941,
  audio_volume_headset:                                 0xECB9,
  audio_volume_headset_mute:                            0xED0B,
  auto_sync:                                            0xEC1C,
  average_speed:                                        0xED12,
  avoid_ferry_car_train_not:                            0xE9B7,
  avoid_traffic:                                        0xEC94,
  awv_prewarning_limousine:                             0xED6F,
  awv_prewarning_sport:                                 0xEDBD,
  awv_prewarning_suv:                                   0xEDBC,
  b_call_premium:                                       0xEAE1,
  back:                                                 0xEDA4,
  back_out_assistant:                                   0xE99C,
  bag:                                                  0xECFF,
  base_mode_menu:                                       0xEBDA,
  battery_0:                                            0xE901,
  battery_1:                                            0xE902,
  battery_2:                                            0xE903,
  battery_3:                                            0xE904,
  battery_4:                                            0xE905,
  battery_blank:                                        0xE906,
  beam_levelling_control:                               0xEAFE,
  beam_matrix_light:                                    0xED6A,
  best_lap:                                             0xED2D,
  bin:                                                  0xE98A,
  blinds_down:                                          0xED2B,
  blinds_up:                                            0xED2A,
  bluetooth:                                            0xE91E,
  bookmark:                                             0xEA01,
  bookmark_outline:                                     0xEC99,
  boost_assist:                                         0xEC93,
  boost_gauge:                                          0xED61,
  box:                                                  0xEBBC,
  box_checked:                                          0xEBBD,
  box_checked_all:                                      0xED09,
  box_checked_empty:                                    0xEBBE,
  brake_failure:                                        0xEAEF,
  brake_fluid_na:                                       0xEAF0,
  brake_key_failure:                                    0xEB34,
  brake_wear_na:                                        0xEAEE,
  brake_worn_linings:                                   0xEAED,
  bubble_info:                                          0xE9EE,
  bubble_infos:                                         0xED29,
  bubble_recommendations:                               0xEBB3,
  bubble_subtitle:                                      0xED28,
  bus_front:                                            0xED16,
  bypass:                                               0xEA15,
  calendar_date:                                        0xED11,
  calendar_date_component_brackets:                     0xED5C,
  calendar_date_component_calendar:                     0xED5B,
  camera:                                               0xE9E5,
  camera_arrow:                                         0xEA2F,
  camera_circle:                                        0xEDB8,
  camera_circle_small:                                  0xEDB9,
  camera_off:                                           0xEDA3,
  cancel:                                               0xE907,
  car_back_e3_pa:                                       0xECB8,
  car_back_g3:                                          0xECB7,
  car_back_suv:                                         0xEC81,
  car_front_911:                                        0xEC39,
  car_front_cayenne:                                    0xEC3A,
  car_front_e3_pa:                                      0xECB6,
  car_front_g3:                                         0xECB5,
  car_front_j1:                                         0xE968,
  car_front_macan_nf:                                   0xECA7,
  car_front_panamera:                                   0xEC3B,
  car_generic_xl:                                       0xED05,
  car_limousine:                                        0xE945,
  car_limousine_acc_hdc:                                0xEC4A,
  car_limousine_auto_recirculation:                     0xE997,
  car_limousine_blinds_back:                            0xEC3C,
  car_limousine_frontlift_plus_one:                     0xEC1D,
  car_limousine_frontlift_plus_two:                     0xEC1E,
  car_limousine_hood:                                   0xEB75,
  car_limousine_hybrid:                                 0xEC3D,
  car_limousine_niveau:                                 0xE946,
  car_limousine_niveau_lift_vehicle:                    0xECFE,
  car_limousine_niveau_minus_one:                       0xECFD,
  car_limousine_niveau_minus_two:                       0xECFC,
  car_limousine_niveau_normal:                          0xEC1F,
  car_limousine_niveau_plus_one:                        0xECFB,
  car_limousine_niveau_plus_two:                        0xECFA,
  car_limousine_niveau_sink_vehicle:                    0xECF9,
  car_limousine_outside_temp:                           0xEAF6,
  car_limousine_ready_cn:                               0xEB12,
  car_limousine_sound_off:                              0xE978,
  car_limousine_spoiler:                                0xE947,
  car_limousine_system_failure_text_cn:                 0xEB18,
  car_limousine_trunk_close_arrow:                      0xECDD,
  car_limousine_trunk_open_arrow:                       0xECDC,
  car_limousine_warning:                                0xEAF3,
  car_limousine_warning_hybrid:                         0xEAFA,
  car_side_cayenne:                                     0xEC80,
  car_sport:                                            0xEC3F,
  car_sport_auto_recirculation:                         0xEC3E,
  car_sport_convertible:                                0xEDA1,
  car_sport_convertible_open:                           0xEDA2,
  car_sport_functions:                                  0xED27,
  car_sport_niveau:                                     0xEDA0,
  car_sport_ready_CN:                                   0xED9F,
  car_sport_recirculation:                              0xEC9B,
  car_sport_sound_off:                                  0xED9E,
  car_sport_spoiler:                                    0xEC89,
  car_sport_spoiler_front:                              0xEC9D,
  car_sport_system_failure_text_CN:                     0xED9D,
  car_sport_targa:                                      0xED9B,
  car_sport_targa_open:                                 0xED9C,
  car_sport_trunk:                                      0xED99,
  car_sport_trunk_close_arrow:                          0xEB76,
  car_sport_trunk_open:                                 0xED9A,
  car_sport_trunk_open_arrow:                           0xEB77,
  car_sport_warning:                                    0xED98,
  car_suv:                                              0xEC4F,
  car_suv_acc_hdc:                                      0xEC49,
  car_suv_auto_recirculation:                           0xEC40,
  car_suv_functions:                                    0xED24,
  car_suv_hybrid:                                       0xEC4C,
  car_suv_intelligent_parking:                          0xED0A,
  car_suv_niveau:                                       0xEC98,
  car_suv_niveau_lift_vehicle:                          0xECF8,
  car_suv_niveau_minus_one:                             0xECF7,
  car_suv_niveau_minus_two:                             0xECF6,
  car_suv_niveau_plus_one:                              0xECF5,
  car_suv_niveau_plus_two:                              0xECF4,
  car_suv_niveau_sink_vehicle:                          0xECF3,
  car_suv_offroad:                                      0xEC4D,
  car_suv_outside_temp:                                 0xED97,
  car_suv_ready_CN:                                     0xED96,
  car_suv_reversing:                                    0xED10,
  car_suv_sink_back:                                    0xED95,
  car_suv_sound_off:                                    0xED94,
  car_suv_spoiler:                                      0xEC4E,
  car_suv_system_failure_text_CN:                       0xED93,
  car_suv_trunk_open:                                   0xED92,
  car_suv_warning:                                      0xED90,
  car_suv_warning_hybrid:                               0xED91,
  car_top_view:                                         0xEC50,
  car_wash_view:                                        0xED38,
  cell_phone:                                           0xE908,
  cell_phone_arrow_down:                                0xED22,
  cell_phone_arrow_up:                                  0xED21,
  cell_phone_charging:                                  0xEC79,
  cell_phone_crossed:                                   0xE973,
  cell_phone_link:                                      0xE9DB,
  channel:                                              0xE920,
  channel_no_signal:                                    0xE98C,
  charge_premium:                                       0xEB4E,
  charge_timer:                                         0xEADB,
  charging_active:                                      0xEB43,
  charging_average_consumption:                         0xED0F,
  charging_inductive:                                   0xEB3D,
  charging_profile:                                     0xEBB5,
  charging_profile_baseline:                            0xEC14,
  charging_station:                                     0xE914,
  charging_station_ac:                                  0xEBFF,
  charging_station_component:                           0xED5A,
  charging_station_dc:                                  0xEBFE,
  charging_station_hpc:                                 0xEBFD,
  charging_station_warning:                             0xEB32,
  charging_station_warning_outline:                     0xEB2F,
  charging_target_state:                                0xEBB4,
  charging_target_state_baseline:                       0xEC15,
  check:                                                0xEA07,
  childlock:                                            0xEAFC,
  childlock_active:                                     0xED20,
  circle:                                               0xE9E4,
  circle_check:                                         0xED60,
  circle_crossed:                                       0xE974,
  circle_filled:                                        0xE996,
  circle_outline:                                       0xE95D,
  city_events:                                          0xEB54,
  cityseeker_skyline:                                   0xEB70,
  cleaning_camera:                                      0xE9D6,
  climate:                                              0xEA1B,
  climate_sync:                                         0xEC2C,
  clock_time:                                           0xEA18,
  clock_time_component_circle:                          0xED59,
  clock_time_component_hour:                            0xED58,
  clock_time_component_minute:                          0xED57,
  codriver_cockpit:                                     0xEB9B,
  comma_fullstop_hiragana:                              0xE9F0,
  comma_fullstop_taiwan:                                0xE9F1,
  comma_point_hiragana:                                 0xE9F2,
  communication:                                        0xE9EF,
  compass:                                              0xEBF1,
  compass_component_circle:                             0xED56,
  compass_component_needle:                             0xED55,
  compass_north_arrow_3d:                               0xED26,
  concierge:                                            0xE985,
  conference_call:                                      0xEA33,
  connect:                                              0xEA90,
  connect_app:                                          0xE975,
  connectivity:                                         0xE976,
  connectivity_disabled:                                0xE977,
  control_e3_pa:                                        0xECDB,
  control_g3:                                           0xECDA,
  coolant_temperature:                                  0xEB0E,
  cover:                                                0xE922,
  cross_lock:                                           0xEB04,
  cross_over_assistant:                                 0xE99D,
  crossing_car_detection:                               0xEDB5,
  dab_linking:                                          0xEC48,
  data_tracking:                                        0xEBA1,
  dataplan:                                             0xECC2,
  date_day:                                             0xEA37,
  date_today:                                           0xEA38,
  date_weekend:                                         0xEA39,
  delete_all:                                           0xED25,
  delete_entry:                                         0xE969,
  delete_folder:                                        0xECA2,
  delete_numbers:                                       0xE909,
  delete_numbers_arabic:                                0xE90A,
  destination_flag:                                     0xE915,
  destination_flag_arrow:                               0xE981,
  destination_flag_left:                                0xEB72,
  destination_flag_right:                               0xEB73,
  destination_flag_stop:                                0xE9F3,
  destination_flag_traffic_eu_mrm_kor:                  0xEDB4,
  destination_flag_traffic_nar:                         0xEDB3,
  display_off:                                          0xEC20,
  distance_poi_destination:                             0xEC03,
  distance_poi_stopover:                                0xEC02,
  dls_top:                                              0xE916,
  door_lock_limousine:                                  0xEB22,
  door_lock_sport:                                      0xED8F,
  door_lock_suv:                                        0xED8E,
  dot:                                                  0xE94A,
  double_de_clutch:                                     0xEC51,
  drag_vertical:                                        0xEA1F,
  drive_together:                                       0xEC10,
  drowsiness_warning:                                   0xED69,
  drowsiness_warning_cn:                                0xED42,
  drowsiness_warning_off:                               0xED8C,
  drowsiness_warning_off_CN:                            0xED8D,
  duration:                                             0xEB5B,
  duration_e:                                           0xED2F,
  e_ac:                                                 0xEB6A,
  e_ac_baseline:                                        0xEC16,
  e_range:                                              0xEB07,
  e_range_cn:                                           0xEBC7,
  e_range_nav:                                          0xEBBB,
  e_range_nav_component:                                0xED54,
  e_sound:                                              0xE948,
  easy_entry:                                           0xEAE5,
  eco:                                                  0xEC2B,
  eco_plus:                                             0xEB6B,
  edge:                                                 0xE96C,
  edit:                                                 0xE98B,
  electric_driving_mileage_of_hybrid_vehicles_mode_cn:  0xEB13,
  electric_motor_failure_cn:                            0xEB19,
  electricity:                                          0xE9CE,
  electricity_baseline:                                 0xEC17,
  emergency_assist:                                     0xE9B0,
  emergency_assist_j1:                                  0xE9A1,
  emergency_assist_off:                                 0xED8B,
  engine:                                               0xEB06,
  engine_coolant_level:                                 0xEAFD,
  enter:                                                0xE94B,
  enter_arabic:                                         0xE94C,
  epg:                                                  0xE98E,
  esp_psm_off:                                          0xEAF4,
  etc_active:                                           0xE96D,
  etc_error:                                            0xE96E,
  ev_charging_connector_lock_cn:                        0xEB16,
  exhaust:                                              0xEC52,
  exit:                                                 0xECC1,
  exit_warning:                                         0xE99F,
  export:                                               0xEA91,
  external_assistant:                                   0xEC05,
  eye:                                                  0xEBCE,
  eye_off:                                              0xEBCF,
  eye_warning:                                          0xED68,
  family:                                               0xE9FD,
  fast_backward:                                        0xE992,
  fast_forward:                                         0xE993,
  festival:                                             0xE9FF,
  filler_cap_ac_left:                                   0xEB44,
  filler_cap_ac_right:                                  0xEB45,
  filler_cap_acdc:                                      0xED73,
  filler_cap_dc_left:                                   0xEB46,
  filler_cap_dc_right:                                  0xEB47,
  filler_cap_dcac_left:                                 0xEB48,
  filler_cap_dcac_right:                                0xEB49,
  filler_cap_electric_warning:                          0xED72,
  filler_cap_electric_warning_left:                     0xEB17,
  filler_cap_electric_warning_right:                    0xEB0D,
  filler_cap_mechanic_warning_left:                     0xEBBA,
  filler_cap_mechanic_warning_right:                    0xEBB9,
  filter:                                               0xEB28,
  flight_info:                                          0xEB53,
  fm_dab:                                               0xE9A5,
  fm_dab_no_signal:                                     0xE9A6,
  fm_dab_radio:                                         0xEBFB,
  fm_linking:                                           0xE990,
  fm_linking_list:                                      0xE927,
  fm_radio:                                             0xE9A7,
  fm_radio_no_signal:                                   0xE9A6,
  fod:                                                  0xEB24,
  folder:                                               0xE98F,
  four_squares:                                         0xE9DA,
  freeway:                                              0xE9B4,
  freeway_toll:                                         0xE9B6,
  front_window_defrost:                                 0xEBC2,
  front_window_wind_deflector:                          0xED8A,
  front_window_wiping_water:                            0xEB10,
  fuel:                                                 0xEC9C,
  fuel_average_consumption:                             0xED0E,
  fuel_indicator_right:                                 0xEBA0,
  fuel_warning:                                         0xED89,
  genre:                                                0xE9FC,
  geo_localization:                                     0xE972,
  gforce_acceleration:                                  0xEB26,
  gforce_delay:                                         0xEB27,
  gforce_lateral_acceleration:                          0xEB25,
  gforce_linear_acceleration:                           0xED0D,
  glass_roof:                                           0xEC6D,
  glass_roof_close:                                     0xEC71,
  glass_roof_half_open_1:                               0xEC70,
  glass_roof_half_open_2:                               0xEC6F,
  glass_roof_open:                                      0xEC6E,
  globe:                                                0xE94D,
  globe_na:                                             0xEB50,
  globe_small:                                          0xEBA6,
  globe_small_na:                                       0xEBA5,
  go_to:                                                0xECD9,
  google_search:                                        0xEB41,
  google_search_inactive:                               0xEB41,
  grounding:                                            0xED87,
  grounding_crossed:                                    0xED88,
  group:                                                0xE90B,
  hal_1_2:                                              0xEAF9,
  hands_free:                                           0xED5E,
  handwriting:                                          0xE94E,
  hd_voice:                                             0xEADD,
  hdmi:                                                 0xEC21,
  headset:                                              0xECC0,
  heart:                                                0xECB3,
  heart_outline:                                        0xECB4,
  heater_fan:                                           0xEC53,
  heater_fond:                                          0xECB2,
  hiragana_function_key:                                0xE9F9,
  home:                                                 0xE96F,
  home_sync:                                            0xEB86,
  homelink:                                             0xEB59,
  homelink_1:                                           0xECB1,
  homelink_2:                                           0xECB0,
  homelink_3:                                           0xECAF,
  homelink_send:                                        0xEB36,
  homelink_send_1:                                      0xEBD1,
  homelink_send_2:                                      0xEBD2,
  homelink_send_3:                                      0xEBD3,
  hov_avoid_not:                                        0xE9B5,
  icon_traffic_assist_limousine:                        0xEC5D,
  icy_wet_road:                                         0xED86,
  inclination_suv:                                      0xEC92,
  information:                                          0xE90C,
  information_baseline:                                 0xEC18,
  information_outline:                                  0xE90D,
  intelligent_parking_payment:                          0xED15,
  ionisation:                                           0xE970,
  ipa:                                                  0xE9D3,
  ipa_baseline:                                         0xEC19,
  ipa_leave_left:                                       0xEA1C,
  ipa_leave_right:                                      0xEA1D,
  ipa_scanner_e3_pa:                                    0xECEB,
  ipa_scanner_g3:                                       0xECEA,
  ipa_scanner_j1:                                       0xE9F4,
  ipa_scanner_sport:                                    0xEC47,
  ipa_scanner_suv:                                      0xEC46,
  ipa_search_left:                                      0xE9FB,
  ipa_search_right:                                     0xE9FA,
  ipod:                                                 0xE98D,
  iptv:                                                 0xED40,
  key:                                                  0xEB09,
  keyboard:                                             0xE94F,
  keyboard_hide:                                        0xE950,
  keyboard_special_characters:                          0xECF2,
  knowledge:                                            0xE9FE,
  lane_change_assist:                                   0xE99E,
  lane_departure_warning_system_e3_pa:                  0xECD7,
  lane_departure_warning_system_e3_pa_off:              0xECD8,
  lane_departure_warning_system_g3:                     0xECD5,
  lane_departure_warning_system_g3_off:                 0xECD6,
  lane_departure_warning_system_j1:                     0xE9AF,
  lane_departure_warning_system_limousine:              0xEC66,
  lane_departure_warning_system_sport:                  0xEC65,
  lane_departure_warning_system_suv:                    0xEC64,
  laptop:                                               0xE918,
  last_destination:                                     0xE987,
  lateral_acceleration_component_arrow_left_active:     0xED53,
  lateral_acceleration_component_arrow_left_inactive:   0xED52,
  lateral_acceleration_component_arrow_right_active:    0xED51,
  lateral_acceleration_component_arrow_right_inactive:  0xED50,
  lateral_acceleration_component_center:                0xED4F,
  lateral_acceleration_component_dots:                  0xED4E,
  leaf_economy:                                         0xEC88,
  lg_2s_dr_00_links:                                    0xEA65,
  lg_2s_dr_00_rechts:                                   0xEA64,
  lg_2s_dr_20_mitte:                                    0xEA63,
  lg_2s_dr_20_rechts_kurz:                              0xEA62,
  lg_2s_dr_20_rechts_lang:                              0xEA61,
  lg_2s_dr_40_mitte:                                    0xEA5F,
  lg_2s_dr_40_mitte_kurz:                               0xEA60,
  lg_2s_dr_40_mitte_kurz_n:                             0xEBDE,
  lg_2s_dr_40_rechts_kurz:                              0xEA5E,
  lg_2s_dr_40_rechts_lang:                              0xEA5D,
  lg_2s_dr_60_mitte:                                    0xEA5B,
  lg_2s_dr_60_mitte_kurz:                               0xEA5C,
  lg_2s_dr_60_mitte_n:                                  0xEBDF,
  lg_2s_dr_60_rechts_kurz:                              0xEA5A,
  lg_2s_dr_60_rechts_lang:                              0xEA59,
  lg_2s_dr_72_mitte:                                    0xEA58,
  lg_2s_dr_72_rechts:                                   0xEA57,
  lg_2s_dr_92_links:                                    0xEA56,
  lg_2s_dr_92_mitte:                                    0xEA55,
  lg_2s_dr_a0_links_kurz:                               0xEA54,
  lg_2s_dr_a0_links_lang:                               0xEA53,
  lg_2s_dr_a0_mitte:                                    0xEA51,
  lg_2s_dr_a0_mitte_kurz:                               0xEA52,
  lg_2s_dr_a0_mitte_n:                                  0xEBE0,
  lg_2s_dr_c0_links_kurz:                               0xEA50,
  lg_2s_dr_c0_links_lang:                               0xEA4F,
  lg_2s_dr_c0_mitte:                                    0xEA4D,
  lg_2s_dr_c0_mitte_kurz:                               0xEA4E,
  lg_2s_dr_c0_mitte_kurz_n:                             0xEBE1,
  lg_2s_dr_e0_links_kurz:                               0xEA4C,
  lg_2s_dr_e0_links_lang:                               0xEA4B,
  lg_2s_dr_e0_mitte:                                    0xEA4A,
  lg_3s_dr_00_links:                                    0xEA8E,
  lg_3s_dr_00_mitte:                                    0xEA8D,
  lg_3s_dr_00_rechts:                                   0xEA8C,
  lg_3s_dr_20_mitte:                                    0xEA8A,
  lg_3s_dr_20_mitte_lang:                               0xEA8B,
  lg_3s_dr_20_rechts:                                   0xEA88,
  lg_3s_dr_20_rechts_lang:                              0xEA89,
  lg_3s_dr_20_rechts_lang_n:                            0xEBE2,
  lg_3s_dr_20_rechts_n:                                 0xEBE3,
  lg_3s_dr_40_mitte_kurz:                               0xEA87,
  lg_3s_dr_40_mitte_lang:                               0xEA86,
  lg_3s_dr_40_rechts:                                   0xEA83,
  lg_3s_dr_40_rechts_kurz:                              0xEA85,
  lg_3s_dr_40_rechts_kurz_n:                            0xEBE4,
  lg_3s_dr_40_rechts_lang:                              0xEA84,
  lg_3s_dr_40_rechts_lang_n:                            0xEBE5,
  lg_3s_dr_40_rechts_n:                                 0xEBE6,
  lg_3s_dr_60_mitte:                                    0xEA80,
  lg_3s_dr_60_mitte_kurz:                               0xEA82,
  lg_3s_dr_60_mitte_lang:                               0xEA81,
  lg_3s_dr_60_rechts:                                   0xEA7D,
  lg_3s_dr_60_rechts_kurz:                              0xEA7F,
  lg_3s_dr_60_rechts_lang:                              0xEA7E,
  lg_3s_dr_72_mitte:                                    0xEA7B,
  lg_3s_dr_72_mitte_kurz:                               0xEA7C,
  lg_3s_dr_72_rechts:                                   0xEA79,
  lg_3s_dr_72_rechts_kurz:                              0xEA7A,
  lg_3s_dr_92_links:                                    0xEA77,
  lg_3s_dr_92_links_kurz:                               0xEA78,
  lg_3s_dr_92_mitte:                                    0xEA75,
  lg_3s_dr_92_mitte_kurz:                               0xEA76,
  lg_3s_dr_a0_links:                                    0xEA72,
  lg_3s_dr_a0_links_kurz:                               0xEA74,
  lg_3s_dr_a0_links_lang:                               0xEA73,
  lg_3s_dr_a0_mitte:                                    0xEA6F,
  lg_3s_dr_a0_mitte_kurz:                               0xEA71,
  lg_3s_dr_a0_mitte_lang:                               0xEA70,
  lg_3s_dr_c0_links:                                    0xEA6C,
  lg_3s_dr_c0_links_kurz:                               0xEA6E,
  lg_3s_dr_c0_links_kurz_n:                             0xEBE7,
  lg_3s_dr_c0_links_lang:                               0xEA6D,
  lg_3s_dr_c0_links_lang_n:                             0xEBE8,
  lg_3s_dr_c0_links_n:                                  0xEBE9,
  lg_3s_dr_c0_mitte_kurz:                               0xEA6B,
  lg_3s_dr_c0_mitte_lang:                               0xEA6A,
  lg_3s_dr_e0_links:                                    0xEA68,
  lg_3s_dr_e0_links_lang:                               0xEA69,
  lg_3s_dr_e0_links_lang_n:                             0xEBEA,
  lg_3s_dr_e0_links_n:                                  0xEBEB,
  lg_3s_dr_e0_mitte:                                    0xEA66,
  lg_3s_dr_e0_mitte_lang:                               0xEA67,
  lg_dr_00:                                             0xEA49,
  lg_dr_20:                                             0xEA48,
  lg_dr_40:                                             0xEA47,
  lg_dr_40_n:                                           0xEBEC,
  lg_dr_60:                                             0xEA46,
  lg_dr_60_n:                                           0xEBED,
  lg_dr_72:                                             0xEA45,
  lg_dr_92:                                             0xEA44,
  lg_dr_a0:                                             0xEA43,
  lg_dr_a0_n:                                           0xEBEE,
  lg_dr_c0:                                             0xEA42,
  lg_dr_c0_n:                                           0xEBEF,
  lg_dr_e0:                                             0xEA41,
  lg_special_buslane:                                   0xEA93,
  lg_special_carpool:                                   0xEBCD,
  lg_special_lane_widget_points:                        0xEA8F,
  lift_sport:                                           0xED85,
  light_bulb:                                           0xED84,
  light_failure:                                        0xEAFF,
  lim:                                                  0xEB11,
  limited_performance_mode_power_cn:                    0xEB14,
  limited_performance_mode_turtle_cn:                   0xEB15,
  limited_power:                                        0xED23,
  linear_acceleration:                                  0xED4D,
  linear_acceleration_component_arrow_down_active:      0xED4C,
  linear_acceleration_component_arrow_down_inactive:    0xED4B,
  linear_acceleration_component_arrow_up_active:        0xED4A,
  linear_acceleration_component_arrow_up_inactive:      0xED49,
  linear_acceleration_component_dots:                   0xED48,
  list:                                                 0xEA00,
  live:                                                 0xE994,
  load_lap:                                             0xEC54,
  lock_locked:                                          0xEB08,
  logo_HeFeng:                                          0xEADE,
  logo_amazon_music:                                    0xEC6C,
  logo_android_auto:                                    0xEC55,
  logo_apple_music:                                     0xEBB1,
  logo_apple_podcasts:                                  0xEBFC,
  logo_asterix:                                         0xEC87,
  logo_audials:                                         0xEC6B,
  logo_auro_3d:                                         0xEADF,
  logo_autonavi:                                        0xEBDD,
  logo_blinklist:                                       0xEC0E,
  logo_bose:                                            0xEA3D,
  logo_burmester:                                       0xEA3F,
  logo_cityseeker:                                      0xEB30,
  logo_deezer:                                          0xEC22,
  logo_dolby_audio:                                     0xEC1A,
  logo_feedly:                                          0xEBB8,
  logo_google:                                          0xEC9A,
  logo_google_play:                                     0xEC78,
  logo_hd_radio:                                        0xEB9D,
  logo_hd_radio_short:                                  0xEC86,
  logo_kt:                                              0xEC85,
  logo_navitime:                                        0xEBDC,
  logo_pandora:                                         0xEC77,
  logo_powered_by_google:                               0xEAE0,
  logo_qi:                                              0xEB5A,
  logo_radio_net_long:                                  0xEA1E,
  logo_results_from_google:                             0xEC76,
  logo_results_from_google_two_rows:                    0xECAE,
  logo_results_from_google_two_rows_button_variant:     0xECE9,
  logo_sirius_xm_long:                                  0xE9F5,
  logo_sirius_xm_short:                                 0xE9F6,
  logo_spotify:                                         0xE92E,
  logo_taycan:                                          0xEA3B,
  logo_yellowmap:                                       0xEB71,
  logo_yelp:                                            0xED1F,
  lte:                                                  0xE971,
  macan_filler_cap_ac_right:                            0xED83,
  macan_filler_cap_acdc_left:                           0xED82,
  macan_filler_cap_dc_left:                             0xED81,
  macan_filler_cap_electric_warning_left:               0xED80,
  macan_filler_cap_electric_warning_right:              0xED7F,
  macan_filler_cap_mechanic_warning_left:               0xED7E,
  macan_filler_cap_mechanic_warning_right:              0xED7D,
  magnify_glass:                                        0xE934,
  maneuver_assist_e3_pa:                                0xECD3,
  maneuver_assist_e3_pa_off:                            0xECD4,
  maneuver_assist_g3:                                   0xECD1,
  maneuver_assist_g3_off:                               0xECD2,
  maneuver_assist_j1:                                   0xE9D5,
  maneuver_assist_j1_off:                               0xE9F8,
  maneuver_assist_limousine:                            0xEC62,
  maneuver_assist_limousine_off:                        0xEC63,
  maneuver_assist_sport:                                0xEC60,
  maneuver_assist_sport_off:                            0xEC61,
  maneuver_assist_suv:                                  0xEC5E,
  maneuver_assist_suv_off:                              0xEC5F,
  manual:                                               0xEAE6,
  map_layer:                                            0xE980,
  maximize:                                             0xEA30,
  message:                                              0xEB57,
  message_draft:                                        0xE9C9,
  message_inbox:                                        0xE9C7,
  message_outbox:                                       0xE9C8,
  message_outline:                                      0xE9AE,
  message_sent:                                         0xE9C6,
  message_template:                                     0xE9CA,
  message_write:                                        0xE9D7,
  microphone:                                           0xE93F,
  microphone_circle_filled:                             0xEDB6,
  microphone_circle_filled_small:                       0xEDB7,
  microphone_crossed:                                   0xE940,
  microphone_hand:                                      0xE919,
  microphone_processing:                                0xEC91,
  minimize:                                             0xEA31,
  minus:                                                0xEC00,
  money:                                                0xEB7A,
  more:                                                 0xE9E7,
  mountain_pass:                                        0xED0C,
  mute:                                                 0xE935,
  my_location:                                          0xE936,
  my_screen_settings:                                   0xEC84,
  navigation:                                           0xEC04,
  network_0:                                            0xE96A,
  network_5:                                            0xE96A,
  new:                                                  0xEC56,
  news:                                                 0xEB51,
  news_channel:                                         0xEA92,
  night_vision:                                         0xE9BE,
  nightlife:                                            0xE91A,
  north_arrow:                                          0xE937,
  note:                                                 0xE942,
  note_add_favorite:                                    0xE923,
  note_circle:                                          0xE921,
  notification:                                         0xE9C1,
  notification_3_digits:                                0xEA40,
  notification_information:                             0xECBF,
  off:                                                  0xE95E,
  oil_can:                                              0xED7C,
  oil_level:                                            0xED7B,
  oil_temperature:                                      0xED7A,
  on_off:                                               0xE9F7,
  online_linking:                                       0xE991,
  online_radio:                                         0xEAE2,
  open_all_day:                                         0xEC96,
  option_open:                                          0xE943,
  option_sort:                                          0xEC57,
  option_sort_up:                                       0xED67,
  option_view_1:                                        0xEBF0,
  options_more:                                         0xE9C2,
  organizer:                                            0xEB2A,
  organizer_week:                                       0xEC90,
  other_road:                                           0xEA14,
  parking:                                              0xE91B,
  parking_assistant:                                    0xE97B,
  parking_assistant_disabled:                           0xECBE,
  parking_brake:                                        0xEAF2,
  parking_brake_na:                                     0xEB79,
  parking_brake_pedal:                                  0xEC0F,
  parking_camera:                                       0xE97C,
  parking_cross_backward_left:                          0xE95F,
  parking_cross_backward_right:                         0xE960,
  parking_cross_forward_left:                           0xE961,
  parking_cross_forward_right:                          0xE962,
  parking_forward:                                      0xEBB6,
  parking_garage:                                       0xE963,
  parking_garage_e3_pa:                                 0xECE8,
  parking_garage_g3:                                    0xECE7,
  parking_garage_suv:                                   0xED3F,
  parking_light:                                        0xEB1F,
  parking_parallel_left:                                0xE964,
  parking_parallel_right:                               0xEA2E,
  parking_plus:                                         0xEB4F,
  particulate_filter:                                   0xED79,
  pasm:                                                 0xE949,
  pasm_normal:                                          0xEC83,
  pasm_warning:                                         0xEB01,
  pause:                                                0xE924,
  pdc_mute:                                             0xE9BB,
  pdc_unmute:                                           0xEC45,
  pdcc_e3_pa:                                           0xECD0,
  pdcc_g3:                                              0xECCF,
  pdcc_j1:                                              0xEB02,
  pdcc_sport:                                           0xED78,
  pdx_oversteer:                                        0xED1E,
  pdx_understeer:                                       0xED1D,
  pdx_wheelspin:                                        0xED1C,
  person:                                               0xE984,
  person_login:                                         0xEB74,
  phone_add_call:                                       0xE90E,
  phone_hang_up:                                        0xE90F,
  phone_incoming_call:                                  0xE910,
  phone_list:                                           0xED41,
  phone_missed_call:                                    0xE911,
  phone_outgoing_call:                                  0xE912,
  phone_receiver:                                       0xE9C4,
  phone_receiver_green:                                 0xE9AD,
  phone_ring_tone:                                      0xE9E2,
  phone_switch_call:                                    0xEB87,
  pid:                                                  0xEAFB,
  pid_upcoming_events_branch_off_left:                  0xEA0A,
  pid_upcoming_events_branch_off_right:                 0xEA0B,
  pid_upcoming_events_branch_off_straight:              0xED6E,
  pid_upcoming_events_highway_exit_left:                0xEA0C,
  pid_upcoming_events_highway_exit_right:               0xEA0D,
  pid_upcoming_events_highway_exit_straight:            0xED6D,
  pid_upcoming_events_junction:                         0xEA0E,
  pid_upcoming_events_left_curve:                       0xEA0F,
  pid_upcoming_events_priority_to_left_a:               0xEA10,
  pid_upcoming_events_priority_to_left_b:               0xEA11,
  pid_upcoming_events_priority_to_right_a:              0xEA12,
  pid_upcoming_events_priority_to_right_b:              0xEA13,
  pid_upcoming_events_prioritysign_priority_outline:    0xEC2D,
  pid_upcoming_events_right_curve:                      0xEA09,
  pid_upcoming_events_roundabout_traffic_sign:          0xED6C,
  pid_upcoming_events_speed_limit_generic:              0xEC2E,
  pid_upcoming_events_speed_limits:                     0xEC2F,
  pid_upcoming_events_traffic_jam_end:                  0xEADC,
  pid_upcoming_events_traffic_light_vertical:           0xED6B,
  pilopa:                                               0xEA3C,
  play:                                                 0xE925,
  play_circle:                                          0xEC13,
  play_video:                                           0xED37,
  playlist:                                             0xE926,
  plim_offset:                                          0xEB39,
  plim_three_digits:                                    0xEB38,
  plim_two_digits:                                      0xEB3C,
  plug:                                                 0xEB20,
  plug_ccs_type2:                                       0xEB78,
  plug_ccs_type_1:                                      0xEB7E,
  plug_chademo:                                         0xEB7B,
  plug_gbt_20234_2_cn:                                  0xEB7F,
  plug_gbt_20234_3_cn:                                  0xEB80,
  plug_iec_type1:                                       0xEB7C,
  plug_iec_type2:                                       0xEB7D,
  plug_left_ac_cn:                                      0xEB4A,
  plug_left_dc_cn:                                      0xED77,
  plug_right_ac_cn:                                     0xED76,
  plug_right_dc_cn:                                     0xEB4B,
  plug_short_left:                                      0xEB4C,
  plug_short_right:                                     0xEB4D,
  plug_small:                                           0xEBB7,
  plus:                                                 0xEA08,
  podcast:                                              0xE999,
  poi:                                                  0xE9BC,
  poi_accommodation:                                    0xED04,
  poi_common_used_cn:                                   0xED03,
  poi_filled:                                           0xE97A,
  poi_life:                                             0xED08,
  poi_listing:                                          0xEB2C,
  poi_outing:                                           0xED07,
  porsche_connect_app:                                  0xE9DC,
  porsche_labs:                                         0xEBDB,
  porsche_shield:                                       0xEC23,
  porsche_wappen:                                       0xEC06,
  pos_lever:                                            0xEB03,
  powermeter:                                           0xE9BF,
  predictive_maintenance:                               0xE9CB,
  predictive_maintenance_911:                           0xEC44,
  predictive_maintenance_cayenne:                       0xEC43,
  predictive_maintenance_e3_pa:                         0xECAD,
  predictive_maintenance_g3:                            0xECAC,
  predictive_maintenance_macan:                         0xEC42,
  predictive_maintenance_macan_nf:                      0xECA6,
  predictive_maintenance_panamer:                       0xEC41,
  predictive_speed_e3_pa:                               0xECE6,
  predictive_speed_g3:                                  0xECE5,
  predictive_speed_j1:                                  0xED66,
  predictive_speed_sport:                               0xED65,
  privacy:                                              0xE958,
  privacy_data:                                         0xEB9E,
  privacy_data_active:                                  0xEDB2,
  privacy_data_gps:                                     0xED5D,
  privacy_data_not_active:                              0xEDB1,
  privacy_data_tracking:                                0xEB9F,
  privacy_nad:                                          0xE959,
  propulsion_battery_temperature:                       0xEB1E,
  provider:                                             0xED31,
  qr_code_na:                                           0xEBB2,
  race_new_lap:                                         0xEC58,
  race_track:                                           0xEC59,
  radio:                                                0xE9C3,
  radio_button:                                         0xEBBF,
  radio_button_checked:                                 0xEBC0,
  radio_no_signal:                                      0xE928,
  radionet_Logo:                                        0xE9E3,
  rain:                                                 0xE9D1,
  rat_indicator_2g:                                     0xECCE,
  rat_indicator_3g:                                     0xECCD,
  rat_indicator_4g:                                     0xECCC,
  rat_indicator_5g:                                     0xECCB,
  rating_cityseeker_0:                                  0xEB89,
  rating_cityseeker_0_5:                                0xEB88,
  rating_cityseeker_1:                                  0xEB8B,
  rating_cityseeker_1_5:                                0xEB8A,
  rating_cityseeker_2:                                  0xEB8D,
  rating_cityseeker_2_5:                                0xEB8C,
  rating_cityseeker_3:                                  0xEB8F,
  rating_cityseeker_3_5:                                0xEB8E,
  rating_cityseeker_3star_0:                            0xEB91,
  rating_cityseeker_3star_0_5:                          0xEB90,
  rating_cityseeker_3star_1:                            0xEB93,
  rating_cityseeker_3star_1_5:                          0xEB92,
  rating_cityseeker_3star_2:                            0xEB95,
  rating_cityseeker_3star_2_5:                          0xEB94,
  rating_cityseeker_3star_3:                            0xEB96,
  rating_cityseeker_4:                                  0xEB98,
  rating_cityseeker_4_5:                                0xEB97,
  rating_cityseeker_5:                                  0xEB99,
  readout:                                              0xE938,
  readout_arrow_down:                                   0xED1B,
  readout_arrow_up:                                     0xED1A,
  rear_blind:                                           0xECCA,
  rear_camera_no_pdc:                                   0xEC30,
  rear_plus:                                            0xECAB,
  rear_seats_sound_off:                                 0xED02,
  rear_seats_sound_on:                                  0xED01,
  rear_window_defrost:                                  0xEBC1,
  recording:                                            0xE95A,
  recuperation:                                         0xEA17,
  recuperation_level_adaptive:                          0xEB42,
  recuperation_off:                                     0xED5F,
  refer_lap:                                            0xED36,
  remote_digits:                                        0xEC82,
  repeat:                                               0xE929,
  repeat_title:                                         0xE998,
  replay:                                               0xE92A,
  reset:                                                0xECA8,
  road:                                                 0xE9B3,
  road_e:                                               0xED2E,
  road_side_assistance:                                 0xEA20,
  route:                                                0xE9AC,
  rtv_panorama_view:                                    0xE9D4,
  satellite:                                            0xE97F,
  saved:                                                0xEA3A,
  scale_zoom_auto:                                      0xE9A2,
  scale_zoom_destination:                               0xEA36,
  scale_zoom_drive_together:                            0xEC11,
  scale_zoom_manually:                                  0xE9A3,
  scale_zoom_stopover:                                  0xEA35,
  screenreader:                                         0xED35,
  scroll_up_down:                                       0xEC0B,
  sds_pickup_list_entry_1:                              0xEB1A,
  sds_pickup_list_entry_2:                              0xEB1B,
  sds_pickup_list_entry_3:                              0xEB1C,
  sds_pickup_list_entry_4:                              0xEB1D,
  sds_processing:                                       0xEC8F,
  seat_belt:                                            0xEAF8,
  seat_comfort_codriver_left:                           0xEB6D,
  seat_comfort_codriver_left_rhw:                       0xEBF3,
  seat_comfort_left:                                    0xEB6C,
  seat_comfort_left_rhw:                                0xEBF4,
  seat_comfort_seat_back_backwards:                     0xED19,
  seat_comfort_seat_back_forwards:                      0xED18,
  seat_comfort_seat_backwards:                          0xED17,
  seat_comfort_seat_forwards:                           0xED16,
  seat_config_codriver_left:                            0xEB6E,
  seat_config_codriver_left_rhw:                        0xEBF5,
  seat_easy_entry_left:                                 0xEB6F,
  seat_easy_entry_left_rhw:                             0xEBF6,
  seat_fond_left:                                       0xECBC,
  seat_fond_right:                                      0xECBB,
  seat_heating_left:                                    0xE9B2,
  seat_heating_right:                                   0xE9B1,
  seat_left:                                            0xEA04,
  seat_massage_fond_left:                               0xECAA,
  seat_massage_fond_right:                              0xECA9,
  seat_massage_left:                                    0xEB69,
  seat_massage_right:                                   0xEB68,
  seat_massage_strength_1_left:                         0xEB67,
  seat_massage_strength_1_right:                        0xEB66,
  seat_massage_strength_2_left:                         0xEB65,
  seat_massage_strength_2_right:                        0xEB64,
  seat_massage_strength_3_left:                         0xEB63,
  seat_massage_strength_3_right:                        0xEB62,
  seat_massage_strength_4_left:                         0xEB61,
  seat_massage_strength_4_right:                        0xEB60,
  seat_massage_strength_5_left:                         0xEB5F,
  seat_massage_strength_5_right:                        0xEB5E,
  seat_massage_strength_left:                           0xEB5D,
  seat_massage_strength_right:                          0xEB5C,
  seat_right:                                           0xEA03,
  seat_ventilation_left:                                0xEA05,
  seat_ventilation_right:                               0xEA06,
  selena:                                               0xE939,
  service:                                              0xEB0A,
  settings:                                             0xE967,
  shift:                                                0xE951,
  shift_assist:                                         0xEC8E,
  show_artist:                                          0xED14,
  shuffle:                                              0xE92B,
  shuffle_play:                                         0xEC73,
  sidelights:                                           0xEB21,
  sim_card:                                             0xEB58,
  sim_card_privacy:                                     0xE97D,
  skip_15s_backward:                                    0xEBC3,
  skip_15s_forward:                                     0xEBC4,
  skip_30s_backward:                                    0xEBC8,
  skip_30s_forward:                                     0xEBC9,
  skip_backward:                                        0xE92C,
  skip_forward:                                         0xE92D,
  smart_key:                                            0xEB00,
  smartphone_integration:                               0xEC97,
  soc_destination_0:                                    0xEB3A,
  soc_destination_1:                                    0xEB35,
  soc_destination_2:                                    0xEB3E,
  soc_destination_3:                                    0xEB2E,
  soc_destination_4:                                    0xEB2D,
  soc_destination_5:                                    0xEB3B,
  soc_destination_blank:                                0xEB3F,
  solar_panel:                                          0xEC24,
  sos:                                                  0xEAF1,
  sos_asia:                                             0xED13,
  sound_to_car:                                         0xEC2A,
  source_bluetooth:                                     0xEB2B,
  source_usb:                                           0xEB31,
  space:                                                0xE952,
  speak:                                                0xEB56,
  speakerphone:                                         0xEBF2,
  speakerphone_arrow_down:                              0xED15,
  speakerphone_arrow_up:                                0xED14,
  speed_0_5x:                                           0xEC34,
  speed_1_5x:                                           0xEC35,
  speed_1x:                                             0xEC36,
  speed_2x:                                             0xEC37,
  speed_big:                                            0xECF1,
  speed_small:                                          0xECF0,
  speller_characters:                                   0xE953,
  speller_coordinates:                                  0xE9CD,
  speller_delete_numbers:                               0xE954,
  speller_delete_numbers_arabic:                        0xE955,
  speller_letters:                                      0xE956,
  speller_numbers:                                      0xE957,
  split_calls:                                          0xEA34,
  sport_response:                                       0xEC8C,
  sports:                                               0xE91C,
  spotify_browse:                                       0xECA1,
  spotify_home:                                         0xECA0,
  spotify_library:                                      0xEC9F,
  spotify_recents:                                      0xEC9E,
  star:                                                 0xE92F,
  star_outline:                                         0xE930,
  start_stop:                                           0xEC26,
  start_stop_automatic:                                 0xEC5A,
  start_stop_not_available:                             0xEC25,
  status_bluetooth:                                     0xEC32,
  status_bluetooth_not_available:                       0xEC31,
  status_wlan_not_available:                            0xEC27,
  steering_wheel:                                       0xEAE9,
  steering_wheel_component_indicator:                   0xED47,
  steering_wheel_component_wheel:                       0xED46,
  steering_wheel_plus:                                  0xECC9,
  stop:                                                 0xEC12,
  stop_scan:                                            0xE931,
  stopover_flag:                                        0xE9BD,
  stopover_flag_add:                                    0xE917,
  stopover_flag_arrow:                                  0xE982,
  stopover_flag_delete:                                 0xE9D8,
  stopover_flag_label_1:                                0xEDB0,
  stopover_flag_label_2:                                0xEDAF,
  stopover_flag_label_3:                                0xEDAE,
  stopover_flag_label_4:                                0xEDAD,
  stopover_flag_label_5:                                0xEDAC,
  stopover_flag_label_6:                                0xEDAB,
  stopover_flag_label_7:                                0xEDAA,
  stopover_flag_label_8:                                0xEDA9,
  stopover_flag_label_9:                                0xEDA8,
  stopover_flag_left:                                   0xEB83,
  stopover_flag_left_with_lable:                        0xEB84,
  stopover_flag_right:                                  0xEB82,
  stopover_flag_right_label:                            0xEB81,
  stopover_flag_traffic_eu_mrm_kor:                     0xEDA7,
  stopover_flag_traffic_nar:                            0xEDA6,
  store:                                                0xECBD,
  subject:                                              0xEBD0,
  sun:                                                  0xE9D0,
  swipe:                                                0xEA3E,
  switch_cell_phone:                                    0xEBF7,
  switch_video_left:                                    0xED34,
  switch_video_right:                                   0xED33,
  sxm_fast_backward:                                    0xEBCA,
  sxm_fast_forward:                                     0xEBCB,
  sxm_for_you:                                          0xEBA7,
  sxm_notification:                                     0xEBA8,
  sxm_notification_outline:                             0xEBCC,
  sxm_on_demand:                                        0xEBA9,
  sxm_possession:                                       0xEB9A,
  sxm_sports_live:                                      0xEC01,
  sync_right_a:                                         0xEC28,
  tachometer:                                           0xEC8B,
  tag:                                                  0xE932,
  tasks:                                                0xEC29,
  tc_off:                                               0xED71,
  tempomat:                                             0xEAF7,
  themes:                                               0xEA02,
  thumb_down:                                           0xEBF9,
  thumb_down_outline:                                   0xED3E,
  thumb_up:                                             0xED3C,
  thumb_up_outline:                                     0xED3D,
  timer:                                                0xEB29,
  timer_component_clock:                                0xED45,
  timer_component_minutes:                              0xED44,
  top_speed:                                            0xED06,
  tour:                                                 0xE91D,
  tour_flag:                                            0xEB37,
  traffic_antenna:                                      0xEB9C,
  traffic_assist_j1:                                    0xE9A0,
  traffic_charging_station_filter:                      0xEDBE,
  traffic_info_outline_ece:                             0xEBD7,
  traffic_info_outline_nar:                             0xEBD8,
  traffic_j1:                                           0xEBF8,
  traffic_sign_recognition_e3_pa:                       0xECC7,
  traffic_sign_recognition_e3_pa_off:                   0xECC8,
  traffic_sign_recognition_g3:                          0xECC5,
  traffic_sign_recognition_g3_off:                      0xECC6,
  traffic_sign_recognition_j1:                          0xEB0F,
  traffic_sign_recognition_j1_off:                      0xED64,
  traffic_sign_recognition_sport:                       0xED62,
  traffic_sign_recognition_sport_off:                   0xED63,
  trailer_view:                                         0xE9D2,
  transfer_case_lock:                                   0xEC33,
  triggered_lane_change:                                0xEC8D,
  trip:                                                 0xEBD6,
  trip_continuous:                                      0xE9DD,
  trip_personal:                                        0xE9DE,
  trip_since:                                           0xE9E0,
  trip_since_charging:                                  0xEC5B,
  trip_since_refuelling:                                0xE9DF,
  tune_keypads:                                         0xE93E,
  tuning_angle:                                         0xEC8A,
  tunnel:                                               0xE9B8,
  tv:                                                   0xED3B,
  update:                                               0xE9E8,
  update_center:                                        0xE9CC,
  update_save:                                          0xEA19,
  update_security:                                      0xEB40,
  usb:                                                  0xE933,
  vent_fond_left:                                       0xECA5,
  vent_fond_right:                                      0xECA4,
  vent_off:                                             0xEC5C,
  video_headset:                                        0xED13,
  video_image_fullscreen:                               0xEAE3,
  video_image_fullscreen_close:                         0xEAE4,
  video_resolution:                                     0xED3A,
  video_streaming:                                      0xED30,
  video_switch_audio:                                   0xED39,
  voice_mail:                                           0xE944,
  voltage:                                              0xEAEC,
  voltage_high:                                         0xEB0B,
  voltage_none:                                         0xEB0C,
  warning_filled:                                       0xE99A,
  warning_tire_pressure:                                0xEB05,
  wc:                                                   0xED75,
  weather:                                              0xE93A,
  welcome_wizzard:                                      0xEC72,
  wet_road_e3_pa:                                       0xECC4,
  wet_road_g3:                                          0xECC3,
  wet_road_sport:                                       0xED32,
  wheel_angle:                                          0xEC7D,
  wheel_angle_axle:                                     0xEC7F,
  wheel_angle_wheel:                                    0xEC7E,
  wind:                                                 0xE9CF,
  wind_deflector:                                       0xED74,
  window_defrost:                                       0xED70,
  wizard_active:                                        0xEC07,
  wizard_checked:                                       0xEC08,
  wizard_empty:                                         0xEC09,
  wizard_missed:                                        0xEC0A,
  wlan:                                                 0xE95B
}

const letters: { [index: string]: number } = {
  Number_0:  0x30,
  Number_1:  0x31,
  Number_2:  0x32,
  Number_3:  0x33,
  Number_4:  0x34,
  Number_5:  0x35,
  Number_6:  0x36,
  Number_7:  0x37,
  Number_8:  0x38,
  Number_9:  0x39,
  Letter_A:  0x41,
  Letter_B:  0x42,
  Letter_C:  0x43,
  Letter_D:  0x44,
  Letter_E:  0x45,
  Letter_F:  0x46,
  Letter_G:  0x47,
  Letter_H:  0x48,
  Letter_I:  0x49,
  Letter_J:  0x4a,
  Letter_K:  0x4b,
  Letter_L:  0x4c,
  Letter_M:  0x4d,
  Letter_N:  0x4e,
  Letter_O:  0x4f,
  Letter_P:  0x50,
  Letter_Q:  0x51,
  Letter_R:  0x52,
  Letter_S:  0x53,
  Letter_T:  0x54,
  Letter_U:  0x55,
  Letter_V:  0x56,
  Letter_W:  0x57,
  Letter_X:  0x58,
  Letter_Y:  0x59,
  Letter_Z:  0x5a,
  Letter_A1: 0x61,
  Letter_B1: 0x62,
  Letter_C1: 0x63,
  Letter_D1: 0x64,
  Letter_E1: 0x65,
  Letter_F1: 0x66,
  Letter_G1: 0x67,
  Letter_H1: 0x68,
  Letter_I1: 0x69,
  Letter_J1: 0x6a,
  Letter_K1: 0x6b,
  Letter_L1: 0x6c,
  Letter_M1: 0x6d,
  Letter_N1: 0x6e,
  Letter_O1: 0x6f,
  Letter_P1: 0x70,
  Letter_Q1: 0x71,
  Letter_R1: 0x72,
  Letter_S1: 0x73,
  Letter_T1: 0x74,
  Letter_U1: 0x75,
  Letter_V1: 0x76,
  Letter_W1: 0x77,
  Letter_X1: 0x78,
  Letter_Y1: 0x79,
  Letter_Z1: 0x7a
}

const imgs: { [index: string]: string } = {
  ETC_app_tile,
  ac_fan_only,
  air_distribution_diffuse,
  air_distribution_focus,
  ambient_light_app_tile_70,
  apple_add_to_library,
  apple_carplay_app_tile,
  apple_for_you,
  apple_radio,
  arrow_n_button,
  arrow_n_button_Red,
  car_front_911_app_tile,
  car_front_E3_PA_app_tile,
  car_front_E3_app_tile,
  car_front_G2PA_app_tile,
  car_front_G3_app_tile,
  car_front_J1_app_tile,
  cell_phone_app_tile,
  charging_active_app_tile,
  city_events_app_tile,
  climate_app_app_tile_70,
  climate_app_tile,
  codriver_cockpit_app_tile,
  codriver_cockpit_app_tile_70,
  concierge_app_tile,
  data_activity_2g_active,
  data_activity_2g_inactive,
  data_activity_3g_active,
  data_activity_3g_inactive,
  data_activity_4g_active,
  data_activity_4g_inactive,
  data_activity_5g_active,
  data_activity_5g_inactive,
  data_activity_wifi_active,
  data_activity_wifi_inactive,
  destination_flag_offroad,
  flights_app_tile,
  google_search_active,
  google_search_default,
  google_search_disabled,
  google_search_pressed,
  guidance_arrow,
  home_app_tile,
  home_app_tile_active,
  home_app_tile_default,
  home_app_tile_disabled,
  home_app_tile_pressed,
  homelink_app_tile,
  icon_contacts,
  indicator_warning,
  info_outline_70,
  info_outline_app_tile,
  lgi_aquaplaning,
  lgi_falschfahrer,
  lgi_panne,
  lgi_sicht,
  lgi_traktion,
  lgi_unfall,
  lgw_notbremsung_voraus,
  lgw_notfallfahrzeug_hinten,
  lgw_notfallfahrzeug_links,
  lgw_notfallfahrzeug_rechts,
  lgw_notfallfahrzeug_voraus,
  lgw_notfallfahrzeug_vorne,
  lgw_stau_voraus,
  logo_7eleven,
  logo_OpisNavx,
  logo_SID,
  logo_Tagesschau,
  logo_Xinhua,
  logo_afp,
  logo_android_auto_app_tile,
  logo_ansa,
  logo_apple_music_color,
  logo_apple_podcasts_color,
  logo_db_bahn,
  logo_deutsche_welle,
  logo_eventseeker,
  logo_flight_stats,
  logo_inrix,
  logo_merian,
  logo_meteoGroup,
  logo_npr,
  logo_opisnavx,
  logo_pag,
  logo_parkopedia,
  logo_plugshare,
  logo_rss,
  logo_screenhits,
  logo_spotify_color,
  logo_twitter,
  logo_wefind,
  manual_app_tile,
  mobile_device_key_app_tile,
  network_0_arrow_forward,
  network_1,
  network_1_arrow_forward,
  network_2,
  network_2_arrow_forward,
  network_3,
  network_3_arrow_forward,
  network_4,
  network_4_arrow_forward,
  network_5_arrow_forward,
  news_app_tile,
  north_arrow_app_tile,
  north_arrow_app_tile_70,
  note_app_tile,
  note_app_tile_70,
  notification_app_tile,
  organizer_app_tile,
  parking_app_tile,
  pdx_app_tile,
  phone_pause_call,
  phone_reactivate_call,
  phone_receiver_app_tile,
  predictive_maintenance_992_app_tile,
  predictive_maintenance_E3PA_app_tile,
  predictive_maintenance_E3_app_tile,
  predictive_maintenance_G2PA_app_tile,
  predictive_maintenance_G3_app_tile,
  predictive_maintenance_J1_app_tile,
  prioritysign_arrow_na,
  prioritysign_roundabout,
  processing,
  processing_123,
  processing_123_red,
  processing_70,
  processing_red,
  qrcode_app_tile,
  rating_yelp_0,
  rating_yelp_1,
  rating_yelp_1_5,
  rating_yelp_2,
  rating_yelp_2_5,
  rating_yelp_3,
  rating_yelp_3_5,
  rating_yelp_4,
  rating_yelp_4_5,
  rating_yelp_5,
  readout_app_tile_70,
  readout_low_app_tile_70,
  readout_mute_app_tile_70,
  road_side_assistance_app_tile,
  seat_comfort_left_app_tile_70,
  settings_app_tile,
  settings_app_tile_70,
  sirius_xm,
  sirius_xm_no_signal,
  soc_destination_0_yellow,
  soc_destination_1_white_C8_0,
  soc_destination_1_white_C8_2,
  soc_destination_1_white_C8_4,
  soc_destination_1_white_C8_6,
  soc_destination_1_yellow,
  soc_destination_2_white_C8_0,
  soc_destination_2_white_C8_2,
  soc_destination_2_white_C8_4,
  soc_destination_2_white_C8_6,
  soc_destination_3_white_C8_0,
  soc_destination_3_white_C8_2,
  soc_destination_3_white_C8_4,
  soc_destination_3_white_C8_6,
  soc_destination_4_white_C8_0,
  soc_destination_4_white_C8_2,
  soc_destination_4_white_C8_4,
  soc_destination_4_white_C8_6,
  soc_destination_5_white_C8_0,
  soc_destination_5_white_C8_2,
  soc_destination_5_white_C8_4,
  soc_destination_5_white_C8_6,
  soc_destination_blank_red,
  soc_destination_blank_white_C8_0,
  soc_destination_blank_white_C8_2,
  soc_destination_blank_white_C8_4,
  soc_destination_blank_white_C8_6,
  soc_destination_flag_0_yellow_C8_2,
  soc_destination_flag_0_yellow_C8_4,
  soc_destination_flag_1_white_C8_2,
  soc_destination_flag_1_white_C8_4,
  soc_destination_flag_1_yellow_C8_2,
  soc_destination_flag_1_yellow_C8_4,
  soc_destination_flag_2_white_C8_2,
  soc_destination_flag_2_white_C8_4,
  soc_destination_flag_3_white_C8_2,
  soc_destination_flag_3_white_C8_4,
  soc_destination_flag_4_white_C8_2,
  soc_destination_flag_4_white_C8_4,
  soc_destination_flag_5_white_C8_2,
  soc_destination_flag_5_white_C8_4,
  soc_destination_flag_blank_red_C8_2,
  soc_destination_flag_blank_red_C8_4,
  soc_destination_flag_blank_white_C8_2,
  soc_destination_flag_blank_white_C8_4,
  soc_stopover_flag_0_yellow_C8_2,
  soc_stopover_flag_0_yellow_C8_4,
  soc_stopover_flag_1_white_C8_2,
  soc_stopover_flag_1_white_C8_4,
  soc_stopover_flag_1_yellow_C8_2,
  soc_stopover_flag_1_yellow_C8_4,
  soc_stopover_flag_2_white_C8_2,
  soc_stopover_flag_2_white_C8_4,
  soc_stopover_flag_3_white_C8_2,
  soc_stopover_flag_3_white_C8_4,
  soc_stopover_flag_4_white_C8_2,
  soc_stopover_flag_4_white_C8_4,
  soc_stopover_flag_5_white_C8_2,
  soc_stopover_flag_5_white_C8_4,
  soc_stopover_flag_blank_red_C8_2,
  soc_stopover_flag_blank_red_C8_4,
  soc_stopover_flag_blank_white_C8_2,
  soc_stopover_flag_blank_white_C8_4,
  speed_limit_60,
  speed_limit_rain,
  speed_limit_warning_vae,
  stopover_flag_offroad,
  streaming_app_tile,
  timer_app_tile,
  traffic_info_green_ece,
  traffic_info_nar,
  traffic_info_red,
  traffic_info_selena_ece,
  traffic_info_selena_nar,
  traffic_sign_additional_icy_NZL,
  traffic_sign_additional_night_nar,
  traffic_sign_additional_school_nar,
  traffic_sign_additional_wet_NZL,
  traffic_sign_additional_work_zone_nar,
  traffic_sign_animal_eu,
  traffic_sign_city_back_eu,
  traffic_sign_city_eu,
  traffic_sign_danger_eu,
  traffic_sign_exit_nar,
  traffic_sign_exit_only_nar,
  traffic_sign_give_way_eu,
  traffic_sign_kmh_EU_CN,
  traffic_sign_mph_EU,
  traffic_sign_mph_USA,
  traffic_sign_no_entry_eu,
  traffic_sign_no_passing_end_nar,
  traffic_sign_no_passing_nar,
  traffic_sign_pedestrians_eu,
  traffic_sign_speed_limit_advisory_kmh_nar_can,
  traffic_sign_speed_limit_advisory_mph_nar,
  traffic_sign_speed_limit_advisory_nar,
  traffic_sign_speed_limit_advisory_nar_can,
  traffic_sign_speed_limit_cn,
  traffic_sign_speed_limit_end_mph_nar,
  traffic_sign_speed_limit_end_nar,
  traffic_sign_speed_limit_eu,
  traffic_sign_speed_limit_eu_kmh,
  traffic_sign_speed_limit_eu_mph,
  traffic_sign_speed_limit_generic_nar_can,
  traffic_sign_speed_limit_kmh_nar_can,
  traffic_sign_speed_limit_mph_nar,
  traffic_sign_speed_limit_nar,
  traffic_sign_speed_limit_nar_can,
  traffic_sign_stop_eu,
  traffic_sign_traffic_lights_eu,
  traffic_sign_traffic_lights_free_right_turn_eu,
  traffic_sign_traffic_lights_no_turn_nar,
  update_error,
  updates_app_tile,
  videostreaming_app_tile,
  view,
  warning_red,
  warning_sign,
  warning_white,
  warning_yellow,
  warnmarker_red,
  warnmarker_white,
  warnmarker_yellow,
  weather_app_tile,
  weather_app_tile_70,
  wheel
}

const model_codes = Object.freeze([
  "992", "992pa", "g3", // coupes
  "g2pa", "j1",         // sedans
  "e3", "e3pa"          // suvs
]);

// @NOTE(): Here we repeat code from initial.ts because otherwise we would
// create circular dependecy. It is causes by initial.ts using LauncherTiles
// which depend on icons.

const base_model = (function () {
    const found = config.model_code.match(/^[a-zA-Z0-9]+/);
    return (
        found !== null
        ? found[0]
        : ""
    );
}());
const model_index = model_codes.findIndex((code) => code === base_model);

const model_specific_icons: { [index: string]: any[] } = Object.freeze({
  air_center: [
    symbols.air_center_devided, symbols.air_center_devided, symbols.air_center_devided,
    symbols.air_center_devided, symbols.air_center_j1,
    symbols.air_center_devided, symbols.air_center_devided
  ],
  ass_basic: [
    symbols.ass_basic, symbols.ass_basic, symbols.ass_basic_g3,
    symbols.ass_basic, symbols.ass_basic, 
    symbols.ass_basic_e3_pa, symbols.ass_basic_e3_pa
  ],
  ass_disabled: [
    symbols.ass_disabled_j1, symbols.ass_disabled_j1, symbols.ass_disabled_g3,
    symbols.ass_disabled_j1, symbols.ass_disabled_j1,
    symbols.ass_disabled_e3_pa, symbols.ass_disabled_e3_pa
  ],
  ass_front: [
    symbols.ass_j1, symbols.ass_j1, symbols.ass_g3,
    symbols.ass_j1, symbols.ass_j1,
    symbols.ass_e3_pa, symbols.ass_e3_pa
  ],
  ass: [
    symbols.ass_sport, symbols.ass_sport, symbols.ass_sport,
    symbols.ass_limousine, symbols.ass_j1,
    symbols.ass_suv, symbols.ass_suv
  ],
  assist_settings: [
    symbols.assist_settings_g3, symbols.assist_settings_g3, symbols.assist_settings_g3,
    symbols.assist_settings_g3, symbols.assist_settings_g3,
    symbols.assist_settings_e3_pa, symbols.assist_settings_e3_pa
  ],
  car_front_app_tile: [
    car_front_911_app_tile, car_front_911_app_tile, car_front_G3_app_tile,
    car_front_G2PA_app_tile, car_front_J1_app_tile,
    car_front_E3_app_tile, car_front_E3_PA_app_tile
  ],
  car: [
    symbols.car_sport, symbols.car_sport, symbols.car_sport,
    symbols.car_limousine, symbols.car_limousine,
    symbols.car_suv, symbols.car_suv
  ],
  car_front: [
    symbols.car_front_911, symbols.car_front_911, symbols.car_front_g3,
    symbols.car_front_panamera, symbols.car_front_j1,
    symbols.car_front_cayenne, symbols.car_front_e3_pa
  ],
  car_niveau: [
    symbols.car_sport_niveau, symbols.car_sport_niveau, symbols.car_sport_niveau,
    symbols.car_limousine_niveau, symbols.car_limousine_niveau,
    symbols.car_suv_niveau, symbols.car_suv_niveau
  ],
  ipa_scanner: [
    symbols.ipa_scanner_sport, symbols.ipa_scanner_sport, symbols.ipa_scanner_g3,
    symbols.ipa_scanner_j1, symbols.ipa_scanner_j1,
    symbols.ipa_scanner_suv, symbols.ipa_scanner_e3_pa
  ],
  predictive_maintenance_app_tile: [
    predictive_maintenance_992_app_tile, predictive_maintenance_992_app_tile, predictive_maintenance_G3_app_tile,
    predictive_maintenance_G2PA_app_tile, predictive_maintenance_J1_app_tile,
    predictive_maintenance_E3_app_tile, predictive_maintenance_E3PA_app_tile
  ],
  traffic_sign_recognition: [
    symbols.traffic_sign_recognition_sport, symbols.traffic_sign_recognition_sport, symbols.traffic_sign_recognition_g3,
    symbols.traffic_sign_recognition_j1, symbols.traffic_sign_recognition_j1,
    symbols.traffic_sign_recognition_e3_pa, symbols.traffic_sign_recognition_e3_pa
  ],
  traffic_sign_recognition_off: [
    symbols.traffic_sign_recognition_sport_off, symbols.traffic_sign_recognition_sport_off, symbols.traffic_sign_recognition_g3_off,
    symbols.traffic_sign_recognition_j1_off, symbols.traffic_sign_recognition_j1_off,
    symbols.traffic_sign_recognition_e3_pa_off, symbols.traffic_sign_recognition_e3_pa_off
  ],
  awv_prewarning: [ 
    symbols.awv_prewarning_sport, symbols.awv_prewarning_sport, symbols.awv_prewarning_sport,
    symbols.awv_prewarning_limousine, symbols.awv_prewarning_limousine,
    symbols.awv_prewarning_suv, symbols.awv_prewarning_suv
  ],
  lane_departure_warning: [
    symbols.lane_departure_warning_system_sport, symbols.lane_departure_warning_system_sport, symbols.lane_departure_warning_system_g3,
    symbols.lane_departure_warning_system_limousine, symbols.lane_departure_warning_system_j1,
    symbols.lane_departure_warning_system_suv, symbols.lane_departure_warning_system_e3_pa
  ],
  lane_departure_warning_off: [
    symbols.lane_departure_warning_system_g3_off, symbols.lane_departure_warning_system_g3_off, symbols.lane_departure_warning_system_g3_off,
    symbols.lane_departure_warning_system_g3_off, symbols.lane_departure_warning_system_g3_off,
    symbols.lane_departure_warning_system_e3_pa_off, symbols.lane_departure_warning_system_e3_pa_off,
  ],
  maneuver_assist: [
    symbols.maneuver_assist_sport, symbols.maneuver_assist_sport, symbols.maneuver_assist_g3,
    symbols.maneuver_assist_limousine, symbols.maneuver_assist_j1,
    symbols.maneuver_assist_suv, symbols.maneuver_assist_e3_pa
  ],
  maneuver_assist_off: [
    symbols.maneuver_assist_sport_off, symbols.maneuver_assist_sport_off, symbols.maneuver_assist_g3_off,
    symbols.maneuver_assist_limousine_off, symbols.maneuver_assist_j1_off,
    symbols.maneuver_assist_suv_off, symbols.maneuver_assist_e3_pa_off,
  ],
  predictive_speed: [
    symbols.predictive_speed_sport, symbols.predictive_speed_sport, symbols.predictive_speed_g3,
    symbols.predictive_speed_j1, symbols.predictive_speed_j1,
    symbols.predictive_speed_e3_pa, symbols.predictive_speed_e3_pa
  ]
})

const icons: { [index: string]: IconType } = Object.create(null)

Object.keys(symbols).forEach(function (key: string) {
  icons[key] = make_icon(symbols[key])
})
Object.keys(letters).forEach(function (key: string) {
  icons[key] = make_icon(letters[key])
})
Object.keys(imgs).forEach(function (key: string) {
  icons[key] = make_icon(imgs[key])
})
Object.keys(model_specific_icons).forEach(function (key: string) {
  icons[key] = make_icon(model_specific_icons[key][model_index])
})

Object.freeze(icons)

export const make = Object.freeze(make_icon)

export default icons
