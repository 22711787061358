export enum EVisibleListUSB {
  OVERVIEW = "Overview",
  ARTISTS = "Artists",
  ALBUMS = "Albums",
  GENRES = "Genres",
  TRACK = "Track",
  FILES_AND_FOLDERS = "Files and Folders",
  PLAYLISTS = "Playlists",
  VIDEOS = "Videos"
}

export enum EVisibleListSiriusXM {
  OVERVIEW_SIRIUS_XM = "Overview_Sirius_XM",
  FOR_YOU = "For You",
  ALL_CHANNELS = "All Channels",
  MUSIC = "Music",
  SPORTS = "Sports"
}

export const overViewListSiriusXM = [
  {
    category: "For You",
    linkto: EVisibleListSiriusXM.FOR_YOU,
    label: "For You"
  },
  {
    category: "All Channels",
    linkto: EVisibleListSiriusXM.ALL_CHANNELS,
    label: "All Channels"
  },
  {
    category: "Music",
    linkto: EVisibleListSiriusXM.MUSIC,
    label: "Music"
  },
  {
    category: "Sports",
    linkto: EVisibleListSiriusXM.SPORTS,
    label: "Sports"
  }
]
