enum ECarSubTabItemNames {
  DRIVE = "drive",
  ASSIST = "assist",
  TRIP = "trip",
  COMFORT = "comfort"
}

enum ECarDriveMainScreenItemNames {
  DEFAULT = "default",
  DRIVE_MODE = "driveMode",
  DAMPER = "damper",
  LEVEL = "level",
  RECUPERATION = "recuperation",
  E_SOUND = "esound"
}

export { ECarSubTabItemNames, ECarDriveMainScreenItemNames }
