import {
  ScreenState,
  SET_CAR_SCREEN_VALUE
} from "./types"

type Action = {
  type: string
  [index: string]: any
}

export default function reducer(
  state: ScreenState = {
    driveTabListItem: undefined,

    assistTabListItem: undefined,
    assistTabActiveList: undefined,
    
    comfortTabListItem: undefined,
    comfortTabActiveList: undefined
  },
  action: Action
): ScreenState {
  switch (action.type) {

  case SET_CAR_SCREEN_VALUE:
    {
      return Object.assign(
        {},
        state,
        { [action.key]: action.value }
      )
    }

  default:
    return state
  }
}
