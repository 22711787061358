import {
  NavigationModeActionType,
  NavigationModeState,
  SET_NAVIGATION_MODE,
  SAVE_KEY_INPUT,
  DELETE_INPUT,
  DestInputInputState,
  DestInputActionType
} from "./types"

const navigationModeInitialState: NavigationModeState = {
  mode: "online"
}

const destInputInitialState: DestInputInputState = {
  input: ""
}

export function navigationModeReducer(
  state = navigationModeInitialState,
  action: NavigationModeActionType
): NavigationModeState {
  switch (action.type) {
    case SET_NAVIGATION_MODE:
      return {
        mode: action.mode
      }
    default:
      return state
  }
}

export function destInputReducer(state = destInputInitialState, action: DestInputActionType): DestInputInputState {
  switch (action.type) {
    case SAVE_KEY_INPUT:
      return {
        ...state,
        input: state.input.concat(action.payload)
      }
    case DELETE_INPUT:
      return {
        ...state,
        input: ""
      }
    default:
      return state
  }
}
