import objtools from "@pag/utils/object-tools"
import {
  CompositeIcon,
  PersonalBox,
  Box,
  Section,
  SectionKind
} from "@pag/center/views/carScreen/trip/types"
import icon from "@pag/center/components/icon/icons"
import { UnitKind } from "@pag/center/components/unit/types"

const section_kinds: SectionKind[] = objtools.populate(
  "label",                                                      "unit"
)(
  [ "Car_TripInformationList_SectionHeader_PersonalTrip",       UnitKind.None ],
  [ "Car_TripInformationList_SectionHeader_TripSinceDate",      UnitKind.Time ],
  [ "Car_TripInformationList_SectionHeader_TripSinceCharging",  UnitKind.None ],
  [ "Car_TripInformationList_SectionHeader_TotalTrip",          UnitKind.None ],
  [ "Car_TripInformationList_SectionHeader_ETripSinceDate",     UnitKind.Time ],
  [ "Car_TripInformationList_SectionHeader_ETripSinceCharging", UnitKind.None ]
) as SectionKind[]

const layout_value_label = Object.freeze({})
const layout_icon_value_label = Object.freeze({})
const layout_value_icon_label = Object.freeze({})
const layout_value_icon_label_label = Object.freeze({})

// ICONS //////////////////////////////////////////////////////////////////////

const no_icon: CompositeIcon = Object.freeze([]);
const icon_calendar: CompositeIcon = Object.freeze([
  icon.calendar_date,
  icon.calendar_date_component_brackets
])
const icon_linear_acc: CompositeIcon = Object.freeze([
  icon.linear_acceleration,
  icon.linear_acceleration_component_dots,
  icon.linear_acceleration_component_arrow_up_inactive,
  icon.linear_acceleration_component_arrow_down_inactive
])
const icon_clock_time: CompositeIcon = Object.freeze([
  icon.clock_time_component_circle,
  icon.clock_time_component_hour,
  icon.clock_time_component_minute
])
const icon_range: CompositeIcon = Object.freeze([
  icon.charging_station
])
const icon_temperature: CompositeIcon = Object.freeze([
  icon.climate
])

// BOXES //////////////////////////////////////////////////////////////////////

const populate_box: (...element: any[]) => Readonly<PersonalBox[] | Box[]> = objtools.populate(
  "label",
  "layout",
  "icon",
  "unit"
)

const personal_boxes: PersonalBox[] = populate_box(
  [
    "Car_TripInformationList_Personal_Box_ArrivalDistanceDestination",
    layout_value_label,
    no_icon,
    [ UnitKind.Duration, UnitKind.Distance ]
  ],
  [
    "Car_TripInformationList_Personal_Box_Date",
    layout_value_icon_label,
    icon_calendar,
    UnitKind.Date
  ],
  [
    "Car_TripInformationList_Personal_Box_MaxLongitudinalAcceleration",
    layout_value_icon_label_label,
    icon_linear_acc,
    UnitKind.None
  ],
  [
    "Car_TripInformationList_Personal_Box_Speed",
    layout_value_label,
    no_icon,
    UnitKind.Speed
  ],
  [
    "Car_TripInformationList_Personal_Box_TimeContinuous",
    layout_value_icon_label,
    icon_clock_time,
    UnitKind.Duration
  ],
  [
    "Car_TripInformationList_Personal_Box_Time",
    layout_value_icon_label,
    icon_clock_time,
    UnitKind.Time
  ],
  [
    "Car_TripInformationList_Personal_Box_Range",
    layout_value_icon_label,
    icon_range,
    UnitKind.Distance
  ],
  [
    "Car_TripInformationList_Personal_Box_AmbientTemperature",
    layout_value_icon_label,
    icon_temperature,
    UnitKind.Temperature
  ]
) as PersonalBox[] 

const boxes: Box[] = populate_box(
  [
    "Car_TripInformationList_Box_DrivingTime",
    layout_icon_value_label,
    Object.freeze([ icon.duration ]),
    UnitKind.Duration
  ],
  [
    "Car_TripInformationList_Box_Distance",
    layout_icon_value_label,
    Object.freeze([ icon.road ]),
    UnitKind.Distance
  ],
  [
    "Car_TripInformationList_Box_AverageConsumtion",
    layout_icon_value_label,
    Object.freeze([ icon.charging_station ]),
    UnitKind.Consumption
  ],
  [
    "Car_TripInformationList_Box_AverageSpeed",
    layout_icon_value_label,
    Object.freeze([ icon.tachometer ]),
    UnitKind.Speed
  ],
  [
    "Car_TripInformationList_Box_ZeroEmissionDistance",
    layout_icon_value_label,
    Object.freeze([ icon.road ]),
    UnitKind.Distance
  ],
  [
    "Car_TripInformationList_Box_ZeroEmissionDrivingTime",
    layout_icon_value_label,
    Object.freeze([ icon.duration ]),
    UnitKind.Duration
  ]
) as Box[] 

const [
  driving_time_box,
  distance_box,
  average_consumption_box,
  averege_speed_box,
  zero_emissions_distance_box,
  zero_emission_driving_time_box
] = boxes

// SECTIONS ////////////////////////////////////////////////////////////////////

const [
  personal_trip,
  since_date_trip,
  since_charge_trip,
  total_trip,
  e_since_date_trip,
  e_since_charge_trip
] = section_kinds

const sections: Section[] = objtools.populate(
  "kind", "boxes"
)(
  [
    since_date_trip,
    [
      driving_time_box,
      distance_box,
      average_consumption_box,
      averege_speed_box
    ]
  ],
  [
    since_charge_trip,
    [
      driving_time_box,
      distance_box,
      average_consumption_box,
      averege_speed_box
    ]
  ],
  [
    total_trip,
    [
      driving_time_box,
      distance_box,
      average_consumption_box,
      averege_speed_box
    ]
  ],
  [
    e_since_date_trip,
    [
      driving_time_box,
      zero_emission_driving_time_box,
      zero_emissions_distance_box,
      average_consumption_box
    ]
  ],
  [
    e_since_charge_trip,
    [
      driving_time_box,
      zero_emission_driving_time_box,
      zero_emissions_distance_box,
      average_consumption_box
    ]
  ]
) as Section[]

const sections_j1: Section[] = objtools.populate(
  "kind", "boxes"
)(
  [
    e_since_date_trip,
    [
      driving_time_box,
      zero_emission_driving_time_box,
      zero_emissions_distance_box,
      average_consumption_box
    ]
  ],
  [
    e_since_charge_trip,
    [
      driving_time_box,
      zero_emission_driving_time_box,
      zero_emissions_distance_box,
      average_consumption_box
    ]
  ],
  [
    total_trip,
    [
      driving_time_box,
      distance_box,
      average_consumption_box,
      averege_speed_box
    ]
  ]
) as Section[]


export default Object.freeze({
  section_kinds,
  personal_boxes,
  boxes,
  sections,
  sections_j1,
  layout_value_label,
  layout_icon_value_label,
  layout_value_icon_label,
  layout_value_icon_label_label
})
