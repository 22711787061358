import { LauncherTile } from "@pag/center/views/launcherConfig/types"
import config from "../../../config.json"
import s_definitions from "@pag/center/views/settingsScreen/definitions"
import { tiles } from "@pag/center/components/launcher/launcherTiles"
import definitions from "@pag/center/config/definitions"

const initial = Object.create(null)

const lang: string = localStorage.getItem("locale") || "de_DE"

// @NOTE(kirill): We use enrichers to unserialise configs. For instance runtime
// uses m/s to store speed values, but config allows us to use km/s, which is
// more convenient. For those values we use m/s to km/h converting function.

if (config.settings !== undefined) {
    initial.settings = Object.create(null)
    const source = config.settings as { [index: string]: any }

    Object.keys(source).forEach(function (key) {
        if (source[key] === null) {
            return;
        }

        switch (typeof definitions[key]) {

        case "object":
            initial.settings[key] = (
                Array.isArray(definitions[key])
                ? (
                    Array.isArray(source[key])
                    ? Object.freeze(source[key].map((label: string, index: number) => (
                        definitions[key].find(
                            (def: { [index: string]: string }) => (
                                def !== null &&
                                def.label === label
                            )
                        ) ||
                        (
                            typeof definitions[key][index] === "function"
                            ? definitions[key][index](source[key][index])
                            : source[key][index]
                        )
                    )))
                    : definitions[key].find(
                        (def: { [index: string]: string }) => (
                            def.label === source[key]
                        )
                    )
                )
                : source[key]
            )
            break

        case "function":
            initial.settings[key] = definitions[key](source[key])
            break

        default:
            initial.settings[key] = source[key]
        }
    })

    // @NOTE(kirill): These properties are generated at runtime. Maybe apart
    // from timezone, but it’s not translated so we don’t have a reference 
    // string for it.

    initial.settings.Settings_System_LanguageAndKeyboard_Language = s_definitions.langs.find((lang_def) => lang_def.value === lang)
    initial.settings.Settings_System_LanguageAndKeyboard_Keyboard = Object.freeze([ s_definitions.keyboard_langs.find((lang_def) => lang_def.value === lang) ])
    initial.settings.Settings_System_DateAndTime_SetTimeManually = s_definitions.today
    initial.settings.Settings_System_DateAndTime_SetDateManually = s_definitions.today
    initial.settings.Settings_System_DateAndTime_SetTimeZoneManually = s_definitions.timezones[0]

    Object.freeze(initial.settings)
}

if (config.launcher !== undefined) {
    if (Array.isArray(config.launcher.tiles)) {
        initial.launcher = Object.create(null)
        initial.launcher.tiles = config.launcher.tiles.map((label: string) => tiles.find((tile: LauncherTile) => tile.text === label))

        Object.freeze(initial.launcher.tiles)
        Object.freeze(initial.launcher)
    }
}

if (config.mainTabBarItems !== undefined) {
    if (Array.isArray(config.mainTabBarItems)) {
        initial.mainTabBarItems = config.mainTabBarItems.map((label: string) => tiles.find((tile: LauncherTile) => tile.text === label))

        Object.freeze(initial.mainTabBarItems)
    }
}

if (config.car !== undefined) {
    initial.car = Object.create(null)
    const source = config.car as { [index: string]: any }

    Object.keys(source).forEach(function (key) {
        if (key.slice(0, 2) === "__") {
            return
        }
        if (
            typeof source[key] === "string" &&
            definitions[key] !== undefined
        ) {
            initial.car[key] = definitions[key].find((item: any) => item.label === source[key])
        } else {
            initial.car[key] = source[key]
        }
    })
}

initial.screen = parseFloat(config.screen);

initial.model = (
    config.model_code !== undefined
    ? config.model_code
    : "j1"
).toLowerCase()

initial.base_model = (function () {
    const found = initial.model.match(/^[a-zA-Z0-9]+/);
    return (
        found !== null
        ? found[0]
        : ""
    );
}());

export default Object.freeze(initial)
