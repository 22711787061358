import React from "react"
import ClassNames from "classnames"

import "./mapTooltipExpanded.scss"

interface IMapTooltipExpanded {
  className?: string
  content?: any
  icon?: any
}

const MapTooltipExpanded: React.FC<IMapTooltipExpanded> = (props: IMapTooltipExpanded) => (
  <div className={ClassNames("map-tooltip-expanded-wrapper", props.className)}>
    <div className="tooltip-expanded-content">{props.content}</div>
    {props.icon}
  </div>
)

export default MapTooltipExpanded
