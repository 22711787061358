import React from "react"
import cx from "classnames"

import { IconType } from "./types"
import icons from "./icons"

import "./icon.scss"

interface IIcon {
  iconType: IconType | string
  iconClass?: string
  isPressed?: boolean
  style?: any
  disabled?: boolean
  onPress?: any
  huge?: boolean
}

function get_key(icon: IconType) {
  return "icon-" + Object.keys(icons)[Object.values(icons).findIndex((symbol) => symbol === icon)]
}

const Icon: React.FunctionComponent<IIcon> = ({
  iconType,
  iconClass,
  isPressed,
  style,
  disabled,
  huge,
  onPress
}) => (
  <div
    className={cx(
      "icon",
      (iconType !== undefined && typeof iconType !== "string") && get_key(iconType),
      iconClass,
      {
        "icon--huge": huge,
        "state-pressed": isPressed,
        "state-disabled": disabled,
      }
    )}
    onClick={onPress !== undefined ? onPress : () => {}}
    style={
      iconType === undefined
      ? style
      : Object.assign(
        {},
        style,
        (
          typeof iconType !== "string" && (
            !Number.isNaN(Number(iconType))
            ? { "--icon-symbol": `"\\${Number(iconType).toString(16)}"` }
            : { "--icon-url": `url(${iconType})` }
          )
        )
      )
    }
  >
    {typeof iconType === "string" && <img alt="" className="icon__url" src={iconType} />}
  </div>
)

export default Icon
