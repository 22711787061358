import {
  MARK_NOTIFICATION_READ,
  SET_NOTIFICATION_FILTER,
  DELETE_NOTIFICATION,
  Action,
  NotificationState,
  State
} from "./types"
import { WARN_MESSAGES } from "./messages/warn-messages"
import { GENERIC_MESSAGES } from "./messages/generic-messages"
import data from "./data"

// @NOTE(kirill): Those empty object fallbacks are temporary. First we need
// to decide on notifications shape and storage.

const initial_notifications_list: Readonly<object[]> = Object.freeze([
  (WARN_MESSAGES.get(55) || {}),
  (GENERIC_MESSAGES.get(1) || {}),
  (GENERIC_MESSAGES.get(2) || {})
])

const initialState: NotificationState = {
  list: initial_notifications_list,
  unread: initial_notifications_list,
  filter: data.tabs[0]
}

export default Object.freeze(function notificationReducer(
  state = initialState,
  action: Action
): State {
  switch (action.type) {

    case SET_NOTIFICATION_FILTER:
      return {
        ...state,
        filter: action.value
      }

    case MARK_NOTIFICATION_READ:
      return {
        ...state,
        unread: state.unread.filter((item) => item !== action.value)
      }
    
    case DELETE_NOTIFICATION:
      return {
        ...state,
        list: state.list.filter((item) => item !== action.value),
        unread: state.unread.filter((item) => item !== action.value)
      }

    default:
      return state
  }
})
