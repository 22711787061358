import { IconType } from "@pag/center/components/icon/types"
import icon from "@pag/center/components/icon/icons"

// import {BlockingRuleId} from '@mib3-pag/widgets';

export enum WarnMessageActionI18nEnum {
  QuickAction_Bedienungsanleitung = "Notification_Center_Quick_Actions_Manual",
  QuickAction_Werkstatt = "Werkstatt",
  QuickAction_Ladestation = "Ladestation",
  QuickAction_Tankstelle = "Notification_Center_Quick_Actions_Gas_Station",
  QuickAction_Pannenruf = "Notification_Center_Quick_Actions_breakdown",
  QuickAction_Reifendruckkontrolle = "Reifendruckkontrolle",
  QuickAction_Parkbremse = "Parkbremse bedienen",
  QuickAction_Update = "Update"
}

export enum WarnMessageQuickActions {
  Bedienungsanleitung,
  Werkstatt,
  Ladestation,
  Tankstelle,
  Pannenruf,
  Reifendruckkontrolle,
  Parkbremse,
  Update
}

export interface MessageAction {
  id: number
  text: string
  icon: IconType
  // blockingRuleId?: BlockingRuleId | string
}

export const WARN_MESSAGE_QUICK_ACTIONS = new Map<number, MessageAction>([
  [
    WarnMessageQuickActions.Bedienungsanleitung,
    {
      id: WarnMessageQuickActions.Bedienungsanleitung,
      text: WarnMessageActionI18nEnum.QuickAction_Bedienungsanleitung,
      icon: icon.manual
    }
  ],
  [
    WarnMessageQuickActions.Werkstatt,
    {
      id: WarnMessageQuickActions.Werkstatt,
      text: WarnMessageActionI18nEnum.QuickAction_Werkstatt,
      icon: icon.magnify_glass
    }
  ],
  [
    WarnMessageQuickActions.Ladestation,
    {
      id: WarnMessageQuickActions.Ladestation,
      text: WarnMessageActionI18nEnum.QuickAction_Ladestation,
      icon: icon.magnify_glass
    }
  ],
  [
    WarnMessageQuickActions.Tankstelle,
    {
      id: WarnMessageQuickActions.Tankstelle,
      text: WarnMessageActionI18nEnum.QuickAction_Tankstelle,
      icon: icon.magnify_glass
    }
  ],
  [
    WarnMessageQuickActions.Pannenruf,
    {
      id: WarnMessageQuickActions.Pannenruf,
      text: WarnMessageActionI18nEnum.QuickAction_Pannenruf,
      icon: icon.b_call_premium
    }
  ],
  [
    WarnMessageQuickActions.Reifendruckkontrolle,
    {
      id: WarnMessageQuickActions.Reifendruckkontrolle,
      text: WarnMessageActionI18nEnum.QuickAction_Reifendruckkontrolle,
      icon: icon.warning_tire_pressure
    }
  ],
  [
    WarnMessageQuickActions.Parkbremse,
    {
      id: WarnMessageQuickActions.Parkbremse,
      text: WarnMessageActionI18nEnum.QuickAction_Parkbremse,
      icon: icon.parking_brake
    }
  ],
  [
    WarnMessageQuickActions.Update,
    {
      id: WarnMessageQuickActions.Update,
      text: WarnMessageActionI18nEnum.QuickAction_Update,
      icon: icon.update_save
    }
  ]
])
