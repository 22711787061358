import React from "react"
import cx from "classnames"

import "./scrollPanel.scss"

interface IScrollPanel {
  className?: string
  children?: React.ReactNode
  style?: React.CSSProperties
}

const ScrollPanel: React.FunctionComponent<IScrollPanel> = ({ className, children, style }) => {
  return (
    <div className={cx("scroll-panel-wrapper", className)} style={style}>
      <div className="content">{children}</div>
    </div>
  )
}

export default ScrollPanel
