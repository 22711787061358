import i18n from "i18next"

import {
  Action,
  MediaState,
  TOGGLE_MEDIA_PLAYVIEW,
  TOGGLE_APPLEID_POPUP,
  TOGGLE_LYRICS_DUMMY,
  TOGGLE_OPTIONS_SLIDEOUT,
  TOGGLE_SIRIUS_XM_POPUP,
  SHOW_SIRIUS_XM_WELCOMESCREEN
} from "./types"
import settingDefs from "@pag/center/views/settingsScreen/definitions"
import { ESourceNames } from "@pag/center/components/player/audioPlayer/types"

const mediaInitialState: MediaState = {
  isPlayviewVisible: false,
  appleIdPopUpVisible: false,
  isLyricsDummyVisible: false,
  isOptionsSlideOutVisible: false,
  siriusXmPopUpVisible: false,
  siriusXmWelcomeScreenVisible: true,
  availableSources: settingDefs.mediaSources.filter((source: any) => {
    if (source.name === ESourceNames.ANDROID_AUTO) {
      return false
    } else if (source.name === ESourceNames.SIRIUS_XM && i18n.language !== "en_US") {
      return false
    } else {
      return true
    }
  })
}

export default Object.freeze(function mediaReducer(state = mediaInitialState, action: Action) {
  switch (action.type) {
    case TOGGLE_MEDIA_PLAYVIEW:
      return { ...state, isPlayviewVisible: !state.isPlayviewVisible }
    case TOGGLE_APPLEID_POPUP:
      return { ...state, appleIdPopUpVisible: action.visible }
    case TOGGLE_LYRICS_DUMMY:
      return { ...state, isLyricsDummyVisible: !state.isLyricsDummyVisible }
    case TOGGLE_OPTIONS_SLIDEOUT:
      return { ...state, isOptionsSlideOutVisible: !state.isOptionsSlideOutVisible }
    case TOGGLE_SIRIUS_XM_POPUP:
      return { ...state, siriusXmPopUpVisible: action.visible }
    case SHOW_SIRIUS_XM_WELCOMESCREEN:
      return { ...state, siriusXmWelcomeScreenVisible: action.visible }
    default:
      return state
  }
})
