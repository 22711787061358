// Handles unit conversion and formatting.
//
// useUnitFormat — a selector that takes `UnitKind` and returns formatting
//                 function
//
// decoderByUnit — plain function that takes a unit from settings definitions
//                 and returns formatting function
//
// useBaseConverter — 
//
// convertorByUnit — 


import i18next from "i18next"
import { useSelector } from "react-redux"

import { UnitKind } from "./types"
import { AppState } from "../../../redux/reducer"
import definitions from "@pag/center/views/settingsScreen/definitions"

function lowerPrecision(num: number): number {
  return Math.ceil(num / 10) * 10
}

function noMoreThanOneDigitAfterZero(num: number): string {
  return Number.isInteger(num) ? String(num) : num.toFixed(1)
}

function toClosestMultiple(num: number, multiple: number): number {
  return Math.round(num / multiple) * multiple
}

function alignWithStep(num: number, step: number = 1): string {
  const value = toClosestMultiple(num, step)
  return (
    Number.isInteger(step)
    ? String(value)
    : (value).toFixed(1)
  )
}

// DATE AND TIME

const s = 1000
const m = s * 60
const h = m * 60
const d = h * 24

function duration_format(value: number | undefined): string {
  if (value === undefined) {
    return i18next.t("Units_Duration_Invalid")
  }

  const days = Math.trunc(value / d)
  if (days > 0) {
    return `${days} ${i18next.t("Units_Duration_Days")}`
  }

  const hours = Math.trunc(value / h)
  const minutes = Math.trunc((value - (hours * h)) / m)
  if (hours > 0) {
    return `${hours}:${String(minutes).padStart(2, "0")} ${i18next.t("Units_Duration_Hours")}`
  }

  if (minutes > 0) {
    return `${String(minutes).padStart(2, "0")} ${i18next.t("Units_Duration_Minutes")}`
  }

  const seconds = Math.trunc(value / s)
  if (seconds > 0) {
    return `${String(seconds).padStart(2, "0")} ${i18next.t("Units_Duration_Seconds")}`
  } else {
    return i18next.t("Units_Duration_Invalid")
  }
}

function duration_precise_format(value: number | undefined): string {
  if (value === undefined) {
    return i18next.t("Units_Duration_Invalid")
  }

  const sign = Math.sign(value)
  const val = Math.abs(value)
  const hours = Math.trunc(val / h)
  const minutes = Math.trunc((val - (h * hours)) / m)
  const seconds = Math.trunc((val - (m * minutes)) / s)
  const milliseconds = Math.trunc((val - (h * hours) - (m * minutes) - (s * seconds)) / 10)

  return `${sign < 0 ? "−" : ""}${minutes}:${String(seconds).padStart(2, "0")}.${String(milliseconds).padStart(2, "0")}`
}

const date_simple = (date: Date): {[index: string]: string} => ({
  day: String(date.getDate()).padStart(2, "0"),
  month: String(date.getMonth() + 1).padStart(2, "0"),
  year: String(date.getFullYear())
})

const strip_leading_zero = (str: string) => str[0] === "0" ? str.slice(1) : str

// DISTANCE

const metersInKm = 1000
const metersInMile = 1609.344
const metersInFt = 0.3048
const metersToKm = (num: number): number => num / metersInKm
const kmToMeters = (num: number): number => num * metersInKm
const metersToMiles = (num: number): number => num / metersInMile
const milesToMeters = (num: number): number => num * metersInMile
const metersToFt = (num: number): number => num / metersInFt
const ftToMeters = (num: number): number => num * metersInFt

// CONSUMPTION

const kmkWh_to_kWh100km = (num: number): number => 100 / num
const kmkWh_to_kWh100mi = (num: number): number => 160.9344 / num
const kmkWh_to_mikWh = (num: number): number => num / 1.609344
const kWh100km_to_kmkWh = (num: number): number => 100 / num
const kWh100mi_to_kmkWh = (num: number): number => 160.9344 / num
const mikWh_to_kmkWh = (num: number): number => 1.609344 * num

// SPEED

const msInKmh = 5 / 18
const msInMph = 0.44704
const msToKmh = (num: number): number => num / msInKmh
const kmhToMs = (num: number): number => num * msInKmh
const msToMph = (num: number): number => num / msInMph
const mphToMs = (num: number): number => num * msInMph

// TEMPERATURE

// @NOTE(kirill): Temperatures here are used for climate system. There’s strict
// limit to the range the user could choose. We align min value and go up in
// fixed steps.

const min_temperature_c = 16
const min_temperature_f = 60

function celsiusToFahrenheit(num: number): number {
  return min_temperature_f + ((num - min_temperature_c) / 0.5)
}

function fahrenheitToCelsius(num: number): number {
  return min_temperature_c + ((num - min_temperature_f) * 0.5)
}

// PRESSURE

const paInBar = 100000 // 100 000 Pa
const paInPsi = 6894.757 // 6.894757 * 10³ Pa
const paInKpa = 1000
const paToBar = (num: number) => num / paInBar
const barToPa = (num: number) => num * paInBar
const paToPsi = (num: number) => num / paInPsi
const psiToPa = (num: number) => num * paInPsi
const paToKpa = (num: number) => num / paInKpa
const kpaToPa = (num: number) => num * paInKpa

// DECODERS

const decoders = new WeakMap<any, (value: any) => string>([

  // 24-hour
  [
    definitions.timeFormats[0],
    (value: Date | undefined) => (
      value === undefined
      ? i18next.t("Settings_Units_InvalidTime")
      : strip_leading_zero(
        value.toLocaleTimeString(
          "en",
          {
            // @ts-ignore
            timeStyle: "short",
            hour12: false
          }
        )
      )
    )
  ],

  // 12-hour
  [
    definitions.timeFormats[1],
    (value: Date) => (
      value === undefined
      ? i18next.t("Settings_Units_InvalidTime")
      : strip_leading_zero(value.toLocaleTimeString(
        "en",
        // @ts-ignore
        { timeStyle: "short" })
      ).slice(0, 5).trim() +
      " " + (
        value.getHours() > 11
        ? i18next.t("Settings_DateAndTime_Meridiem_PM")
        : i18next.t("Settings_DateAndTime_Meridiem_AM")
      )
    )
  ],

  // DD.MM.YYYY
  [
    definitions.dateFormats[0],
    (value: Date): string => {
      const {day, month, year} = date_simple(value)
      return `${day}.${month}.${year}`
    }
  ],

  // MM/DD/YYYY
  [
    definitions.dateFormats[1],
    (value: Date): string => {
      const {day, month, year} = date_simple(value)
      return `${month}/${day}/${year}`
    }
  ],

  // YYYY-MM-DD
  [
    definitions.dateFormats[2],
    (value: Date): string => {
      const {day, month, year} = date_simple(value)
      return `${year}-${month}-${day}`
    }
  ],

  // {N} km
  [
    definitions.distanceUnits[0],
    (value: number) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : (
        (
          definitions.distanceUnits[0].min !== undefined &&
          value < definitions.distanceUnits[0].min
        )
        ? `${lowerPrecision(value)} ${i18next.t(definitions.distanceUnits[2].label)}`
        : `${Math.floor(metersToKm(value))} ${i18next.t(definitions.distanceUnits[0].label)}`
      )
    )
  ],

  // {N} mi
  [
    definitions.distanceUnits[1],
    (value: number) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : (
        (
          definitions.distanceUnits[1].min !== undefined &&
          value < definitions.distanceUnits[1].min
        )
        ? `${lowerPrecision(metersToFt(value))} ${i18next.t(definitions.distanceUnits[3].label)}`
        : `${Math.floor(metersToMiles(value))} ${i18next.t(definitions.distanceUnits[1].label)}`
      )
    )
  ],

  // {N} kWh/100km
  [
    definitions.consumptionUnits[0],
    (value: number) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : `${kmkWh_to_kWh100km(value).toFixed(1)} ${i18next.t(definitions.consumptionUnits[0].label)}`
    )
  ],

  // {N} km/kWh
  [
    definitions.consumptionUnits[1],
    (value: number) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : `${value.toFixed(1)} ${i18next.t(definitions.consumptionUnits[1].label)}`
    )
  ],

  // {N} kWh/100 mi
  [
    definitions.consumptionUnits[2],
    (value: number) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : `${kmkWh_to_kWh100mi(value).toFixed(1)} ${i18next.t(definitions.consumptionUnits[2].label)}`
    )
  ],

  // {N} mi/kWh
  [
    definitions.consumptionUnits[3],
    (value: number) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : `${kmkWh_to_mikWh(value).toFixed(1)} ${i18next.t(definitions.consumptionUnits[3].label)}`
    )
  ],


  // {N} km/h
  [
    definitions.speedUnits[0],
    (value: number) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : `${Math.floor(msToKmh(value))} ${i18next.t(definitions.speedUnits[0].label)}`
    )
  ],

  // {N} mph
  [
    definitions.speedUnits[1],
    (value: number) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : `${Math.floor(msToMph(value))} ${i18next.t(definitions.speedUnits[1].label)}`
    )
  ],

  // {N} °C
  [
    definitions.temperatureUnits[0],
    (value: number | undefined) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : `${alignWithStep(value, definitions.temperatureUnits[0].step)} ${i18next.t(definitions.temperatureUnits[0].label)}`
    )
  ],

  // {N} °F
  [
    definitions.temperatureUnits[1],
    (value: number) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : `${alignWithStep(celsiusToFahrenheit(value))} ${i18next.t(definitions.temperatureUnits[1].label)}`
    )
  ],

  [
    definitions.chargeSpeedUnits[0],
    (value: number | undefined) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : `${metersToKm(value).toFixed(1)} ${i18next.t(definitions.chargeSpeedUnits[0].label)}`
    )
  ],

  [
    definitions.chargeSpeedUnits[1],
    (value: number | undefined) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : `${metersToMiles(value).toFixed(1)} ${i18next.t(definitions.chargeSpeedUnits[1].label)}`
    )
  ],

  // {N} bar
  [
    definitions.pressureUnits[0],
    (value: number | undefined) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : `${noMoreThanOneDigitAfterZero(paToBar(value))} ${definitions.pressureUnits[0].label}`
    )
  ],

  // {N} psi
  [
    definitions.pressureUnits[1],
    (value: number | undefined) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : `${noMoreThanOneDigitAfterZero(paToPsi(value))} ${definitions.pressureUnits[1].label}`
    )
  ],

  // {N} kPa
  [
    definitions.pressureUnits[2],
    (value: number | undefined) => (
      value === undefined
      ? i18next.t("Settings_Units_Invalid")
      : `${noMoreThanOneDigitAfterZero(paToKpa(value))} ${definitions.pressureUnits[2].label}`
    )
  ],

])

const unit_kind_definitions = {
  [UnitKind.None]:            undefined,
  [UnitKind.Charge]:          "Settings_System_Units_Charging",
  [UnitKind.Consumption]:     "Settings_System_Units_Consumption",
  [UnitKind.Date]:            "Settings_System_DateAndTime_SetDateFormat",
  [UnitKind.Distance]:        "Settings_System_Units_Distance",
  [UnitKind.Duration]:        undefined,
  [UnitKind.DurationPrecise]: undefined,
  [UnitKind.Percentage]:      undefined,
  [UnitKind.Pressure]:        "Settings_System_Units_Pressure",
  [UnitKind.Speed]:           "Settings_System_Units_Speed",
  [UnitKind.Temperature]:     "Settings_System_Units_Temperature",
  [UnitKind.Time]:            "Settings_System_DateAndTime_SetTimeFormat",
  [UnitKind.GForce]:          undefined
}

function decoder_selector_by_kind(kind: UnitKind) {
  if (kind === UnitKind.Duration) {
    return () => duration_format
  }

  if (kind === UnitKind.DurationPrecise) {
    return () => duration_precise_format
  }

  if (kind === UnitKind.GForce) {
    return () => (value: number) => value.toFixed(2).replace(/(\.[0]+|[0]+)$/, '') + " " + i18next.t("Units_GForce")
  }

  if (kind === UnitKind.Percentage) {
    return () => (value: number) => value.toFixed(0) + " %"
  }

  const setting_name = unit_kind_definitions[kind] as string | undefined
  if (setting_name === undefined) {
    return () => String
  }

  return function (state: AppState) {
    const unit = state.settings[setting_name]

    if (unit === undefined) {
      return String
    }

    return decoders.get(unit) || String
  }
}

// CONVERTERS

type PlainConverter = (value: number) => number

type Converter = {
  to: PlainConverter,
  from: PlainConverter
}

const passthrough = (value: number): number => value

const passthrough_converter = Object.freeze({
  to: passthrough,
  from: passthrough
})

const converters = new WeakMap<any, Converter>([

  // km
  [
    definitions.distanceUnits[0],
    Object.freeze({
      to: kmToMeters,
      from: metersToKm
    })
  ],

  // mi
  [
    definitions.distanceUnits[1],
    Object.freeze({
      to: milesToMeters,
      from: metersToMiles
    })
  ],

  // m
  [
    definitions.distanceUnits[0],
    passthrough_converter
  ],

  // ft
  [
    definitions.distanceUnits[1],
    Object.freeze({
      to: ftToMeters,
      from: metersToFt
    })
  ],

  // kWh/100km
  [
    definitions.consumptionUnits[0],
    Object.freeze({
      to: kWh100km_to_kmkWh,
      from: kmkWh_to_kWh100km
    })
  ],

  // km/kWh
  [
    definitions.consumptionUnits[1],
    passthrough_converter
  ],

  // kWh/100 mi
  [
    definitions.consumptionUnits[2],
    Object.freeze({
      to: kWh100mi_to_kmkWh,
      from: kmkWh_to_kWh100mi
    })
  ],

  // mi/kWh
  [
    definitions.consumptionUnits[3],
    Object.freeze({
      to: mikWh_to_kmkWh,
      from: kmkWh_to_mikWh
    })
  ],

  // km/h
  [
    definitions.speedUnits[0],
    Object.freeze({
      to: kmhToMs,
      from: msToKmh
    })
  ],

  // mph
  [
    definitions.speedUnits[1],
    Object.freeze({
      to: mphToMs,
      from: msToMph
    })
  ],

  // °C
  [
    definitions.temperatureUnits[0],
    passthrough_converter
  ],

  // °F
  [
    definitions.temperatureUnits[1],
    Object.freeze({
      to: fahrenheitToCelsius,
      from: celsiusToFahrenheit
    })
  ],

  [
    definitions.chargeSpeedUnits[0],
    Object.freeze({
      to: kmToMeters,
      from: metersToKm
    })
  ],

  [
    definitions.chargeSpeedUnits[1],
    Object.freeze({
      to: milesToMeters,
      from: metersToMiles
    })
  ],

  // bar
  [
    definitions.pressureUnits[0],
    Object.freeze({
      to: barToPa,
      from: paToBar
    })
  ],

  // psi
  [
    definitions.pressureUnits[1],
    Object.freeze({
      to: psiToPa,
      from: paToPsi
    })
  ],

  // kPa
  [
    definitions.pressureUnits[2],
    Object.freeze({
      to: kpaToPa,
      from: paToKpa
    })
  ]

])

function converter_selector_by_kind(kind: UnitKind) {
  const setting_name = unit_kind_definitions[kind] as string | undefined

  if (setting_name === undefined) {
    return () => passthrough_converter
  }

  return function (state: AppState) {
    const unit = state.settings[setting_name]

    if (unit === undefined) {
      return passthrough_converter
    }

    return converters.get(unit) || passthrough_converter
  }
}

export default Object.freeze({
  useUnitFormat: (kind: UnitKind): (value: Date | number | undefined) => string => useSelector(decoder_selector_by_kind(kind)),
  decoderByUnit: (unit: any): (value: Date | number | undefined) => string => decoders.get(unit) || String,
  useBaseConverter: (kind: UnitKind) => useSelector(converter_selector_by_kind(kind)),
  convertorByUnit: (unit: any): Converter => converters.get(unit) || passthrough_converter
})
