import React from "react"
import cx from "classnames"

import "./bigText.scss"

interface IBigText {
  firstLine?: React.ReactElement<any>
  secondLine?: React.ReactElement<any>
  className?: string
}

const BigText: React.FunctionComponent<IBigText> = (props) => {
  const showSecondLine = () => {
    if (props.secondLine) return <div className="big-text-component__second-line">{props.secondLine}</div>
  }

  return (
    <div className={cx("big-text", props.className)}>
      <div className="big-text-component__first-line">{props.firstLine}</div>
      {showSecondLine()}
    </div>
  )
}

export default BigText
