import car_data from "@pag/center/views/carScreen/data"
import settings_definitions from "@pag/center/views/settingsScreen/definitions"
import trip from "@pag/center/views/carScreen/trip/data"

const msInKmh = 5 / 18
const kmhToMs = (num: number): number => num * msInKmh

export default Object.freeze({
    Car_Drive_DriveMode_ListItem:                                          car_data.drive_modes,
    Car_Drive_Chassis_ListItem:                                            car_data.chassis_values,
    Car_Drive_Level_ListItem:                                              car_data.level_values,
    Car_Drive_Recuperation_ListItem:                                       car_data.recuperation_values,
    Car_Drive_ESound_ListItem:                                             car_data.e_sound_values,
    Car_Drive_Terrain_ListItem:                                            car_data.terrain_values,
    Car_Drive_HybridMode_ListItem:                                         car_data.hybrid_modes,
    Car_Assistance_AssistMode:                                             car_data.assist_modes,
    Car_Assistance_LaneDeparture:                                          car_data.assist_lane_departure_values,
    Car_Assistance_DistanceWarning:                                        car_data.assist_distance_warning_values,
    Car_Comfort_AmbientLight_Total_ListItem:                               settings_definitions.ambientBrightness,
    Car_Comfort_AmbientLight_Door_ListItem:                                settings_definitions.ambientBrightness,
    Car_Comfort_AmbientLight_Cupholder_ListItem:                           settings_definitions.ambientBrightness,
    Car_Comfort_AmbientLight_CenterConsole_ListItem:                       settings_definitions.ambientBrightness,
    Car_Comfort_AmbientLight_Fond_ListItem:                                settings_definitions.ambientBrightness,
    Car_Comfort_AmbientLight_Footwell_ListItem:                            settings_definitions.ambientBrightness,
    Car_Comfort_AmbientLight_TopRoof_ListItem:                             settings_definitions.ambientBrightness,
    Car_Comfort_Heating_DriverSeat_ListItem:                               settings_definitions.heating_balance,
    Car_Comfort_Heating_CodriverSeat_ListItem:                             settings_definitions.heating_balance,
    Car_Comfort_Ventilation_DriverSeat_ListItem:                           settings_definitions.ventilation_balance,
    Car_Comfort_Ventilation_CodriverSeat_ListItem:                         settings_definitions.ventilation_balance,
    Car_Comfort_AmbientLight_Color_ListItem:                               car_data.comfort_color_values,
    Car_Comfort_MassageIntensity_DriverSeat_ListItem:                      car_data.comfort_massage_intensity_values,
    Car_Comfort_MassageIntensity_CodriverSeat_ListItem:                    car_data.comfort_massage_intensity_values,
    Car_Comfort_MassageProgram_DriverSeat_ListItem:                        car_data.comfort_massage_program_values,
    Car_Comfort_MassageProgram_CodriverSeat_ListItem:                      car_data.comfort_massage_program_values,
    Calendar_Settings_StartWeek:                                           settings_definitions.calendarWeekStart,
    Settings_AssistanceSystems_AdaptiveSpeedLimiter_AdoptionOfSpeedlimits: settings_definitions.assistanceSpeedLimitsAdoption,
    Settings_AssistanceSystems_AdaptiveSpeedLimiter_MaximumSpeed:          kmhToMs,
    Settings_AssistanceSystems_LaneChangeAssist_Brightness:                settings_definitions.assistanceLaneChangeBrightness,
    Settings_AssistanceSystems_LaneDepartureWarning_Volume:                settings_definitions.assistanceLaneDepartureWarning,
    Settings_AssistanceSystems_NightViewAssist_WarningTime:                settings_definitions.assistanceWarningTime,
    Settings_AssistanceSystems_ParkAssist_Volume:                          settings_definitions.assistanceParkAssistVolume,
    Settings_AssistanceSystems_PorscheInnoDrive_MaxSpeed:                  kmhToMs,
    Settings_AssistanceSystems_TrafficSignRecognition_Exceed:              kmhToMs,
    Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning:        settings_definitions.assistanceCollisionWarning,
    Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning:         settings_definitions.assistanceDistanceWarning,
    Settings_Car_Drive_IndividualDriveModeConfiguration_DriveMode:         car_data.drive_modes,
    Settings_Car_Drive_IndividualDriveModeConfiguration_Chassis:           car_data.chassis_values,
    Settings_Car_Drive_IndividualDriveModeConfiguration_Niveau:            car_data.level_values,
    Settings_Car_Drive_IndividualDriveModeConfiguration_Recuperation:      car_data.recuperation_values,
    Settings_Car_Drive_IndividualDriveModeConfiguration_ESound:            car_data.e_sound_values,
    Settings_RangeDriveMode_Configuration_Air_Conditioning:                settings_definitions.driveRangeModeAirConditioning,
    Settings_RangeDriveMode_Configuration_Maximum_Speed:                   kmhToMs,
    Settings_Car_Trip_ConfigureTripPersonally_tile1:                       trip.personal_boxes,
    Settings_Car_Trip_ConfigureTripPersonally_tile2:                       trip.personal_boxes,
    Settings_Car_Trip_ConfigureTripPersonally_tile3:                       trip.personal_boxes,
    Settings_Car_Trip_ConfigureTripPersonally_tile4:                       trip.personal_boxes,
    Settings_Climate_FootwellTemperature_Driver:                           settings_definitions.climateFootwellTemperature,
    Settings_Climate_FootwellTemperature_CoDriver:                         settings_definitions.climateFootwellTemperature,
    Settings_Display_CenterConsoleOperatingPanel_FocusMovementSpeed:       settings_definitions.focusMovementSpeed,
    Settings_Display_CenterConsoleOperatingPanel_HapticFeedback:           settings_definitions.hapticFeedback,
    Settings_Display_CenterConsoleOperatingPanel_TouchTones:               settings_definitions.touchTones,
    Settings_Display_CentralDisplay_TouchTones:                            settings_definitions.touchTones,
    Settings_Display_HUD_Custom_Adjust_MainArea:                           settings_definitions.hudCustomAdjustMainArea,
    Settings_Display_HUD_DayAndNightView:                                  settings_definitions.hudDayNightView,
    Settings_Display_HUD_IncomingCalls:                                    settings_definitions.hudIncomingCalls,
    Settings_Display_HUD_TrafficSign_Deviation:                            kmhToMs,
    Settings_Display_HUD_View:                                             settings_definitions.hudView,
    Settings_Display_InstrumentCluster_ConfigureDisplayScope:              settings_definitions.displayScope,
    Settings_Display_InstrumentCluster_CustomisedView_Line1:               settings_definitions.displayCustomizedView,
    Settings_Display_InstrumentCluster_CustomisedView_Line2:               settings_definitions.displayCustomizedView,
    Settings_Display_InstrumentCluster_CustomisedView_Line3:               settings_definitions.displayCustomizedView,
    Settings_Display_InstrumentCluster_CustomisedView_Line4:               settings_definitions.displayCustomizedView,
    Settings_Display_InstrumentCluster_PersonalSpeedLimits_SpeedLimit1:    [ null, kmhToMs ],
    Settings_Display_InstrumentCluster_PersonalSpeedLimits_SpeedLimit2:    [ null, kmhToMs ],
    Settings_Display_InstrumentCluster_TrafficSignRecognition_Deviation:   kmhToMs,
    Settings_Media_AdjustSortingOfSources:                                 settings_definitions.mediaSources,
    Settings_Navigation_NavigationSettings_MapSettings_DayAndNightView:    settings_definitions.navigationDayAndNightView,
    Settings_Navigation_RouteOptions_PorscheCharging_MinChargingPower:     settings_definitions.chargingStopsPower,
    Settings_NotificationCenter_Allow:                                     settings_definitions.notificationCenterAllow,
    Settings_Phone_PhoneBookSettings_Criteria:                             settings_definitions.phoneBookSortingCriteria,
    Settings_PrivacyAndPorscheConnect_AnonData:                            settings_definitions.privacyModes,
    Settings_PrivacyAndPorscheConnect_DataFromPorsche:                     settings_definitions.privacyModes,
    Settings_PrivacyAndPorscheConnect_Improvements:                        settings_definitions.privacyModes,
    Settings_PrivacyAndPorscheConnect_ConsentData:                         settings_definitions.privacyModes,
    Settings_PrivacyAndPorscheConnect_PrivateMode:                         settings_definitions.privacyModes,
    Settings_PrivacyAndPorscheConnect_ServiceWithTracking:                 settings_definitions.privacyModes,
    Settings_PrivacyAndPorscheConnect_ServicesWithTracking:                settings_definitions.privacyModes,
    Settings_PrivacyAndPorscheConnect_ServicesWithoutTracking:             settings_definitions.privacyModes,
    Settings_System_DateAndTime_SetDateFormat:                             settings_definitions.dateFormats,
    Settings_System_DateAndTime_SetTimeFormat:                             settings_definitions.timeFormats,
    Settings_System_DateAndTime_SetTimeZoneManually:                       settings_definitions.timezones,
    Settings_System_TimeAndTemperature:                                    settings_definitions.timeTemp,
    Settings_System_Units_Charging:                                        settings_definitions.chargeSpeedUnits,
    Settings_System_Units_Consumption:                                     settings_definitions.consumptionUnits,
    Settings_System_Units_Distance:                                        settings_definitions.distanceUnits,
    Settings_System_Units_Pressure:                                        settings_definitions.pressureUnits,
    Settings_System_Units_Speed:                                           settings_definitions.speedUnits,
    Settings_System_Units_SpeedAndDistance:                                settings_definitions.speedAndDistanceUnits,
    Settings_System_Units_Temperature:                                     settings_definitions.temperatureUnits,
    Settings_Vehicle_ShortcutButtons_ClusterButton:                        settings_definitions.vehicleShortcutButtons,
    Settings_Vehicle_ShortcutButtons_SteeringWheel:                        settings_definitions.vehicleShortcutButtons,
    Settings_Vehicle_TyrePressureMonitoring_Selection:                     settings_definitions.vehicleTyrePressureSelection,
    Settings_Vehicle_TyrePressureMonitoring_Load:                          settings_definitions.vehicleTyrePressureLoad,
    Settings_Vehicle_VehicleLockingSystems_DoorUnlocking:                  settings_definitions.vehicleDoorUnlocking,
    Settings_Volume_LaneDeparture:                                         settings_definitions.laneDepartureVolume,
    Settings_Volume_ParkAssist:                                            settings_definitions.parkAssistVolume,
    Settings_Volume_TouchTones:                                            settings_definitions.touchTones
}) as { [index: string]: any };
