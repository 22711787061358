import React from "react"
import ClassNames from "classnames"

import icon from "@pag/center/components/icon/icons"
import units from "@pag/center/components/unit/units"
import { UnitKind } from "@pag/center/components/unit/types"
import List from "@pag/center/components/list/List"
import I18nLabel from "@pag/center/components/I18nLabel/I18nLabel"
import NonI18nLabel from "@pag/center/components/nonI18nLabel/NonI18nLabel"
import BigText from "@pag/center/components/bigText/BigText"
import Icon from "@pag/center/components/icon/Icon"
import ListItemNaviAnythingAnywhere from "@pag/center/components/list/listItemNaviAnythingAnywhere/ListItemNaviAnythingAnywhere"

import "./poiStackList.scss"

interface IPoiStackList {
  className?: string
  poiStackList?: any[]
  onTap: (idx: number) => void
  onTapEnd: () => void
}

const PoiStackList: React.FC<IPoiStackList> = (props: IPoiStackList) => {
  const formatDistance = units.useUnitFormat(UnitKind.Distance)

  const renderListContent = () => {
    // @ts-ignore
    return props.poiStackList.map((item: PoiItem, idx: number) => (
      <ListItemNaviAnythingAnywhere
        key={idx}
        className="poi-list__item"
        // item={item}
        hideCenterEnd={false}
        startContent={
          <Icon
            // iconUrl={item.icon}
            // iconType={icon.poi}
            iconType={item.icon} // TODO
          />
        }
        centerContent={
          <BigText
            firstLine={
              <NonI18nLabel
                className="address-item"
                text={item.location.addressLineFirst} // TODO
              />
            }
          />
        }
        centerEnd={
          <div className="poi-list__item--center-end">
            {item.distance === undefined ? (
              <Icon
                iconType={icon.processing}
                iconClass="state-rotate-right"
                // rotate={RotationType.Right}
              />
            ) : (
              <span className="distance">{formatDistance(item.distance)}</span>
            )}
          </div>
        }
        endContent={
          <div className="poi-list__item--end">
            <Icon
              // iconClass="icon-details" // TODO
              iconType={icon.options_more}
            />
          </div>
        }
        onTap={() => props.onTap(idx)}
        onTapEnd={props.onTapEnd}
      />
    ))
  }

  return (
    <div className={ClassNames("poi-stack-list-wrapper", props.className)}>
      {props.poiStackList && (
        <div className="headline">
          <span className="headline__amount">{props.poiStackList.length}</span>
          <I18nLabel className="pois-available" text="NavigationScreen_PoiStackList_Pois-Available" />
        </div>
      )}
      <List className="poi-list" content={renderListContent()} isScrollbarVisible={true} scrollSnapProximity={true} />
    </div>
  )
}

export default PoiStackList
