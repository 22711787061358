import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { availableLanguages } from "./components/pagCenter/translations/availableLanguages"
import XHR from "i18next-xhr-backend"

if (!localStorage.getItem("locale")) {
  localStorage.setItem("locale", "en_GB")
  window.location.reload()
}

const locale = localStorage.getItem("locale")

if (
  window.location.search.split("locale=")[1] !== locale &&
  availableLanguages.includes(window.location.search.split("locale=")[1])
) {
  localStorage.setItem("locale", window.location.search.split("locale=")[1])
  window.location.reload()
}

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    fallbackLng: "en_GB",
    defaultNS: "center",
    backend: {
      loadPath: "/i18n/{{lng}}.json",
      crossDomain: true
    }
  })

export default i18n
