import object_tools from "@pag/utils/object-tools"
import icon from "@pag/center/components/icon/icons"
import { List_Entry } from "@pag/center/components/player/audioPlayer/types"

import webtunes from "@pag/center/assets/mp3/covers/webtunes_vol3.png"
import webtunes2 from "@pag/center/assets/mp3/covers/webtunes_vol4.png"

const importAll = (r: any) => r.keys().reduce(
  (songs: { [index: string]: any }, item: any) => {
    songs[item.replace("./", "")] = r(item)
    return songs
  },
  Object.create(null)
)
const assets = importAll(require.context("@pag/center/assets/mp3/usb", false, /\.(mp3)$/))

const populate_albums = object_tools.populate_loose(
    "label",                           "icon"
)

const albums: Readonly<List_Entry[]> = populate_albums(
  [ "Porsche Webtunes Vol.3 - Cayman", webtunes ],
  [ "Porsche Webtunes Vol.4 - Boxter", webtunes2 ]
)

const populate_artists = object_tools.populate(
    "label",           "icon",      "list"
)

const artists: Readonly<List_Entry[]> = populate_artists(
  [ "Various Artists", icon.person, albums.slice() ]
)

const populate_songs = object_tools.populate(
    "label",              "album",   "artist",   "source",                       "duration"
)

export const songs: Readonly<List_Entry[]> = populate_songs(
  [ "Radically You",      albums[0], artists[0], assets["sign_here.mp3"],        "6:00" ],
  [ "Pushing Boundaries", albums[0], artists[0], assets["afro_blue.mp3"],        "4:11" ],
  [ "Flat-6",             albums[0], artists[0], assets["along_the_ocean.mp3"],  "2:51" ],
  [ "Peridot Shimmer",    albums[0], artists[0], assets["drive.mp3"],            "3:38" ],
  [ "Bpm sets Rpm",       albums[0], artists[0], assets["life_intensified.mp3"], "3:42" ],
  [ "Depth-sounder",      albums[0], artists[0], assets["one.mp3"],              "4:08" ],
  [ "Complete",           albums[0], artists[0], assets["ambient.mp3"],          "3:00" ],

  [ "Moonlight Ride",     albums[1], artists[0], assets["moonlight_drive.mp3"],  "3:09" ],
  [ "Across The Space",   albums[1], artists[0], assets["across_the_space.mp3"], "3:28" ],
  [ "Along The Ocean",    albums[1], artists[0], assets["along_the_ocean.mp3"],  "2:51" ],
  [ "Unleashed",          albums[1], artists[0], assets["unleashed.mp3"],        "3:38" ],
  [ "Evening Cruise",     albums[1], artists[0], assets["to_the_sea.mp3"],       "3:52" ],
  [ "Independence Day",   albums[1], artists[0], assets["independence_day.mp3"], "2:26" ]
)

albums.forEach((album) => {
  album.list = Object.freeze(songs.filter((song) => song.album === album))
  Object.freeze(album)
})

const populate_list = object_tools.populate(
    "label",                                      "icon",    "list"
)

const playlists = populate_list(
  [ "Summer Hits",                                undefined, Object.freeze(songs.slice())     ],
  [ "Mellow Mornings",                            undefined, Object.freeze(songs.slice())     ]
)

const genres = populate_list(
  [ "Rock",                                       undefined, Object.freeze(songs.slice(0, 7)) ],
  [ "Pop",                                        undefined, Object.freeze(songs.slice(7))    ]
)

const overview = populate_list(
  [ "Media_USB_List_Overview_Category_Files",     undefined, Object.freeze(songs.slice())     ],
  [ "Media_USB_List_Overview_Category_Playlists", undefined, playlists                        ], 
  [ "Media_USB_List_Overview_Category_Artists",   undefined, artists                          ],
  [ "Media_USB_List_Overview_Category_Albums",    undefined, albums                           ],
  [ "Media_USB_List_Overview_Category_Genres",    undefined, genres                           ],
  [ "Media_USB_List_Overview_Category_Track",     undefined, Object.freeze(songs.slice())     ]
)

export default overview
