export const SET_ACTIVE_SUBTAB_ITEM = "SET_ACTIVE_SUBTAB_ITEM"

export type ReduxInitAction = { type: "@@INIT" }

export interface activeItem {
  itemName: string
  subTabBar: string
}

export type setActiveSubTabItemAction = {
  type: typeof SET_ACTIVE_SUBTAB_ITEM
  payload: activeItem
}

export type SubTabBarState = {
  activeItems: activeItem[]
}

export type SubTabItemSettingType = setActiveSubTabItemAction
