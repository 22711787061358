import data from "./data"
import {
  UPDATE_PHONE_SEARCH,
  SET_BREAKDOWN_CALL,
  UpdatePhoneSearchAction,
  SetBreakdownCallAction,
  SetOngoingCall,
  SET_ONGOING_CALL,
  ADD_FAVORITE_PHONE_NUMBER,
  AddFavoritePhoneNumber,
  PhoneScreenState
} from "./types"

const phoneSearchInitialState: boolean = false

export function phoneSearchReducer(state = phoneSearchInitialState, action: UpdatePhoneSearchAction) {
  switch (action.type) {
    case UPDATE_PHONE_SEARCH:
      return action.active
    default:
      return state
  }
}

const breakdown_phone_number = Object.freeze({
  number: "",
  contact: undefined,
  kind: { label: "" }
})

const phoneScreenInitialState: PhoneScreenState = {
  breakdownCallActive: false,
  contacts: Object.freeze(data.contacts.slice()),
  favorites: Object.freeze(data.favorites.slice()),
  fav_candidates: Object.freeze([ data.contacts[3].phone_numbers[0] ]),
  calls: Object.freeze(data.calls.slice()),
  ongoing: undefined
}

export function phoneScreenReducer(
  state: PhoneScreenState = phoneScreenInitialState,
  action: SetBreakdownCallAction | AddFavoritePhoneNumber | SetOngoingCall
): PhoneScreenState {
  switch (action.type) {
  case SET_ONGOING_CALL:
    return {
      ...state,
      ongoing: action.ongoing || undefined,
      breakdownCallActive: action.ongoing ? state.breakdownCallActive : false
    }

  case SET_BREAKDOWN_CALL:
    return {
      ...state,
      breakdownCallActive: action.active,
      ongoing: action.active ? breakdown_phone_number : undefined
    }

  case ADD_FAVORITE_PHONE_NUMBER:
    return {
      ...state,
      favorites: state.favorites.concat([ action.number ]),
      fav_candidates: state.fav_candidates.filter((phoneNumber) => phoneNumber !== action.number)
    }
  default:
    return state
  }
}
