export const SET_ACCOUNT_LOADING = "SET_ACCOUNT_LOADING"
export const PRESELECT_ACCOUNT = "PRESELECT_ACCOUNT"
export const SELECT_ACCOUNT = "SELECT_ACCOUNT"
export const CREATE_ACCOUNT = "CREATE_ACCOUNT"
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT"

export type Account = {
  [index: string]: any
  label?: string
  name: string
  kind?: string
  useCode: boolean
  autoLogIn: boolean
}

export type CreateAccount = {
  type: typeof CREATE_ACCOUNT,
  account: Account
}

export type PreselectAccount = {
  type: typeof PRESELECT_ACCOUNT,
  account: Account
}

export type SelectAccount = {
  type: typeof SELECT_ACCOUNT
}

export type SetAccountLoading = {
  type: typeof SET_ACCOUNT_LOADING
  payload: boolean
}

export type UpdateAccount = {
  type: typeof UPDATE_ACCOUNT,
  account: Account,
  data: { [index: string]: any }
}

export type AccountsState = {
  list: Account[]
  selected: Account
  toBeSelected: Account | undefined
  loading: boolean
}

export type Action =
  | SetAccountLoading
  | PreselectAccount
  | SelectAccount
  | CreateAccount
  | UpdateAccount
