import React from "react"

import I18nLabel from "../I18nLabel/I18nLabel"

interface INonI18nLabel {
  text: string | TemplateStringsArray
  className?: string
  id?: string
}

const NonI18nLabel: React.FC<INonI18nLabel> = (props: INonI18nLabel) => (
  <div className="non-I18n-label-wrapper">
    <I18nLabel text={props.text} className={props.className} id={props.id} />
  </div>
)

export default NonI18nLabel
