import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"

export const UPDATE_TIME = "UPDATE_TIME"
export const UPDATE_VOLUME = "UPDATE_VOLUME"

export type ReduxInitAction = { type: "@@INIT" }

export type updateTimeAction = {
  type: typeof UPDATE_TIME
  duration: number
  currentTime: number
  progressTime: number
}

export type updateVolumeAction = {
  type: typeof UPDATE_VOLUME
  volume: number
}

export type titlePlayTimeState = {
  duration: number
  currentPlayTime: number
  volume: number
  currentTime: number
  progressTime: number
}

export type Action = ReduxInitAction | updateTimeAction | updateVolumeAction

export type Store = ReduxStore<titlePlayTimeState, Action>

export type Dispatch = ReduxDispatch<Action>

export type PlayTimeActionType = updateTimeAction | updateVolumeAction
