import React from "react"
import ClassNames from "classnames"

import icon from "@pag/center/components/icon/icons"
import units from "@pag/center/components/unit/units"
import { UnitKind } from "@pag/center/components/unit/types"
import Icon from "@pag/center/components/icon/Icon"
import NonI18nLabel from "@pag/center/components/nonI18nLabel/NonI18nLabel"
import DistanceDestination from "@pag/center/views/navigationScreen/mapMain/navigationOverlay/poi/distanceDestination/DistanceDestination"

import { LocationObject } from "../types"

import "./mapTooltipAddressItem.scss"

interface IMapTooltipAddressItem {
  className?: string
  address: LocationObject
  distance?: number
  onPress: any
}

const MapTooltipAddressItem: React.FC<IMapTooltipAddressItem> = (props: IMapTooltipAddressItem) => {
  const formatDistance = units.useUnitFormat(UnitKind.Distance)

  return (
    <div className={ClassNames("map-tooltip-address-item-wrapper", props.className)} onClick={props.onPress}>
      <NonI18nLabel
        className="address__text"
        text={props.address.addressLineFirst} // TODO:
      />
      <div className="rrd">
        {props.distance === undefined ? (
          <Icon iconClass="rrd__loading-spinner state-rotate-right" iconType={icon.processing} />
        ) : (
          <DistanceDestination formattedDistance={formatDistance(props.distance)} />
        )}
      </div>
    </div>
  )
}

export default MapTooltipAddressItem
