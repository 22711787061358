export const availableLanguages: string[] = [
  "de_DE",
  "en_GB",
  "en_US",
  "es_ES",
  "zh_CN",
  "fr_FR",
  "it_IT",
  "ja_JP",
  "ko_KR",
  "nl_NL",
  "pt_BR",
  "ru_RU",
  "zh_TW"
]
