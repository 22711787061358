import React, { useState } from "react"
import cx from "classnames"

import Icon from "./CopyIcon"

//import "@pag/center/components/speller/key/key.scss"

interface IKey {
  shiftActive?: boolean
  keyContent_text?: string
  keyContent_icon?: string
  secondaryLabel?: string
  secondaryLabel_icon?: string
  keyWidth: number
  keyType: EKeyType
  clickHandler?: () => void
  disabled?: boolean
  className?: string
}

export enum EKeyType {
  Character,
  Icon,
  Blank,
  NumLock,
  Space,
  Ok
}

const CopyKey: React.FunctionComponent<IKey> = (props: IKey) => {
  const [pressed, setPressed] = useState(false)

  function keyType(): string {
    switch (props.keyType) {
      case EKeyType.Character:
        return "key--character"
      case EKeyType.Icon:
        return "key--icon"
      case EKeyType.Blank:
        return "key--blank"
      case EKeyType.NumLock:
        return "key--numlock"
      case EKeyType.Space:
        return "key--space"
      case EKeyType.Ok:
        return "key--ok"
      default:
        return ""
    }
  }
  const keyWidthClass: string = "key__width-" + props.keyWidth

  const keyContent = () => {
    if (props.keyContent_text) {
      if (props.keyType === EKeyType.Space) {
        return (
          <div className={cx("key__content-container", "key__4-rows-layout", { "state-disabled": props.disabled })}>
            {props.keyContent_text}
          </div>
        )
      } else {
        return (
          <>
            <div className={cx("key__content-container", "key__4-rows-layout", { "state-disabled": props.disabled })}>
              {props.keyContent_text}
            </div>
            {props.secondaryLabel && <div className="key__secondary-label">{props.secondaryLabel}</div>}
            {props.secondaryLabel_icon && (
              <Icon
                iconClass={cx("key__secondary-icon", { "state-disabled": props.disabled })}
                iconType={props.secondaryLabel_icon}
              />
            )}
          </>
        )
      }
    } else if (props.keyContent_icon) {
      return (
        <Icon
          iconClass={cx("key__primary-icon", { "state-disabled": props.disabled })}
          iconType={props.keyContent_icon}
        />
      )
    }
  }

  const handleClickFromParent = props.clickHandler ? props.clickHandler : () => {}

  function clickHandler(clickHandlerProps: () => void) {
    clickHandlerProps()
    setPressed(true)
    setTimeout(() => {
      setPressed(false)
    }, 500)
  }

  return (
    <div
      className={cx("key-wrapper", keyType(), keyWidthClass, props.className || "", { "state-pressed": pressed })}
      onMouseDown={() => !props.disabled && setPressed(!pressed)}
      onMouseUp={() => !props.disabled && setPressed(!pressed)}
      onClick={() => !props.disabled && clickHandler(handleClickFromParent)}
    >
      <div
        className={cx("key__container", { "state-disabled": props.disabled }, { "shift-active": props.shiftActive })}
      >
        <div className="key__animation-container">
          {/* <div className="animated-bg--growing-circle"></div> */}
          <div className="animated-bg--pressed"></div>
        </div>
        {keyContent()}
      </div>
    </div>
  )
}

export default CopyKey
