export const SET_WELCOME_WIZARD_VISIBLE = "SET_WELCOME_WIZARD_VISIBLE"
export const SET_SPIN_ENTRY_POPUP_VISIBLE = "SET_SPIN_ENTRY_POPUP_VISIBLE"
export const SET_SPIN_ENTERED_CORRECTLY = "SET_SPIN_ENTERED_CORRECTLY"
export const SET_SHOW_CLOSE_POPUP = "SET_SHOW_CLOSE_POPUP"

export type WelcomeWizardState = {
  visible: boolean
  spinEntryPopupVisible: boolean
  spinEnteredCorrectly: boolean
  showClosePopup: boolean
}

export type setWelcomeWizardVisibleAction = {
  type: typeof SET_WELCOME_WIZARD_VISIBLE
  payload: boolean
}

export type setSpinEntryPopupVisibleAction = {
  type: typeof SET_SPIN_ENTRY_POPUP_VISIBLE
  payload: boolean
}

export type setSpinEnteredCorrectlyAction = {
  type: typeof SET_SPIN_ENTERED_CORRECTLY
  payload: boolean
}

export type setShowClosePopupAction = {
  type: typeof SET_SHOW_CLOSE_POPUP
  payload: boolean
}

export type WelcomeWizardActionType =
  | setWelcomeWizardVisibleAction
  | setSpinEntryPopupVisibleAction
  | setSpinEnteredCorrectlyAction
  | setShowClosePopupAction
