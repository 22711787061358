import React from "react"
import cx from "classnames"

import "./Copy.scss";
//import "@pag/center/components/icon/icon.scss"

interface IIcon {
    iconType?: string
    iconClass?: string
    iconUrl?: string
    ref?: React.RefObject<HTMLImageElement>
    isPressed?: boolean
    style?: any
    disabled?: boolean
    onPress?: any
}

const CopyIcon: React.FunctionComponent<IIcon> = (props) => {
    const showImg = () => {
        if (props.iconUrl) return <img alt="" className="icon__url" src={props.iconUrl}></img>
    }

    return (
        <div
            onClick={props.onPress !== undefined ? props.onPress : () => { }}
            style={props.style}
            className={cx(
                "icon",
                "img-connection",
                props.iconType,
                props.iconClass,
                { "state-pressed": props.isPressed },
                { "state-disabled": props.disabled }
            )}
        >
            {showImg()}
        </div>
    )
}

export default CopyIcon
