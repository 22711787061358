enum SliderTypeEnum {
  Default,
  Percentage,
  KmPerHour,
  Temperature,
  WithoutUnitOfMeasure,
  Color,
  Charge
}

export interface SimpleColor {
  red: number
  green: number
  blue: number
}

export interface NamedColor extends SimpleColor {
  name: string
}

export interface ProgressBarSegment {
  color: NamedColor
}

export interface ColorSliderSegment extends ProgressBarSegment {
  index: number
}

export enum PbEmitMode {
  drag = 1,
  release = 2,
  releaseWithPreviewOnDrag = 3
}

export enum PbTypes {
  hardwareControlled = "HARDWARE_CONTROLLED" as any,
  touchControlled = "TOUCH_CONTROLLED" as any
}

export type BaseDirections = "top" | "right" | "bottom" | "left"

export default SliderTypeEnum
