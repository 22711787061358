import object_tools from "@pag/utils/object-tools"
import { List_Entry } from "@pag/center/components/player/audioPlayer/types"

import octava_cover from "@pag/center/assets/mp3/radio/covers/octava.png"
import pstudio_cover from "@pag/center/assets/mp3/radio/covers/pstudio.png"
import studio_cover from "@pag/center/assets/mp3/radio/covers/studio.png"
import urban_cover from "@pag/center/assets/mp3/radio/covers/urban.png"
import slm_cover from "@pag/center/assets/mp3/radio/covers/slmradio3.png"

import qpkt_cover from "@pag/center/assets/mp3/radio/covers/qpkt.png"
import hnk_cover from "@pag/center/assets/mp3/radio/covers/hnk.png"
import popsummer_cover from "@pag/center/assets/mp3/radio/covers/popsummer.png"
import synthwave_cover from "@pag/center/assets/mp3/radio/covers/synthwave.png"
import talk_cover from "@pag/center/assets/mp3/radio/covers/talk.png"

const importAll = (r: any) => r.keys().reduce(
  (songs: { [index: string]: any }, item: any) => {
    songs[item.replace("./", "")] = r(item)
    return songs
  },
  Object.create(null)
)
const assets = importAll(require.context("@pag/center/assets/mp3/radio", false, /\.(mp3)$/))

const populate_stations = object_tools.populate(
  "label",          "icon",         "source",                "genre"
)

const radio: Readonly<List_Entry[]> = populate_stations(
  [ "Okkitava",     octava_cover,    assets["octava.mp3"],    "Classic"         ],
  [ "B Studio",     pstudio_cover,   assets["pstudio.mp3"],   "Electronic"      ],
  [ "Studio",       studio_cover,    assets["studio.mp3"],    "Rock"            ],
  [ "Urban Studio", urban_cover,     assets["urban.mp3"],     "Hip Hop / Latin" ],
  [ "SLM Radio 3",  slm_cover,       assets["slm_3.mp3"],     "Contemporary"    ]
)

const onlineRadio: Readonly<List_Entry[]> = populate_stations(
  [ "Radio HNK",    hnk_cover,       assets["hnk.mp3"],       "Contemporary"       ],
  [ "BPKT",         qpkt_cover,      assets["qpkt.mp3"],      "International Hits" ],
  [ "Synthwave",    synthwave_cover, assets["synthwave.mp3"], "Retro Music"        ],
  [ "Pop Summer",   popsummer_cover, assets["popsummer.mp3"], "Pop"                ],
  [ "Talk",         talk_cover,      assets["talk.mp3"],      "Podcasts"           ]
)

const favorites: Readonly<List_Entry[]> = Object.freeze([
  onlineRadio[0]
])

export default Object.freeze({
  radio,
  onlineRadio,
  favorites
})
