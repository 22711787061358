import {
  WelcomeWizardState,
  WelcomeWizardActionType,
  SET_WELCOME_WIZARD_VISIBLE,
  SET_SPIN_ENTRY_POPUP_VISIBLE,
  SET_SPIN_ENTERED_CORRECTLY,
  SET_SHOW_CLOSE_POPUP
} from "./types"

const initialWelcomeWizardState: WelcomeWizardState = {
  visible: false,
  spinEntryPopupVisible: false,
  spinEnteredCorrectly: false,
  showClosePopup: true
}

export default Object.freeze(function welcomeWizardReducer(
  state = initialWelcomeWizardState,
  action: WelcomeWizardActionType
): WelcomeWizardState {
  switch (action.type) {
    case SET_WELCOME_WIZARD_VISIBLE:
      return {
        ...state,
        // if exiting the welcome wizard while spin-entry-popup is visibile close it as well
        spinEntryPopupVisible: !action.payload ? false : state.spinEntryPopupVisible,
        // after exiting the welcome wizard, user should enter spin again to verify not in secure-mode
        spinEnteredCorrectly: !action.payload ? false : state.spinEnteredCorrectly,
        visible: action.payload
      }
    case SET_SPIN_ENTRY_POPUP_VISIBLE:
      return {
        ...state,
        spinEntryPopupVisible: action.payload
      }
    case SET_SPIN_ENTERED_CORRECTLY:
      return {
        ...state,
        spinEnteredCorrectly: action.payload
      }
    case SET_SHOW_CLOSE_POPUP:
      return {
        ...state,
        showClosePopup: action.payload
      }
    default:
      return state
  }
})
