import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"

export const SET_NAVIGATION_MODE = "SET_NAVIGATION_MODE"
export const SAVE_KEY_INPUT = "SAVE_KEY_INPUT"
export const DELETE_INPUT = "DELETE_INPUT"

export type ReduxInitAction = { type: "@@INIT" }

export type setNavigationModeAction = {
  type: typeof SET_NAVIGATION_MODE
  mode: string
}

export type NavigationModeState = {
  mode: string
}

export type saveSearchInputAction = {
  type: typeof SAVE_KEY_INPUT
  payload: string
}

export type deleteSearchInputAction = {
  type: typeof DELETE_INPUT
}

export type DestInputInputState = {
  input: string
}

export type NavigationModeActionType = setNavigationModeAction
export type DestInputActionType = saveSearchInputAction | deleteSearchInputAction

export type Action = ReduxInitAction | NavigationModeActionType

export type Store = ReduxStore<NavigationModeState, Action>

export type Dispatch = ReduxDispatch<Action>
