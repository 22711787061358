export enum EHomelinkItemTypeEnum {
  Door,
  DoorWithoutSync,
  Plus,
  None
}

export type HomeLinkItem = {
  id: string
  type: EHomelinkItemTypeEnum
  data?: { [key: string]: string }
  slidOutVisible?: boolean
  handler?: () => void
}

export const profiles: HomeLinkItem[] = [
  {
    id: "HomeLink_Item_Holiday_Home_Gate",
    type: EHomelinkItemTypeEnum.Door,
    data: {
      name: "HomeLink_Item_Holiday_Home_Gate",
      address: "Porscheplatz 1, 70435 Stuttgart"
    },
    slidOutVisible: false
  },
  {
    id: "HomeLink_Item_Underground_Carpark_Office",
    type: EHomelinkItemTypeEnum.Door,
    data: {
      name: "HomeLink_Item_Underground_Carpark_Office"
      // address: "Mercedesstraße 69, 70372 Stuttgart"
    },
    slidOutVisible: false
  },
  {
    id: "HomeLink_Item_Parkallee",
    type: EHomelinkItemTypeEnum.Door,
    data: {
      name: "HomeLink_Item_Parkallee",
      address: "HomeLink_Item_Parkallee"
    },
    slidOutVisible: false
  }
]
