import { ECarDriveMainScreenItemNames } from "@pag/center/views/carScreen/enums"
import { CarDriveModeOption } from "@pag/center/components/myScreenTiles/carDriveModeTile/types"

export const SET_ACTIVE_DRIVE_MAIN_SCREEN_LIST_ITEM = "SET_ACTIVE_DRIVE_MAIN_SCREEN_LIST_ITEM"
export const SET_DRIVE_MODE = "SET_DRIVE_MODE"

export type DriveMainScreenListState = ECarDriveMainScreenItemNames

export type ReduxInitAction = { type: "@@INIT" }

export type SetActiveDriveMainScreenListItemAction = {
  type: typeof SET_ACTIVE_DRIVE_MAIN_SCREEN_LIST_ITEM
  item: ECarDriveMainScreenItemNames
}

export type SetDriveModeAction = {
  type: typeof SET_DRIVE_MODE
  mode: string
}

type DriveMenuListItem = {
  text: string
  iconType: string
  video: any
  slideOutItems: CarDriveModeOption[]
}

export type DriveMenuListItems = { [key: string]: DriveMenuListItem }
