import { Store as ReduxStore, Dispatch as ReduxDispatch } from 'redux';

export const UPDATE_PLAYBACK_TIME = 'UPDATE_PLAYBACK_TIME'

export type ReduxInitAction = { type: '@@INIT' };

export type updatePlaybackTime = {
    type: typeof UPDATE_PLAYBACK_TIME
    currentPlaybackTime: number
}

export type playbackFastForward = {
    currentPlaybackTime: number
}

export type Action = ReduxInitAction | updatePlaybackTime;

export type Store = ReduxStore<playbackFastForward, Action>;

export type Dispatch = ReduxDispatch<Action>;

export type PlaybackTimeActionType = updatePlaybackTime