import objtools from "@pag/utils/object-tools";
import icon from "@pag/center/components/icon/icons";
import { ListItem, Value } from "./types";
import { SubTabBarItemRO } from "@pag/center/components/subTabBarRouting/types"
import { ECarSubTabItemNames } from "@pag/center/views/carScreen/enums"

const populate = objtools.populate;
const populate_label = populate("label");
const populate_list_item = populate("label", "desc", "icon", "value");

// SUB TAB BAR /////////////////////////////////////////////////////////////////

const tabs = objtools.populate(
    "text",                                            "itemName"
)(
  [ "CarRootRouter_SubTabBar_pag3-i18n-label_Drive",   ECarSubTabItemNames.DRIVE   ],
  [ "CarRootRouter_SubTabBar_pag3-i18n-label_Assist",  ECarSubTabItemNames.ASSIST  ],
  [ "CarRootRouter_SubTabBar_pag3-i18n-label_Trip",    ECarSubTabItemNames.TRIP    ],
  [ "CarRootRouter_SubTabBar_pag3-i18n-label_Comfort", ECarSubTabItemNames.COMFORT ]
) as SubTabBarItemRO<ECarSubTabItemNames>[]

const [
  drive_tab,
  assist_tab,
  trip_tab,
  comfort_tab
] = tabs;

const new_tabs = populate_label(
  [ "CarRootRouter_SubTabBar_pag3-i18n-label_Drive"   ],
  [ "CarRootRouter_SubTabBar_pag3-i18n-label_Assist"  ],
  [ "CarRootRouter_SubTabBar_pag3-i18n-label_Trip"    ],
  [ "CarRootRouter_SubTabBar_pag3-i18n-label_Comfort" ]
);

const [
    ,
    ,
    ,
    new_comfort_tab
] = new_tabs;

// DRIVE VALUES ////////////////////////////////////////////////////////////////

const drive_modes: Readonly<Value[]> = populate_label(
    [ "Car_Drive_DriveMode_SlideOut_EPower"      ],
    [ "Car_Drive_DriveMode_SlideOut_Gravel"      ],
    [ "Car_Drive_DriveMode_SlideOut_Hybrid"      ],
    [ "Car_Drive_DriveMode_SlideOut_Individual"  ],
    [ "Car_Drive_DriveMode_SlideOut_Normal"      ],
    [ "Car_Drive_DriveMode_SlideOut_Offroad"     ],
    [ "Car_Drive_DriveMode_SlideOut_Race"        ],
    [ "Car_Drive_DriveMode_SlideOut_Rally"       ],
    [ "Car_Drive_DriveMode_SlideOut_Range"       ],
    [ "Car_Drive_DriveMode_SlideOut_Sport"       ],
    [ "Car_Drive_DriveMode_SlideOut_SportPlus"   ],
    [ "Car_Drive_DriveMode_SlideOut_Track"       ],
    [ "Car_Drive_DriveMode_SlideOut_Wet"         ]
);

const [
    drive_mode_epower,
    drive_mode_gravel,
    drive_mode_hybrid,
    drive_mode_individual,
    drive_mode_normal,
    drive_mode_offroad,
    ,
    ,
    ,
    drive_mode_sport,
    drive_mode_sport_plus,
    ,
    ,
] = drive_modes

const chassis_values: Readonly<Value[]> = populate_label(
    [ "Car_Drive_Chassis_SlideOut_Normal"        ],
    [ "Car_Drive_Chassis_SlideOut_Sport"         ],
    [ "Car_Drive_Chassis_SlideOut_SportPlus"     ]
);

const [
  chassis_normal,
  chassis_sport,
  chassis_sport_plus
] = chassis_values;

const level_values: Readonly<Value[]> = populate_label(
    [ "Car_Drive_Level_SlideOut_High"            ],
    [ "Car_Drive_Level_SlideOut_OffroadII"       ],
    [ "Car_Drive_Level_SlideOut_OffroadI"        ],
    [ "Car_Drive_Level_SlideOut_Lift"            ],
    [ "Car_Drive_Level_SlideOut_Normal"          ],
    [ "Car_Drive_Level_SlideOut_Lowered"         ],
    [ "Car_Drive_Level_SlideOut_Low"             ]
);

const [
    level_high,
    ,
    ,
    level_lift,
    level_normal,
    level_lowered,
    level_low
] = level_values;

const terrain_values: Readonly<Value[]> = populate_label(
    [ "Car_Drive_Terrain_SlideOut_Gravel"        ],
    [ "Car_Drive_Terrain_SlideOut_Mud"           ],
    [ "Car_Drive_Terrain_SlideOut_Sand"          ],
    [ "Car_Drive_Terrain_SlideOut_Rocks"         ]
);

const hybrid_modes: Readonly<Value[]> = populate_label(
    [ "Car_Drive_HybridMode_SlideOut_HybridAuto" ],
    [ "Car_Drive_HybridMode_SlideOut_EHold"      ],
    [ "Car_Drive_HybridMode_SlideOut_ECharge"    ]
);

const recuperation_values: Readonly<Value[]> = populate_label(
    [ "Car_Drive_Recuperation_SlideOut_An"          ],
    [ "Car_Drive_Recuperation_SlideOut_Aus"         ],
    [ "Car_Drive_Recuperation_SlideOut_Automatisch" ],
    // [ "Car_Drive_Recuperation_SlideOut_Hoch"        ],
    // [ "Car_Drive_Recuperation_SlideOut_Manuell"     ],
    // [ "Car_Drive_Recuperation_SlideOut_Mittel"      ],
    // [ "Car_Drive_Recuperation_SlideOut_Niedrig"     ]
);

const [
    recuperation_on,
    recuperation_off
] = recuperation_values

const e_sound_values: Readonly<Value[]> = populate_label(
    // [ "Car_Drive_ESound_SlideOut_Normal"    ],
    [ "Car_Drive_ESound_SlideOut_Off"       ],
    // [ "Car_Drive_ESound_SlideOut_On"        ],
    [ "Car_Drive_ESound_SlideOut_Sport"     ],
    [ "Car_Drive_ESound_SlideOut_SportPlus" ]
);

// ASSIST VALUES ///////////////////////////////////////////////////////////////

const assist_modes = populate_label(
    [ "Car_Assistance_AssistMode_AssistedDrive"      ],
    [ "Car_Assistance_AssistMode_HillDescentControl" ],
    [ "Car_Assistance_AssistMode_HoldSpeed"          ],
    [ "Car_Assistance_AssistMode_Limited"            ],
    [ "Car_Assistance_AssistMode_Off"                ]
)
const [
    assist_mode_ad,
    assist_mode_hill,
    assist_mode_hold,
    assist_mode_limit,
    assist_mode_off,
] = assist_modes

const assist_lane_departure_values = populate_label(
    [ "Car_Assistance_LaneDeparture_Loud"   ],
    [ "Car_Assistance_LaneDeparture_Medium" ],
    [ "Car_Assistance_LaneDeparture_Quiet"  ],
    [ "Car_Assistance_LaneDeparture_Off"    ]
)

const assist_distance_warning_values = populate_label(
    [ "Car_Assistance_DistanceWarning_Off"    ],
    [ "Car_Assistance_DistanceWarning_Early"  ],
    [ "Car_Assistance_DistanceWarning_Medium" ],
    [ "Car_Assistance_DistanceWarning_Late"   ]
) 

// COMFORT VALUES //////////////////////////////////////////////////////////////

const comfort_color_values = populate(
      "label",                                        "id"
)(
    [ "Car_Comfort_AmbientLight_Color_Kaltweiss",     "cool-white"  ],
    [ "Car_Comfort_AmbientLight_Color_Warmweiss",     "warm-white"  ],
    [ "Car_Comfort_AmbientLight_Color_Tieforange",    "deep-orange" ],
    [ "Car_Comfort_AmbientLight_Color_Mohnrot",       "poppy"       ],
    [ "Car_Comfort_AmbientLight_Color_Topasblau",     "topaz-blue"  ],
    [ "Car_Comfort_AmbientLight_Color_Atlantis",      "atlantis"    ],
    [ "Car_Comfort_AmbientLight_Color_Zitronengruen", "lime"        ],
    [ "Car_Comfort_AmbientLight_Color_Dynamisch",     "dynamic"     ]
)

const [
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    comfort_color_dynamic
] = comfort_color_values

const comfort_massage_intensity_values = populate_label(
    [ "Car_Comfort_MassageIntensity_Max"    ],
    [ "Car_Comfort_MassageIntensity_Strong" ],
    [ "Car_Comfort_MassageIntensity_Medium" ],
    [ "Car_Comfort_MassageIntensity_Soft"   ],
    [ "Car_Comfort_MassageIntensity_Min"    ]
)

const comfort_massage_program_values = populate_label(
    [ "Car_Comfort_MassageProgram_Off"      ],
    [ "Car_Comfort_MassageProgram_Lordosis" ],
    [ "Car_Comfort_MassageProgram_Shiatsu"  ],
    [ "Car_Comfort_MassageProgram_Shoulder" ],
    [ "Car_Comfort_MassageProgram_Stretch"  ],
    [ "Car_Comfort_MassageProgram_Wave"     ]
)

// LIST ELEMENTS ///////////////////////////////////////////////////////////////

// @NOTE(kirill): List elements are a different beast as we need different list

const drive_list_items: Readonly<ListItem[]> = populate_list_item(
    [ "Car_Drive_DriveMode_ListItem",        undefined, icon.car,             drive_modes         ],
    [ "Car_Drive_Chassis_ListItem",          undefined, icon.pasm,            chassis_values      ],
    [ "Car_Drive_Level_ListItem",            undefined, icon.car_niveau,      level_values        ],
    [ "Car_Drive_Terrain_ListItem",          undefined, icon.car_suv_offroad, terrain_values      ],
    [ "Car_Drive_HybridMode_ListItem",       undefined, icon.charging_active, hybrid_modes        ],
    [ "Car_Drive_Recuperation_ListItem",     undefined, icon.recuperation,    recuperation_values ],
    [ "Car_Drive_ESound_ListItem",           undefined, icon.e_sound,         e_sound_values      ],
    [ "Car_Drive_ChassisOffroad_ListItem",   undefined, icon.pasm                                 ],
    [ "Car_Drive_ChassisRange_ListItem",     undefined, icon.pasm                                 ],
    [ "Car_Drive_ChassisGravel_ListItem",    undefined, icon.pasm                                 ],
    [ "Car_Drive_ChassisWet_ListItem",       undefined, icon.pasm                                 ],
    [ "Car_Drive_ShowSurroundings_ListItem", undefined, icon.car_top_view                         ]
)

const [
    drive_mode_list_item,
    chassis_list_item,
    level_list_item,
    terrain_list_item,
    hybrid_list_item,
    recuperation_list_item,
    e_sound_list_item,
] = drive_list_items

const assist_list_items = populate_list_item(
    [ "Car_Assistance_AssistMode",                  undefined,                                      icon.tempomat                                                ],
    [ "Car_Assistance_SpeedLimitAdaption_ListItem", "Car_Assistance_SpeedLimitAdaption_SecondLine", icon.traffic_sign_recognition                                ],
    [ "Car_Assistance_RouteTopography_ListItem",    "Car_Assistance_RouteTopography_SecondLine",    icon.predictive_speed                                        ],
    [ "Car_Assistance_TrackGuiding_ListItem",       "Cas_Assistance_TrackGuiding_SecondLine",       icon.steering_wheel                                          ],
    [ "Car_Assistance_SpeedWarning",                "Car_Assistance_SpeedWarning_SecondLine",       icon.traffic_sign_recognition                                ],
    [ "Car_Assistance_WarnAndBrake",                "Car_Assistance_WarnAndBrake_SecondLine",       icon.awv_prewarning                                          ],
    [ "Car_Assistance_LaneKeeping",                 "Car_Assistance_LaneKeeping_SecondLine",        icon.lane_departure_warning                                  ],
    [ "Car_Assistance_LaneDeparture",               "Car_Assistance_LaneDeparture_SecondLine",      icon.lane_departure_warning,  assist_lane_departure_values   ],
    [ "Car_Assistance_LaneChange",                  "Car_Assistance_LaneChange_SecondLine",         icon.lane_change_assist                                      ],
    [ "Car_Assistance_IntersectionAssist",          "Car_Assistance_IntersectionAssist_SecondLine", icon.cross_over_assistant                                    ],
    [ "Car_Assistance_DigitalMatrixBeam",           "Car_Assistance_DigitalMatrixBeam_SecondLine",  icon.beam_matrix_light                                       ],
    [ "Car_Assistance_DistanceWarning",             "Car_Assistance_DistanceWarning_SecondLine",    icon.awv_prewarning,          assist_distance_warning_values ],
    [ "Car_Assistance_EmergencyStop",               "Car_Assistance_EmergencyStop_SecondLine",      icon.emergency_assist                                        ],
    [ "Car_Assistance_PreCrash",                    "Car_Assistance_PreCrash_SecondLine",           icon.active_safe                                             ],
    [ "Car_Assistance_DrowsinessWarning",           "Car_Assistance_DrowsinessWarning_SecondLine",  icon.drowsiness_warning                                      ],
    [ "Car_Assistance_RiskRadar",                   "Car_Assistance_RiskRadar_SecondLine",          icon.information                                             ],
    [ "Car_Assistance_NightVision",                 "Car_Assistance_NightVision_SecondLine",        icon.night_vision                                            ],
    [ "Car_Assistance_TrafficLightInfo",            "Car_Assistance_TrafficLightInfo_SecondLine"                                                                 ],
    [ "Car_Assistance_AttentionAssist",             "Car_Assistance_AttentionAssist_SecondLine"                                                                  ],
    [ "Car_Assistance_WetDetection",                "Car_Assistance_WetDetection_SecondLine"                                                                     ],
    [ "Car_Assistance_AdditionalFunctions"                                                                                                                       ]
);

const digital_matrix_beam_list_item = assist_list_items[10]
const additional_functions_list_item = assist_list_items[assist_list_items.length - 1];

const comfort_list_items = populate_list_item(
    [ "Car_Comfort_AmbientLight_ListItem",                  undefined,                                 icon.ambient_light                                               ],
    [ "Car_Comfort_DriverSeat_ListItem",                    undefined,                                 icon.seat_comfort_left                                           ],
    [ "Car_Comfort_CodriverSeat_ListItem",                  undefined,                                 icon.seat_comfort_codriver_left                                  ],
    [ "Car_Comfort_EasyEntry_ListItem",                     "Car_Comfort_EasyEntry_SecondLine",        icon.seat_easy_entry_left                                        ],
    [ "Car_Comfort_AdjustCodriverSeat_ListItem",            undefined,                                 icon.seat_config_codriver_left                                   ],
    [ "Car_Comfort_AmbientLight_OnOff_ListItem"                                                                                                                         ],
    [ "Car_Comfort_AmbientLight_Color_ListItem",            undefined,                                 undefined,                      comfort_color_values             ],
    [ "Car_Comfort_AmbientLight_Total_ListItem"                                                                                                                         ],
    [ "Car_Comfort_AmbientLight_Door_ListItem"                                                                                                                          ],
    [ "Car_Comfort_AmbientLight_Cupholder_ListItem"                                                                                                                     ],
    [ "Car_Comfort_AmbientLight_CenterConsole_ListItem"                                                                                                                 ],
    [ "Car_Comfort_AmbientLight_Fond_ListItem"                                                                                                                          ],
    [ "Car_Comfort_AmbientLight_Footwell_ListItem"                                                                                                                      ],
    [ "Car_Comfort_AmbientLight_TopRoof_ListItem"                                                                                                                       ],
    [ "Car_Comfort_BeltPretensioner_DriverSeat_ListItem",   "Car_Comfort_BeltPretensioner_SecondLine"                                                                   ],
    [ "Car_Comfort_BeltPretensioner_CodriverSeat_ListItem", "Car_Comfort_BeltPretensioner_SecondLine"                                                                   ],
    [ "Car_Comfort_Heating_DriverSeat_ListItem"                                                                                                                         ],
    [ "Car_Comfort_Heating_CodriverSeat_ListItem"                                                                                                                       ],
    [ "Car_Comfort_Ventilation_DriverSeat_ListItem"                                                                                                                     ],
    [ "Car_Comfort_Ventilation_CodriverSeat_ListItem"                                                                                                                   ],
    [ "Car_Comfort_MassageIntensity_DriverSeat_ListItem",   undefined,                                undefined,                       comfort_massage_intensity_values ],
    [ "Car_Comfort_MassageIntensity_CodriverSeat_ListItem", undefined,                                undefined,                       comfort_massage_intensity_values ],
    [ "Car_Comfort_MassageProgram_DriverSeat_ListItem",     undefined,                                undefined,                       comfort_massage_program_values   ],
    [ "Car_Comfort_MassageProgram_CodriverSeat_ListItem",   undefined,                                undefined,                       comfort_massage_program_values   ]
)

const [
    comfort_ambient_light_list_item,
    comfort_driver_seat_list_item,
    comfort_codriver_seat_list_item,
    ,
    comfort_adjust_codriver_seat_list_item,
    ,
    comfort_ambient_light_color_list_item,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    comfort_driver_seat_heating_list_item,
    comfort_codriver_seat_heating_list_item,
    comfort_driver_seat_ventilation_list_item,
    comfort_codriver_seat_ventilation_list_item,
    ,
    ,
    ,
    ,
    ,

] = comfort_list_items;

export default Object.freeze({
    tabs,
    drive_tab,
    assist_tab,
    trip_tab,
    comfort_tab,

    new_tabs,
    new_comfort_tab,
    
    drive_modes,
    drive_mode_epower,
    drive_mode_hybrid,
    drive_mode_normal,
    drive_mode_offroad,
    drive_mode_gravel,
    drive_mode_sport,
    drive_mode_sport_plus,
    drive_mode_individual,

    drive_list_items,
    drive_mode_list_item,
    chassis_list_item,
    level_list_item,
    terrain_list_item,
    hybrid_list_item,
    recuperation_list_item,
    e_sound_list_item,

    chassis_values,
    level_values,
    terrain_values,
    hybrid_modes,
    recuperation_values,
    e_sound_values,

    assist_modes,
    assist_mode_ad,
    assist_mode_hill,
    assist_mode_hold,
    assist_mode_limit,
    assist_mode_off,

    assist_list_items,
    assist_lane_departure_values,
    assist_distance_warning_values,

    chassis_normal,
    chassis_sport,
    chassis_sport_plus,

    level_high,
    level_lift,
    level_normal,
    level_low,
    level_lowered,

    recuperation_on,
    recuperation_off,

    additional_functions_list_item,
    digital_matrix_beam_list_item,

    comfort_color_values,
    comfort_color_dynamic,
    comfort_massage_intensity_values,
    comfort_massage_program_values,

    comfort_list_items,
    comfort_ambient_light_list_item,
    comfort_driver_seat_list_item,
    comfort_codriver_seat_list_item,
    comfort_ambient_light_color_list_item,
    comfort_driver_seat_heating_list_item,
    comfort_codriver_seat_heating_list_item,
    comfort_driver_seat_ventilation_list_item,
    comfort_codriver_seat_ventilation_list_item,
    comfort_adjust_codriver_seat_list_item
});
