import {
  HomeLinkState,
  HomeLinkAction,
  ADD_HOME_LINK_PROFILE,
  REMOVE_HOME_LINK_PROFILE,
  STORE_HOME_LINK_PROFILE_POSITION,
  DELETE_HOME_LINK_PROFILE_POSITION,
  UPDATE_HOME_LINK_PROFILE_NAME,
  SET_RENAME_VISIBLE
} from "./types"

import { profiles, HomeLinkItem } from "./homeLinkProfiles"

export const DEFAULT_HOME_LINK_PROFILE_ADDRESS: string = "Porscheplatz 1, 70435 Stuttgart"

const homeLinkInitialState: HomeLinkState = {
  renameVisible: false,
  profiles: profiles
}

export function homeLinkReducer(state = homeLinkInitialState, action: HomeLinkAction): HomeLinkState {
  switch (action.type) {

    case SET_RENAME_VISIBLE:
      return { ...state, renameVisible: action.visible }

    case ADD_HOME_LINK_PROFILE:
      return { ...state, profiles: [...state.profiles, action.profile] }

    case REMOVE_HOME_LINK_PROFILE:
      return { ...state, profiles: state.profiles.filter((profile) => action.profile !== profile) }

    case STORE_HOME_LINK_PROFILE_POSITION: {
      const index: number = state.profiles.findIndex((profile) => profile.id === action.id)
      const updatedProfile: HomeLinkItem = { ...state.profiles[index], data: {} }
      updatedProfile.data!.name = state.profiles[index].data!.name
      updatedProfile.data!.address = DEFAULT_HOME_LINK_PROFILE_ADDRESS

      return {
        ...state,
        profiles: [
          ...state.profiles.slice(0, index),
          updatedProfile,
          ...state.profiles.slice(index + 1, state.profiles.length + 1)
        ]
      }
    }

    case DELETE_HOME_LINK_PROFILE_POSITION: {
      const index: number = state.profiles.findIndex((profile) => profile.id === action.id)
      const updatedProfile: HomeLinkItem = { ...state.profiles[index], data: {} }
      updatedProfile.data!.name = state.profiles[index].data!.name

      return {
        ...state,
        profiles: [
          ...state.profiles.slice(0, index),
          updatedProfile,
          ...state.profiles.slice(index + 1, state.profiles.length + 1)
        ]
      }
    }

    case UPDATE_HOME_LINK_PROFILE_NAME:
      const index: number = state.profiles.findIndex((profile) => profile.id === action.id)
      const updatedProfile: HomeLinkItem = { ...state.profiles[index], data: {} }
      updatedProfile.data!.name = action.name
      updatedProfile.data!.address = state.profiles[index].data!.address

      return {
        ...state,
        profiles: [
          ...state.profiles.slice(0, index),
          updatedProfile,
          ...state.profiles.slice(index + 1, state.profiles.length + 1)
        ]
      }
      
    default:
      return state
  }
}
