import icon from "@pag/center/components/icon/icons"
import { MessageAction } from "./warn-message-quick-actions"

// import {BlockingRuleId} from '@mib3-pag/widgets';

export enum GenericMessageActionI18nEnum {
  QuickAction_Rueckruf = "Notification_Center_Quick_Actions_Call_Back",
  QuickAction_Anrufen = "Notification_Center_Quick_Actions_Call",
  QuickAction_Vorlesen = "Notification_Center_Quick_Actions_Read"
}

export enum GenericMessageQuickActions {
  Rueckruf = 100, // @NOTE(kirill): Should be more than the number of elements in WarnMessageQuickActions.
  Anrufen,
  Vorlesen
}

export const GENERIC_MESSAGE_QUICK_ACTIONS = new Map<number, MessageAction>([
  [
    GenericMessageQuickActions.Rueckruf,
    {
      id: GenericMessageQuickActions.Rueckruf,
      text: GenericMessageActionI18nEnum.QuickAction_Rueckruf,
      icon: icon.phone_receiver_green
    }
  ],
  [
    GenericMessageQuickActions.Anrufen,
    {
      id: GenericMessageQuickActions.Anrufen,
      text: GenericMessageActionI18nEnum.QuickAction_Anrufen,
      icon: icon.phone_receiver_green
    }
  ],
  [
    GenericMessageQuickActions.Vorlesen,
    {
      id: GenericMessageQuickActions.Vorlesen,
      text: GenericMessageActionI18nEnum.QuickAction_Vorlesen,
      icon: icon.readout
    }
  ]
])
