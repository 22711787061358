import { connect } from "react-redux"
import { Location } from "history"

import Tile from "./Tile"
import { AppState } from "../../../redux/reducer/index"
import { updateLocationHistory } from "@pag/center/views/centerMainScreen/actions"
import { setVisibleSettingsList } from "@pag/center/views/settingsScreen/actions"

const mapStateToProps = (state: AppState) => ({})

const mapDispatchToProps = (dispatch: any) => ({
  updateLocationHistory: (location: Location, source: string) => {
    dispatch(updateLocationHistory(location, source))
  },
  setVisibleList: (list: any) => {
    dispatch(setVisibleSettingsList(list))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Tile)
