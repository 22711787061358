import { IconType } from "@pag/center/components/icon/types"

export const SET_PARKING_VALUE = "SET_PARKING_VALUE"

export enum EParkingSubTabItemNames {
  PARKING = "parking"
}

export type ParkingState = {
  [index: string]: any,
  Parking_SoundLevel_ListItem: { label: string },
  Parking_EntertainmentAttenuation_ListItem: number,
  Parking_AutomaticParkingAssistant_ListItem: boolean,
  Parking_ManeuverAssistant_ListItem: boolean,
  Parking_RearCrossTraffic_ListItem: boolean,
  Parking_ExitWarning_ListItem: boolean
}

export type ListItem = Readonly<{
  label: string,
  secondLine?: string,
  icon?: IconType
  route?: string
}>

export type setParkingValueAction = {
  type: typeof SET_PARKING_VALUE
  label: string
  value: any
}
