import React from "react"

import "./mapTooltipDetailButtons.scss"

interface IMapTooltipDetailButtons {}

const MapTooltipDetailButtons: React.FC<IMapTooltipDetailButtons> = (props: IMapTooltipDetailButtons) => {
  return <div className="map-tooltip-detial-buttons-wrapper"></div> // TODO:
}

export default MapTooltipDetailButtons
