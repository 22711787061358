import objtools from "@pag/utils/object-tools";
import { SubTabBarItemRO } from "@pag/center/components/subTabBarRouting/types";
import { EParkingSubTabItemNames, ListItem } from "@pag/center/views/parkingScreen/types";
import icon from "@pag/center/components/icon/icons";
import { ROUTES } from "@pag/center/views/routes";

const tabs = objtools.populate(
    "text",        "itemName"
)(
  [ "Parking_Tab", EParkingSubTabItemNames.PARKING ],
) as SubTabBarItemRO<EParkingSubTabItemNames>[];

const sound_level_values = objtools.populate(
    "label"
)(
    [ "Parking_SoundLevel_RadioButton_Loud"   ],
    [ "Parking_SoundLevel_RadioButton_Medium" ],
    [ "Parking_SoundLevel_RadioButton_Quiet"  ]
);

const list_items: ListItem[] = objtools.populate(
    "label", "secondLine", "icon", "route"
)(
    [
        "Parking_ShowSurroundings_ListItem",
        undefined,
        icon.parking_camera,
        ROUTES.PARKING_ASSIST
    ],
    [ "Parking_SoundLevel_ListItem"                                                        ],
    [ "Parking_EntertainmentAttenuation_ListItem"                                          ],
    [ "Parking_AutomaticParkingAssistant_ListItem"                                         ],
    [ "Parking_ManeuverAssistant_ListItem",         "Parking_ManeuverAssistant_SecondLine" ],
    [ "Parking_RearCrossTraffic_ListItem",          "Parking_RearCrossTraffic_SecondLine"  ],
    [ "Parking_ExitWarning_ListItem",               "Parking_ExitWarning_SecondLine"       ]
) as ListItem[];

export default Object.freeze({
    tabs,
    sound_level_values,
    list_items
});
