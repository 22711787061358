import React from "react"
import ClassNames from "classnames"

import "./mapTooltip.scss"

interface IMapTooltip {
  className?: string
  children: any
}

const MapTooltip: React.FC<IMapTooltip> = (props: IMapTooltip) => (
  <div className={ClassNames("map-tooltip-wrapper", props.className)}>{props.children}</div>
)

export default MapTooltip
