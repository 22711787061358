import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"

export const SAVE_KEY_INPUT = "SAVE_KEY_INPUT"
export const DELETE_INPUT = "DELETE_INPUT"

export type ReduxInitAction = { type: "@@INIT" }

export type saveSearchInputAction = {
  type: typeof SAVE_KEY_INPUT
  payload: string
}

export type deleteSearchInputAction = {
  type: typeof DELETE_INPUT
}

export type SearchBarInputState = {
  input: string
}

export type Action = ReduxInitAction | saveSearchInputAction | deleteSearchInputAction

export type Store = ReduxStore<SearchBarInputState, Action>

export type Dispatch = ReduxDispatch<Action>

export type SearchInputType = saveSearchInputAction | deleteSearchInputAction
