import { CSSProperties } from "react"

import objtools from "@pag/utils/object-tools"
import { Handle, Pos, Zone, EClimateSubTabItems } from "./types"
import config from "@pag/center/config/initial"
import { SubTabBarItemRO } from "@pag/center/components/subTabBarRouting/types"

const isJ1 = config.base_model === "j1"

const tabs = objtools.populate(
  "text",                                                     "itemName"
)(
  [ "ClimateRootRouter_SubTabBar_pag3-i18n-label_Airflow",    EClimateSubTabItems.AIRFLOW    ],
  [ "ClimateRootRouter_SubTabBar_pag3-i18n-label_Airquality", EClimateSubTabItems.AIRQUALITY ],
  [ "ClimateRootRouter_SubTabBar_pag3-i18n-label_Precool",    EClimateSubTabItems.PRECOOL    ],
  [ "ClimateRootRouter_SubTabBar_pag3-i18n-label_Rear",       EClimateSubTabItems.REAR       ]
)

const [ ventilation_tab, air_quality_tab, precoolheat_tab, rear_tab ] = tabs;

const aux_precondition = objtools.populate("label")(
  [ "Auxiliary_Cooling_BevAuxCooling_Activation_i18n_Label#Off" ],
  [ "Auxiliary_Cooling_BevAuxCooling_Activation_i18n_Label#On"  ]
)

const aux_zones_combinations: Readonly<Zone[]> = objtools.populate("label")(
  [ /* 0000 ⠀ */ "Auxiliary_Cooling_Zones_i18n_Label#None"              ],
  [ /* 0001 ⠠ */ "Auxiliary_Cooling_Zones_i18n_Label#RearRight"         ],
  [ /* 0010 ⠄ */ "Auxiliary_Cooling_Zones_i18n_Label#RearLeft"          ],
  [ /* 0011 ⠤ */ "Auxiliary_Cooling_Zones_i18n_Label#Rear"              ],
  [ /* 0100 ⠈ */ "Auxiliary_Cooling_Zones_i18n_Label#Codriver"          ],
  [ /* 0101 ⠨ */ "Auxiliary_Cooling_Zones_i18n_Label#CodriverRearRight" ],
  [ /* 0110 ⠌ */ "Auxiliary_Cooling_Zones_i18n_Label#CodriverRearLeft"  ],
  [ /* 0111 ⠬ */ "Auxiliary_Cooling_Zones_i18n_Label#CodriverRear"      ],
  [ /* 1000 ⠁ */ "Auxiliary_Cooling_Zones_i18n_Label#Driver"            ],
  [ /* 1001 ⠡ */ "Auxiliary_Cooling_Zones_i18n_Label#DriverRearRight"   ],
  [ /* 1010 ⠅ */ "Auxiliary_Cooling_Zones_i18n_Label#DriverRearLeft"    ],
  [ /* 1011 ⠥ */ "Auxiliary_Cooling_Zones_i18n_Label#DriverRear"        ],
  [ /* 1100 ⠉ */ "Auxiliary_Cooling_Zones_i18n_Label#Front"             ],
  [ /* 1101 ⠩ */ "Auxiliary_Cooling_Zones_i18n_Label#FrontRearRight"    ],
  [ /* 1110 ⠍ */ "Auxiliary_Cooling_Zones_i18n_Label#FrontRearLeft"     ],
  [ /* 1111 ⠭ */ "Auxiliary_Cooling_Zones_i18n_Label#All"               ]
)

const aux_zones = Object.freeze([
  aux_zones_combinations[1 << 3], // driver
  aux_zones_combinations[1 << 2], // codriver
  aux_zones_combinations[1 << 1], // rear left
  aux_zones_combinations[1 << 0]  // rear right
])

const LEFT_SIDE_DIFFUSE         = Object.freeze([ 0.54,  0.27  ]) as Pos
const LEFT_CENTER_SIDE_DIFFUSE  = Object.freeze([ 0.69,  0.38  ]) as Pos
const LEFT_SIDE_FOCUSED         = Object.freeze([ 0.85,  0.785 ]) as Pos
const LEFT_CENTER_SIDE_FOCUSED  = Object.freeze([ 0.625, 0.84  ]) as Pos
const RIGHT_SIDE_DIFFUSE        = Object.freeze([ 0.46,  0.27  ]) as Pos
const RIGHT_CENTER_SIDE_DIFFUSE = Object.freeze([ 0.31,  0.38  ]) as Pos
const RIGHT_SIDE_FOCUSED        = Object.freeze([ 0.15,  0.785 ]) as Pos
const RIGHT_CENTER_SIDE_FOCUSED = Object.freeze([ 0.375, 0.84  ]) as Pos

const make_key = (function () {
  const max = 16;
  const randoes = new Uint32Array(max);
  let index = max;

  return function () {
    index += 1;
    if (index >= max) {
      crypto.getRandomValues(randoes);
      index = 0;
    }

    return () => randoes[index].toString(36);
  };
}());

const style_strong = Object.freeze({ toString: make_key() })
const style_soft = Object.freeze({ toString: make_key() })

const driver_side_handle: Handle = Object.freeze({
  className: "left",
  vent: "vent_left"
})
const driver_center_handle: Handle = Object.freeze({
  className: "left-center",
  vent: "vent_center_left"
})
const codriver_side_handle: Handle = Object.freeze({
  className: "right",
  vent: "vent_right"
})
const codriver_center_handle: Handle = Object.freeze({
  className: "right-center",
  vent: "vent_center_right"
})

const airflow_top = Object.freeze({ toString: make_key() })
const airflow_middle = Object.freeze({ toString: make_key() })
const airflow_bottom = Object.freeze({ toString: make_key() })

function bg_style(filename: string, increase_index = false): CSSProperties {
  return Object.freeze({
    backgroundImage: (
      filename === ""
      ? undefined
      : `url(${require("/src/components/pagCenter/assets/graphics-master/" + filename)})`
    ),
    zIndex: increase_index ? 1 : 0
  })
}

const central_airflow = bg_style("ac/ac_main/Airflow_EBF.png");

const driver_airflow_assets = new WeakMap([
  [ airflow_top,    bg_style(isJ1 ? "ac/ac_main/j1-Airflow_TL_Merged.png" : "ac/ac_main/Airflow_TL.png")               ],
  [ airflow_middle, bg_style(isJ1 ? "" : "ac/ac_main/Airflow_CML.png", true)                                           ],
  [ style_soft,     bg_style("ac/ac_main/Airflow_CML_soft.png", true)                                                  ],
  [ style_strong,   bg_style("ac/ac_main/Airflow_CML_strong.png", true)                                                ],
  [ airflow_bottom, bg_style(isJ1 ? "ac/ac_main/j1-Airflow_BL_Merged.png" : "ac/ac_main/Airflow_BL.png")               ]
])

const codriver_airflow_assets = new WeakMap([
  [ airflow_top,    bg_style(isJ1 ? "ac/ac_main/j1-Airflow_TR_Merged.png" : "ac/ac_main/Airflow_TR.png")               ],
  [ airflow_middle, bg_style(isJ1 ? "" : "ac/ac_main/Airflow_CMR.png", true)                                           ],
  [ style_soft,     bg_style("ac/ac_main/Airflow_CMR_soft.png", true)                                                  ],
  [ style_strong,   bg_style("ac/ac_main/Airflow_CMR_strong.png", true)                                                ],
  [ airflow_bottom, bg_style(isJ1 ? "ac/ac_main/j1-Airflow_BR_Merged.png" : "ac/ac_main/Airflow_BR.png")               ]
])

const pre_cool_list_items = objtools.populate(
  "label"
)(
  [ "Auxiliary_Cooling_Zones_i18n_Label" ]
)

const aux_zones_list_item = pre_cool_list_items[0]

export default Object.freeze({
  tabs,
  ventilation_tab,
  air_quality_tab,
  precoolheat_tab,
  rear_tab,

  pre_cool_list_items,
  aux_zones_list_item,

  aux_zones_combinations,
  aux_zones,
  aux_precondition,

  LEFT_SIDE_DIFFUSE,
  LEFT_CENTER_SIDE_DIFFUSE,
  LEFT_SIDE_FOCUSED,
  LEFT_CENTER_SIDE_FOCUSED,
  RIGHT_SIDE_DIFFUSE,
  RIGHT_CENTER_SIDE_DIFFUSE,
  RIGHT_SIDE_FOCUSED,
  RIGHT_CENTER_SIDE_FOCUSED,

  driver_side_handle,
  driver_center_handle,
  codriver_side_handle,
  codriver_center_handle,

  style_soft,
  style_strong,

  airflow_top,
  airflow_middle,
  airflow_bottom,

  central_airflow,
  driver_airflow_assets,
  codriver_airflow_assets
})
