export const SET_AUX_COOLING = "SET_AUX_COOLING"
export const SET_TEMPERATURE = "SET_TEMPERATURE"
export const SET_REMOTE = "SET_REMOTE"
export const SET_AUX_ZONE = "SET_AUX_ZONE"
export const SET_CLIMATE_ON = "SET_CLIMATE_ON"
export const SET_CLIMATE_OFF = "SET_CLIMATE_OFF"
export const SET_CLIMATE_EBF = "SET_CLIMATE_EBF"
export const SET_ECO_MODE = "SET_ECO_MODE"
export const SET_AC = "SET_AC"
export const SET_DRIVER_STYLE = "SET_DRIVER_STYLE"
export const SET_CODRIVER_STYLE = "SET_CODRIVER_STYLE"
export const SET_DRIVER_AIRFLOW_ON = "SET_DRIVER_AIRFLOW_ON"
export const SET_DRIVER_AIRFLOW_OFF = "SET_DRIVER_AIRFLOW_OFF"
export const SET_CODRIVER_AIRFLOW_ON = "SET_CODRIVER_AIRFLOW_ON"
export const SET_CODRIVER_AIRFLOW_OFF = "SET_CODRIVER_AIRFLOW_OFF"
export const SET_HANDLE_INTENCITY = "SET_HANDLE_INTENCITY"
export const SET_HANDLE_POSITION = "SET_HANDLE_POSITION"
export const SET_ACTIVE_LIST_ITEM = "SET_ACTIVE_LIST_ITEM"

export type Pos = readonly [ number, number ]

export type Zone = Readonly<{ label: string }>

export enum EClimateSubTabItems {
  AIRFLOW = "airflow",
  AIRQUALITY = "airquality",
  PRECOOL = "Precool/h.",
  REAR = "rear"
}

export type ListItem = Readonly<{ label: string }>

export enum Market {
  CHINA,
  US,
  EU,
  JAPAN,
  KOREA
}

export type State = {
  active: boolean,
  eco: boolean,
  ac: boolean,
  ebf: boolean,
  outside_temp: number,

  aux_cooling: { label: string },
  temperature: number,
  remote: boolean,
  aux_zones: Zone[],
  active_list_item: ListItem | undefined,

  driver_airflow: any[],

  driver_style: object | undefined,
  
  driver_flow_center_intencity: number,
  driver_flow_center_position: Pos

  driver_flow_side_intencity: number,
  driver_flow_side_position: Pos

  codriver_airflow: any[],

  codriver_style: object | undefined,
  
  codriver_flow_center_intencity: number,
  codriver_flow_center_position: Pos

  codriver_flow_side_intencity: number,
  codriver_flow_side_position: Pos
}

export type Handle = {
  className: string
  vent: string
}

export type SetActiveListItemAction = {
  type: typeof SET_ACTIVE_LIST_ITEM
  value: ListItem | undefined
}

export type SetActiveAction = {
  type: typeof SET_CLIMATE_OFF | typeof SET_CLIMATE_ON
}

export type SetEBFActiveAction = {
  type: typeof SET_CLIMATE_EBF
  value: boolean
}

export type SetEcoAction = {
  type: typeof SET_ECO_MODE
  value: boolean
}

export type SetAcAction = {
  type: typeof SET_AC
  value: boolean
}

export type SetStyleAction = {
  type: typeof SET_DRIVER_STYLE | typeof SET_CODRIVER_STYLE,
  style: any
}

export type SetAirflowOnAction = {
  type: typeof SET_DRIVER_AIRFLOW_ON | typeof SET_CODRIVER_AIRFLOW_ON,
  airflow: any
}

export type SetAirflowOffAction = {
  type: typeof SET_DRIVER_AIRFLOW_OFF | typeof SET_CODRIVER_AIRFLOW_OFF,
  airflow: any
}

export type SetHandleIntencityAction = {
  type: typeof SET_HANDLE_INTENCITY,
  handle: Handle,
  value: number
}

export type SetHandlePositionAction = {
  type: typeof SET_HANDLE_POSITION,
  handle: Handle,
  value: Pos
}

export type SetAuxCoolingAction = {
  type: typeof SET_AUX_COOLING,
  value: { label: string }
}

export type SetTemperatureAction = {
  type: typeof SET_TEMPERATURE,
  value: number
}

export type SetRemoteAction = {
  type: typeof SET_REMOTE,
  value: boolean
}

export type SetAuxZoneAction = {
  type: typeof SET_AUX_ZONE,
  value: { label: string }
}

export type Action = (
  SetAuxCoolingAction |
  SetActiveAction |
  SetEBFActiveAction |
  SetEcoAction |
  SetAcAction |
  SetStyleAction |
  SetAirflowOnAction |
  SetAirflowOffAction |
  SetHandleIntencityAction |
  SetHandlePositionAction |
  SetTemperatureAction |
  SetRemoteAction |
  SetAuxZoneAction |
  SetActiveListItemAction
)
