import { HomeLinkItem } from "./homeLinkProfiles"

export const SET_RENAME_VISIBLE = "SET_RENAME_VISIBLE"
export const ADD_HOME_LINK_PROFILE = "ADD_HOME_LINK_PROFILE"
export const REMOVE_HOME_LINK_PROFILE = "REMOVE_HOME_LINK_PROFILE"
export const STORE_HOME_LINK_PROFILE_POSITION = "STORE_HOME_LINK_PROFILE_POSITION"
export const DELETE_HOME_LINK_PROFILE_POSITION = "DELETE_HOME_LINK_PROFILE_POSITION"
export const UPDATE_HOME_LINK_PROFILE_NAME = "UPDATE_HOME_LINK_PROFILE_NAME"

export type HomeLinkState = {
  renameVisible: boolean
  profiles: HomeLinkItem[]
}

export type SetRenameVisibleAction = {
  type: typeof SET_RENAME_VISIBLE
  visible: boolean
}

export type RemoveHomeLinkProfileAction = {
  type: typeof REMOVE_HOME_LINK_PROFILE
  profile: HomeLinkItem
}

export type AddHomeLinkProfileAction = {
  type: typeof ADD_HOME_LINK_PROFILE
  profile: HomeLinkItem
}

export type StoreHomeLinkProfilePositionAction = {
  type: typeof STORE_HOME_LINK_PROFILE_POSITION
  id: string
}

export type DeleteHomeLinkProfilePositionAction = {
  type: typeof DELETE_HOME_LINK_PROFILE_POSITION
  id: string
}

export type UpdateHomeLinkProfileName = {
  type: typeof UPDATE_HOME_LINK_PROFILE_NAME
  id: string
  name: string
}

export type HomeLinkAction =
  | SetRenameVisibleAction
  | AddHomeLinkProfileAction
  | RemoveHomeLinkProfileAction
  | StoreHomeLinkProfilePositionAction
  | DeleteHomeLinkProfilePositionAction
  | UpdateHomeLinkProfileName
