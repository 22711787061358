const populate = (...names: string[]) => (...elements: any[]): Readonly<readonly any[]> => (
  Object.freeze(elements.map((values: any[]) => Object.freeze(
    names.reduce(
      (result: { [index: string]: any}, name: string, index: number) => {
        result[name] = values[index]
        return result
      },
      {}
    )
  )))
)

const populate_loose = (...names: string[]) => (...elements: any[]): Readonly<any[]> => (
  Object.freeze(elements.map((values: any[]) => names.reduce(
    (result: { [index: string]: any}, name: string, index: number) => {
      result[name] = values[index]
      return result
    },
    {}
  )))
)

const map = (...pairs: [object, any][]) => new WeakMap(pairs)

export default Object.freeze({
  populate,
  populate_loose,
  map
})
