import { BaseClient, ICallErrorResponse } from "./BaseClient";

export interface LinkDto {
    uri: string;
    ref: string;
    method: string;
    view?: string;
}

export interface MediaDownloadDto {
    id: string;
    type?: string;
    partitionKey?: string;
    links: LinkDto[];
    reference: string;
    mediaType: string;
    width?: number;
    height?: number;
    changed: boolean;
    lng?: string;
    autoPlay?: boolean;
    container?: string;
}

export interface UserDownloadDto {
    id: string;
    type?: string;
    partitionKey?: string;
    links: LinkDto[];
    title: string;
    firstName: string;
    secondName: string;
    email: string;
    phone?: string;
    avatar: MediaDownloadDto;
    roleInGroup: string;
    registered: boolean;
    showGroupWelcome: boolean;
    inactive: boolean;
    firstLogin: Date;
    lastLogin: Date;
    passwordChanged: Date;
    locked: boolean;
    unsuccessfulLogins: number;
    publicKey: string;
    organisation: string;
    position: string;
    commentCount: number;
    ratingCount: number;
}



export const GetUserInfo = (
    client: BaseClient,
    onSuccess?: (result: UserDownloadDto) => void,
    onError?: (reason: ICallErrorResponse) => void,
    onProgress?: (currentBytes: number, totalBytes: number) => void,
    mutex?: string) => {
    client.call<UserDownloadDto>({
        uri: "api/user/info",
        method: "GET",
        onSuccess,
        onError,
        onProgress,
        mutex,
    });
}

export const RenewCookie = (
    client: BaseClient,
    token: string,
    onSuccess?: (result: string) => void,
    onError?: (reason: ICallErrorResponse) => void,
    onProgress?: (currentBytes: number, totalBytes: number) => void,
    mutex?: string) => {
    const fd = new FormData();
    fd.append("token", JSON.stringify(token));
    client.call<string>({
        uri: "api/login/token/renew",
        method: "POST",
        onSuccess,
        onError,
        onProgress,
        mutex,
        data: fd,
    });
}
