import { IconType } from "@pag/center/components/icon/types";

export const SET_CAR_VALUE = "SET_CAR_VALUE";
export const SET_CAR_VALUE_LIST = "SET_CAR_VALUE_LIST";
export const SET_CAR_SCREEN_VALUE = "SET_CAR_SCREEN_VALUE";

export type Value = Readonly<{ label: string }>

export type ListItem = Readonly<{
    label: string
    desc?: string
    icon: IconType | undefined
    value: Value[] | undefined | object
    list?: Readonly<ListItem[]>
}>

export type Lists = WeakMap<Value, Readonly<ListItem[]>>

export type State = {
    [index: string]: any
}

export type ScreenState = {
    driveTabListItem: ListItem | undefined
    assistTabListItem: ListItem | undefined
    assistTabActiveList: ListItem | undefined
    comfortTabListItem: ListItem | undefined
    comfortTabActiveList: ListItem | undefined
}

export type SetValueListAction = {
    type: typeof SET_CAR_VALUE_LIST
    values: { [index: string]: Value | boolean }
}

export type SetValueAction = {
    type: typeof SET_CAR_VALUE
    key: string
    value: Value | boolean
}

export type SetScreenValueAction = {
    type: typeof SET_CAR_SCREEN_VALUE
    key: string
    value: any
}
