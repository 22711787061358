import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"
import { LauncherTile } from "@pag/center/views/launcherConfig/types"

export const SET_MAIN_TAB_BAR_ITEMS = "SET_MAIN_TAB_BAR_ITEMS"

export const CLOSE_WIZARD = "CLOSE_WIZARD"
export const REQUEST_CLOSE_WIZARD_SWITCH = "REQUEST_CLOSE_WIZARD_SWITCH"

export type ReduxInitAction = { type: "@@INIT" }

export type SetMainTabBarItemsAction = {
  type: typeof SET_MAIN_TAB_BAR_ITEMS
  items: MainTabBarState
}

export type MainTabBarState = LauncherTile[]

export type Action = ReduxInitAction | SetMainTabBarItemsAction

export type Store = ReduxStore<MainTabBarState, Action>

export type Dispatch = ReduxDispatch<Action>

export type SetCloseWizard = {
  type: typeof CLOSE_WIZARD
  close: boolean
}

export type RequestCloseWizardSwitch = {
  type: typeof REQUEST_CLOSE_WIZARD_SWITCH
  close: boolean
}

export type CloseWizardState = {
  closeWizard: boolean,
  switchRequested: boolean
}

