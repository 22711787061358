import React, { lazy, Suspense, useEffect } from "react"

import { initNavigationPayload, updateNavigationPayload } from "@pag/center/views/navigationScreen/mapMain/types"
import NavigationService from "@pag/utils/navigationService/NavigationService"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import AuthorizedRoute from "./imgConnection/AuthorizedRoute"

import "./App.css"
import "@pag/globalstyles/globalStyle.scss"
import "@pag/globalstyles/iconfont.css"
import "@pag/globalstyles/sass/_icon-master.scss"
import "@pag/globalstyles/sass/_pag3_styles.scss"

/* Components */
/* --------------------------------------------------------  Lazy load Components ---|> */

const CenterShowcase = lazy(() => import("@pag/center/views/centerShowcase/CenterShowcase"))
const CenterMainScreenContainer = lazy(() => import("@pag/center/views/centerMainScreen/CenterMainScreenContainer"))
const Navigation = lazy(() => import("./Navigation"))
const PassengerCenterMainScreenContainer = lazy(
  () => import("./components/pagCenterPassenger/views/passengerCenterMainScreen/PassengerCenterMainScreenContainer")
)
/* <-------------------------------------------------------- Lazy load Components ---<| */

// if (process.env.NODE_ENV === "production") {
//   console.log("console.log disabled in production mode: ", process.env.NODE_ENV)
//   console.log = () => null
// }

const setTitle = () => {
  document.title = `PCM Simulation`
}

interface IApp {
  updateNavigationAction?: (payload: updateNavigationPayload) => void
  stopNavigationAction?: () => void
  initNavigationAction?: (payload: initNavigationPayload) => void
}

export const isDevMode = process.env.NODE_ENV === "development" ? true : false

const App: React.FunctionComponent<IApp> = ({ updateNavigationAction, stopNavigationAction, initNavigationAction }) => {
  useEffect(() => {
    NavigationService.init(updateNavigationAction!, stopNavigationAction!, initNavigationAction!)
  }, [updateNavigationAction, initNavigationAction, stopNavigationAction])

  return (
    <>
      {setTitle()}
      <Suspense fallback="loading">
        {/* Suspense is required for i18next */}
        <BrowserRouter>
          <Switch>
            <Route exact path={`/`}>
              {isDevMode ? <Navigation /> : <Redirect to="/center" />}
            </Route>
            <AuthorizedRoute
              exact
              path="/center"
              component={CenterMainScreenContainer}
              componentProps={{ pathOrigin: "/center" }}
            />
            {isDevMode && (
              <Route exact path="/center_passenger">
                <PassengerCenterMainScreenContainer pathOrigin="/center_passenger" />
              </Route>
            )}
            {isDevMode && (
              <Route exact path="/center/*">
                <Redirect to="/center" />
              </Route>
            )}

            {isDevMode && (
              <Route exact path="/showcase_center">
                <CenterShowcase />
              </Route>
            )}
            <Route>{isDevMode ? <Navigation /> : <Redirect to="/center" />}</Route>
          </Switch>
        </BrowserRouter>
      </Suspense>
    </>
  )
}
export default App
