import { SET_IPA, Action } from "./types"

export default function parkingScreenReducer(
    state = { ipa: false },
    action: Action
) {
  switch (action.type) {
    case SET_IPA:
      return {
        ...state,
        ipa: action.active
      }
    default:
      return state
  }
}
