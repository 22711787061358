import { createStore, combineReducers } from "redux"

import {
  locationHistoryReducer,
  homeButtonUrlReducer,
  pageTransitionAnimationReducer,
  socReducer,
  incomingCallPopupVisibilityReducer,
  voiceSearchReducer,
  popupReducer,
  overlayReducer
} from "@pag/center/views/centerMainScreen/reducer"
import { closeWizardReducer, mainTabBarItemsReducer } from "@pag/center/components/mainTabBar/reducer"
import { driveMainScreenListItemReducer, driveModeReducer } from "@pag/center/views/carScreen/drive/reducer"
import { navigationReducer, mapReducer, poiReducer } from "@pag/center/views/navigationScreen/mapMain/reducer"
import { settingsReducer, settingsScreenReducer } from "@pag/center/views/settingsScreen/reducer"
import { destInputReducer } from "@pag/center/views/navigationScreen/reducer"
import { homeLinkReducer } from "@pag/center/views/homeLinkScreen/reducer"
import { phoneSearchReducer, phoneScreenReducer } from "@pag/center/views/phoneScreen/reducer"
import { chargingScreenReducer } from "@pag/center/views/chargingScreen/reducer"
import { accountsReducer } from "@pag/center/views/accountsScreen/reducer"
import subTabBarItem from "@pag/center/components/subTabBarRouting/subTabBarRoutingItem/reducer"
import audioPlayer from "@pag/center/components/player/audioPlayer/reducer"
import titlePlayTime from "@pag/center/components/player/audioPlayer/html5AudioWrapper/reducer"
import searchBarInput from "@pag/center/components/speller/keyboard/reducer"
import notifications from "@pag/center/views/notificationCenter/reducer"
import mainState from "../../redux/reducer/mainState/reducer"
import statusBarSettings from "@pag/center/components/statusBar/reducer"
import mediaSound from "@pag/center/views/mediaPlayer/mediaSound/reducer"
import songFastForwarTime from "@pag/center/components/player/audioPlayer/audioPlayerProgressBar/reducer"
import welcomeWizard from "@pag/center/views/welcomeScreen/reducer"
import myScreen from "@pag/center/views/myScreen/reducer"
import media from "@pag/center/views/mediaPlayer/reducer"
import launcher from "@pag/center/views/launcherConfig/reducer"
import parking from "@pag/center/views/parkingScreen/reducer"
import parkingAssist from "@pag/center/views/parkingAssist/reducer"
import device from "@pag/center/views/deviceScreen/reducer"
import climate from "@pag/center/views/climateScreen/reducer"
import car from "@pag/center/views/carScreen/reducer"
import car_screen from "@pag/center/views/carScreen/screen_reducer"

const rootReducer = combineReducers({
  mainState,
  subTabBarItem,
  audioPlayer,
  titlePlayTime,
  locationHistory: locationHistoryReducer,
  homeButtonUrl: homeButtonUrlReducer,
  pageAnimationTrasition: pageTransitionAnimationReducer,
  searchBarInput,
  notifications,
  car,
  car_screen,
  climate,
  device,
  driveMainScreenListItem: driveMainScreenListItemReducer,
  mainTabBarItems: mainTabBarItemsReducer,
  closeWizard: closeWizardReducer,
  navigation: navigationReducer,
  map: mapReducer,
  poi: poiReducer,
  statusBarSettings,
  mediaSound,
  driveMode: driveModeReducer,
  soc: socReducer,
  songFastForwarTime,
  welcomeWizard,
  incomingCallPopupVisibility: incomingCallPopupVisibilityReducer,
  settingsScreen: settingsScreenReducer,
  accounts: accountsReducer,
  settings: settingsReducer,
  myScreen,
  voiceSearch: voiceSearchReducer,
  destInput: destInputReducer,
  homeLink: homeLinkReducer,
  phoneSearch: phoneSearchReducer,
  phoneScreen: phoneScreenReducer,
  parking,
  parkingAssist,
  media,
  launcher,
  charging: chargingScreenReducer,
  popup: popupReducer,
  overlay: overlayReducer
})

export type AppState = ReturnType<typeof rootReducer>

export default function configureStore() {
  const store = createStore(
    rootReducer,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  )

  return store
}
