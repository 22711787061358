import { ParkingState, setParkingValueAction, SET_PARKING_VALUE } from "./types"
import data from "./data"

export default function parkingScreenReducer(
    state: ParkingState = {
      Parking_SoundLevel_ListItem: data.sound_level_values[1],
      Parking_EntertainmentAttenuation_ListItem: 3,
      Parking_AutomaticParkingAssistant_ListItem: true,
      Parking_ManeuverAssistant_ListItem: true,
      Parking_RearCrossTraffic_ListItem: true,
      Parking_ExitWarning_ListItem: true
    },
    action: setParkingValueAction
): ParkingState {
  switch (action.type) {
    case SET_PARKING_VALUE:
      return Object.assign({}, state, {
        [action.label]: action.value 
      })
    default:
      return state
  }
}
