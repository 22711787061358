import * as React from 'react';

export function MiniLoader() {
    return (
        <div className="js-loader">
            <div className="js-loader-container">
                <div className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <br />
          Loading...
        </div>
        </div>
    );
}
