export enum UnitKind {
  None,
  Charge,
  Consumption,
  Date,
  Distance,
  Duration,
  DurationPrecise,
  Percentage,
  Pressure,
  Speed,
  Temperature,
  Time,
  GForce
}
