import React from "react"
import ClassNames from "classnames"

import { IconType } from "@pag/center/components/icon/types"
import Icon from "@pag/center/components/icon/Icon"
import I18nLabel from "@pag/center/components/I18nLabel/I18nLabel"

import "./infoMessage.scss"

interface InfoMessageProps {
  className?: string
  developerText: string | React.ReactElement
  useFullScreenWrapper: boolean
  iconState?: string
  icon?: IconType
  children?: any // only buttons
  interpolationKeys?: Object
}

const InfoMessage: React.FC<InfoMessageProps> = ({
  className,
  developerText,
  useFullScreenWrapper,
  iconState,
  icon,
  children,
  interpolationKeys
}) => {
  return (
    <div className={ClassNames(className, "info-message-wrapper")}>
      {useFullScreenWrapper ? (
        <div className="bg-dotgrid__wrapper">
          <div className="bg-dotgrid bg-dotgrid__mask-highlight bg-dotgrid__centered" />
        </div>
      ) : null}

      <div className="info">
        {icon ? <Icon iconClass={"info__icon" + (iconState === undefined ? "" : " " + iconState)} iconType={icon} /> : null}

        <div className="info__message">{
          typeof developerText === "string"
          ? <I18nLabel interpolationKeys={interpolationKeys} text={developerText} />
          : developerText
        }</div>
      </div>

      {children && <div className="buttons">{children}</div>}
    </div>
  )
}

export default InfoMessage
