import { IconType } from "@pag/center/components/icon/types"
import icon from "@pag/center/components/icon/icons"
import { GenericMessageQuickActions } from "./generic-message-quick-actions"

export const GENERIC_MESSAGE_TYPES = {
  MISSED_CALL: "missed_call",
  NEW_SMS: "new_sms",
  NEW_EMAIL: "NEW_EMAIL"
}

enum EFilter {
  CAR = "car",
  INFO = "info",
  MESSAGE = "message"
}

export type GenericMessage = {
  id: number
  filter: EFilter
  text: string
  detailText: string
  detailData: {
    type: string
    phone?: string
    contactName?: string
    picture: null
  }
  timestamp: Date
  icon: IconType
  quickActions: GenericMessageQuickActions[]
  clicked?: boolean
}

const yesterday: (timeString: string) => Date = (function () {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  return function(timeString: string) {
    return new Date(`${yesterday.getFullYear()}-${String(yesterday.getMonth() + 1).padStart(2, "0")}-${String(yesterday.getDate()).padStart(2, "0")}T${timeString}`)
  }
}());

export const GENERIC_MESSAGES = new Map([
  [
    1,
    {
      id: 1,
      filter: EFilter.MESSAGE,
      text: "Notification_Center_Generic_Message_Missed_Call",
      detailText: "",
      detailData: {
        type: GENERIC_MESSAGE_TYPES.MISSED_CALL,
        contactName: "Maria Bianchi",
        picture: null
      },
      timestamp: yesterday("12:01:00"),
      icon: icon.phone_missed_call,
      quickActions: [GenericMessageQuickActions.Rueckruf]
    }
  ],
  [
    2,
    {
      id: 2,
      filter: EFilter.MESSAGE,
      text: "Notification_Center_Generic_Message_New_Mail",
      detailData: {
        type: GENERIC_MESSAGE_TYPES.NEW_EMAIL,
        contactName: "Maria Bianchi",
        picture: null
      },
      detailText:
        "Hope you are fine.\nLet's have lunch this week in order to talk about your buiness idea.\n\nBest Maria",
      timestamp: yesterday("12:02:00"),
      icon: icon.message,
      quickActions: [GenericMessageQuickActions.Vorlesen]
    }
  ]
])
