import React, { CSSProperties, useState, useEffect } from "react"
import ClassNames from "classnames"

import { IconType } from "@pag/center/components/icon/types"
import I18nLabel from "@pag/center/components/I18nLabel/I18nLabel"
import Icon from "@pag/center/components/icon/Icon"

import "./button.scss"

interface IButton {
  style?: CSSProperties
  labelText?: string
  icon?: IconType
  iconUrl?: string
  hastText?: boolean
  nonI18n?: string
  buttonId?: string
  className?: string
  disabled?: boolean
  disabledText?: string
  onClick?: () => void
}

const Button: React.FC<IButton> = ({
  labelText = "",
  icon,
  iconUrl,
  hastText,
  nonI18n,
  buttonId,
  className,
  onClick,
  disabled,
  disabledText,
  style
}) => {
  const [ pressed, setPressed ] = useState(false)

  useEffect(
    function () {
      if (pressed) {
        const timeout = setTimeout(() => {
          setPressed(false)
          if (typeof onClick === "function") {
            onClick()
          }
        }, 300)

        return () => clearTimeout(timeout)
      }
    },
    [ pressed, onClick ]
  )

  return (
    <div
      style={style}
      className={ClassNames(
        className,
        "button__host",
        {
          "state-pressed": pressed,
          "state-disabled": disabled
        }
      )}
      onClick={() => {
        if (!disabled) {
          setPressed(true)
        }
      }}
    >
      <div className="animated-bg--pressed" />
      <div className="button__fading">
        <div className="button__container">
          {icon && <Icon iconClass={ClassNames("button__icon", { "button--icon-only": !(nonI18n || labelText) })} iconType={icon} />}
          {iconUrl && <Icon iconClass={ClassNames("button__icon", { "button--icon-only": !(nonI18n || labelText) })} iconType={iconUrl} />}
          {(nonI18n || labelText) && (
            <I18nLabel
              text={disabled && disabledText ? disabledText : labelText}
              id={buttonId}
              className="button__text"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Button
