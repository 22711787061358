import React from "react"
import ClassNames from "classnames"

import NonI18nLabel from "@pag/center/components/nonI18nLabel/NonI18nLabel"
import Icon from "@pag/center/components/icon/Icon"
import icon from "@pag/center/components/icon/icons"

import { ENavigationOverlay } from "@pag/center/views/navigationScreen/mapMain/navigationOverlay/NavigationOverlay"
import { LocationObject } from "../types"

import "./mapTooltipAddressItemDetails.scss"

interface IMapTooltipAddressItemDetails {
  className?: string
  overlay: ENavigationOverlay
  location: LocationObject
  isFavorite?: boolean
}

const MapTooltipAddressItemDetails: React.FC<IMapTooltipAddressItemDetails> = (
  props: IMapTooltipAddressItemDetails
) => {
  return (
    <div className={ClassNames("map-tooltip-address-item-details-wrapper", props.className)}>
      <div className="headline-wrapper">
        {props.overlay === ENavigationOverlay.POI_STACK_SELECTED && (
          <Icon
            iconClass="back-button"
            iconType={icon.arrow_open_left}
            onPress={() => {}} // TODO:
          />
        )}
        <NonI18nLabel
          className={ClassNames("address__headline", {
            "poi-stack": ENavigationOverlay.POI_STACK_SELECTED === props.overlay
          })}
          text={props.location.addressLineFirst} // TODO:
        />
      </div>
      <NonI18nLabel
        className="address__second-line"
        text={props.location.addressLineSecond || ""} // TODO:
      />
      <NonI18nLabel
        className="address__third-line"
        text={props.location.addressLineThird || ""} // TODO:
      />
      {props.isFavorite !== undefined && (
        <div className="address__favorite-icon">
          <Icon
            iconType={props.isFavorite ? icon.star : icon.star_outline}
            onPress={() => {}} // TODO:
          />
        </div>
      )}
    </div>
  )
}

export default MapTooltipAddressItemDetails
