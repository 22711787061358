import { SubTabItemSettingType, SubTabBarState, SET_ACTIVE_SUBTAB_ITEM, activeItem } from "./types"

const initialState: SubTabBarState = {
  activeItems: [
    { itemName: "favorites", subTabBar: "news" },
    { itemName: "play",      subTabBar: "media" },
    { itemName: "drive",     subTabBar: "car" },
    { itemName: "airflow",   subTabBar: "climate" },
    { itemName: "favorites", subTabBar: "phone" },
    { itemName: "overview",  subTabBar: "charging" },
    { itemName: "homelink",  subTabBar: "homelink" },
    { itemName: "evaluation", subTabBar: "sportChrono" },
    { itemName: "updates",   subTabBar: "update" },
    { itemName: "parking",   subTabBar: "parking" }
  ]
}

function updateObjectInArray(array: SubTabBarState, action: SubTabItemSettingType) {
  return array.activeItems.map((item: activeItem, index: number) => {
    if (item.subTabBar !== action.payload.subTabBar) {
      return item
    }
    return {
      ...item,
      ...action.payload
    }
  })
}

export default Object.freeze(function activeSubTabItemReducer(state = initialState, action: SubTabItemSettingType): SubTabBarState {
  switch (action.type) {
    case SET_ACTIVE_SUBTAB_ITEM:
      return {
        activeItems: updateObjectInArray(state, action)
      }
    default:
      return state
  }
})
