import { IconType } from "@pag/center/components/icon/types"

export const UPDATE_PHONE_SEARCH = "UPDATE_PHONE_SEARCH"
export const SET_BREAKDOWN_CALL = "SET_BREAKDOWN_CALL"
export const SET_ONGOING_CALL = "SET_ONGOING_CALL"
export const ADD_FAVORITE_PHONE_NUMBER = "ADD_FAVORITE_PHONE_NUMBER"

export type PhoneNumberKind = {
  label: string
}

export type Contact = {
  firstName: string
  lastName?: string
  phone_numbers: PhoneNumber[]
}

export type PhoneNumber = {
  number: string
  contact: Contact | undefined
  kind: PhoneNumberKind
}

export type CallKind = {
  icon: IconType
}

export type Call = {
  kind: CallKind
  number: PhoneNumber
  date: Date
}

export type UpdatePhoneSearchAction = {
  type: typeof UPDATE_PHONE_SEARCH
  active: boolean
}

export type SetBreakdownCallAction = {
  type: typeof SET_BREAKDOWN_CALL
  active: boolean
}

export type SetOngoingCall = {
  type: typeof SET_ONGOING_CALL
  ongoing: PhoneNumber
}

export type AddFavoritePhoneNumber = {
  type: typeof ADD_FAVORITE_PHONE_NUMBER
  number: PhoneNumber
}

export type PhoneScreenState = {
  breakdownCallActive: boolean
  contacts: Readonly<Contact[]>
  favorites: Readonly<PhoneNumber[]>
  fav_candidates: Readonly<PhoneNumber[]>
  calls: Readonly<Call[]>
  ongoing: PhoneNumber | undefined
}
