import { Store as ReduxStore, Dispatch as ReduxDispatch } from "redux"

export const SET_MAINSTATE_MAINVIEW = "SET_MAINSTATE_MAINVIEW"
export const SET_PASSENGER_SCREEN_STANDBY = "SET_PASSENGER_SCREEN_STANDBY"
export const SET_AUTHORIZED = "SET_AUTHORIZED";
export type ReduxInitAction = { type: "@@INIT" }

export type setMainViewAction = {
  type: typeof SET_MAINSTATE_MAINVIEW
  payload: string
}

export type setPassengerScreenViewAction = {
  type: typeof SET_PASSENGER_SCREEN_STANDBY
  payload: string
}

export type setAuthorizedAction = {
  type: typeof SET_AUTHORIZED
  payload: boolean
}

export type MainState = {
  mainView: string
  passengerScreenView: string
  authorized: boolean
}


export type Action = ReduxInitAction | setMainViewAction | setPassengerScreenViewAction

export type Store = ReduxStore<MainState, Action>

export type Dispatch = ReduxDispatch<Action>

export type MainViewSettingType = setMainViewAction | setPassengerScreenViewAction | setAuthorizedAction
