import object_tools from "@pag/utils/object-tools"
import { List_Entry } from "@pag/center/components/player/audioPlayer/types"
import icon from "@pag/center/components/icon/icons"

import dance_cover from "@pag/center/assets/mp3/spotify/Album_Cover_Dance_(In your Car).jpg"
import dream_racer_cover from "@pag/center/assets/mp3/spotify/Album_Cover_Dream_Racer.png"
import electro_mix_cover from "@pag/center/assets/mp3/spotify/Album_Cover_Electronic_Mix.png"
import its_my_time_cover from "@pag/center/assets/mp3/spotify/Album_Cover_It's_My_Time.png"
import our_up_beat_cover from "@pag/center/assets/mp3/spotify/Album_Cover_Our_Up_Beat.png"
import somewhere_nice_cover from "@pag/center/assets/mp3/spotify/Album_Cover_Somewhere_Nice.png"

const importAll = (r: any) => r.keys().reduce(
  (songs: { [index: string]: any }, item: any) => {
    songs[item.replace("./", "")] = r(item)
    return songs
  },
  Object.create(null)
)
const assets = importAll(require.context("@pag/center/assets/mp3/spotify", false, /\.(mp3)$/))

// BUILD SONGS /////////////////////////////////////////////////////////////////

const populate_albums = object_tools.populate_loose(
    "label",          "icon"
)

const albums: Readonly<List_Entry[]> = populate_albums(
  [ "Somewhere Nice", somewhere_nice_cover ],
  [ "Dreamracer",     dream_racer_cover ],
  [ "Our Up Beat",    our_up_beat_cover ],
  [ "It’s My Time",   its_my_time_cover ]
)

const populate_artists = object_tools.populate(
  "label",                "icon",      "list"
)

const artists: Readonly<List_Entry[]> = populate_artists(
  [ "Gail Philips",       icon.person, [ albums[0] ] ],
  [ "Phoenix Oz",         icon.person, [ albums[1] ] ],
  [ "The Love of Ladies", icon.person, [ albums[2] ] ],
  [ "Two Times Up Beat",  icon.person, [ albums[3] ] ]
)

const populate_songs = object_tools.populate(
    "label",            "album",   "artist",   "source",                       "duration"
)

export const songs: Readonly<List_Entry[]> = populate_songs(
  [ "Happy Village",    albums[0], artists[0], assets["Happy Village.mp3"],    "2:14" ],
  [ "Somewhere Nice",   albums[0], artists[0], assets["somewhere nice.mp3"],   "2:22" ],
  [ "Sound Of Passion", albums[0], artists[0], assets["Sound of Passion.mp3"], "1:20" ],

  [ "Sputnik",          albums[1], artists[1], assets["Sputnik.mp3"],          "2:28" ],
  [ "Bad Behaviors",    albums[1], artists[1], assets["Bad Behaviors.mp3"],    "9:20" ],
  [ "Dreamracer",       albums[1], artists[1], assets["DREAMRACER.mp3"],       "3:34" ],
  [ "Runs",             albums[1], artists[1], assets["Runs.mp3"],             "2:35" ],
  [ "Wowa",             albums[1], artists[1], assets["Wowa.mp3"],             "3:44" ],

  [ "Earning",          albums[2], artists[2], assets["Earning.mp3"],          "0:47" ],
  [ "Fallen Leaves",    albums[2], artists[2], assets["Fallen Leaves.mp3"],    "1:13" ],
  [ "Sly Cat",          albums[2], artists[2], assets["Sly Cat.mp3"],          "1:40" ],

  [ "Majesty",          albums[3], artists[3], assets["Majesty.mp3"],          "6:26" ],
  [ "Rainy",            albums[3], artists[3], assets["Rainy.mp3"],            "3:13" ],
  [ "Waiting",          albums[3], artists[3], assets["Waiting.mp3"],          "2:28" ],
  [ "Champions",        albums[3], artists[3], assets["Champions.mp3"],        "3:15" ],
  [ "Euphoria",         albums[3], artists[3], assets["Euphoria.mp3"],         "3:13" ],
  [ "Purely Green",     albums[3], artists[3], assets["Purely Green.mp3"],     "4:26" ]
)

albums.forEach((album) => {
  album.list = Object.freeze(songs.filter((song) => song.album === album))
  Object.freeze(album)
})

// BUILD LISTS /////////////////////////////////////////////////////////////////

const populate_list = object_tools.populate(
    "label",            "icon",                "list"
)

const mixes: Readonly<List_Entry[]> = populate_list( // @ts-ignore
  [ "Electronic Mix",   electro_mix_cover,     Object.freeze([ ...albums[1].list.slice(), ...albums[3].list.slice() ]) ],
)

const playlists: Readonly<List_Entry[]> = populate_list(
  [
    "Media_Spotify_Browser_Playlist_Liked",
    undefined,
    Object.freeze([ songs[4], songs[7], songs[2] ])
  ]
)

const home_list: Readonly<List_Entry[]> = Object.freeze([
  Object.freeze({ label: "Media_Spotify_Browser_List_Divider_Good_Morning" }),
  albums[2],
  mixes[0],
  albums[0],
  Object.freeze({ label: "Media_Spotify_Browser_Additional_Results", list: [] }),
  Object.freeze({ label: "Media_Spotify_Browser_List_Divider_Jump_Back_In" }),
  albums[3],
  artists[0],
  albums[1],
  Object.freeze({ label: "Media_Spotify_Browser_Additional_Results", list: [] }),
  Object.freeze({ label: "Media_Spotify_Browser_RecentlyPlayed" }),
  Object.freeze({ label: "Media_Spotify_Browser_Additional_Results", list: [] })
])

const recent_list: Readonly<List_Entry[]> = populate_list(
)

const library_list: Readonly<List_Entry[]> = populate_list(
  [ "Media_Spotify_Browser_Playlists", undefined, playlists.slice() ],
  [ "Media_Spotify_Browser_Albums",    undefined, albums.slice() ],
  [ "Media_Spotify_Browser_Artists",   undefined, artists.slice() ],
  [ "Media_Spotify_Browser_Podcasts",  undefined, [] ],
)

const browse_list: Readonly<List_Entry[]> = populate_list(
  [ "Top Lists",       undefined, [  ] ],
  [ "Fun",             undefined, [ albums[0], albums[2] ] ],
  [ "Upbeat",          undefined, [ albums[0] ] ],
  [ "Tropical",        undefined, [ albums[0] ] ],
  [ "Electronic",      undefined, [ albums[1], albums[3] ] ],
  [ "Techno",          undefined, [ albums[1] ] ],
  [ "Unusual",         undefined, [ albums[1] ] ],
  [ "Jazz",            undefined, [ albums[2] ] ],
  [ "Funk",            undefined, [ albums[2] ] ],
  [ "Relaxed",         undefined, [ albums[3] ] ],
  [ "Lo-Fi",           undefined, [ albums[3] ] ]
)

const overview_list: Readonly<List_Entry[]> = populate_list(
  [ "Media_Spotify_Browser_Home",           icon.spotify_home,    home_list ],
  [ "Media_Spotify_Browser_RecentlyPlayed", icon.spotify_recents, recent_list ],
  [ "Media_Spotify_Browser_Browse",         icon.spotify_browse,  browse_list ],
  [ "Media_Spotify_Browser_Library",        icon.spotify_library, library_list ]
)

export default overview_list
