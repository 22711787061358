import React from "react"
import ClassNames from "classnames"

import BaseListItemSepearateTouchArea from "@pag/center/views/navigationScreen/mapMain/navigationOverlay/poi/baseListItemSeparateToucharea/BaseListItemSeparateToucharea"

import "./listItemNaviAnythingAnywhere.scss"

interface IListItemNaviAnythingAnywhere {
  className?: string
  centerEndNeedsPadding?: boolean
  startContent?: React.ReactNode
  centerContent?: React.ReactNode
  centerEnd?: React.ReactNode
  endContent?: React.ReactNode
  hideStart?: boolean
  hideCenterEnd?: boolean
  hideEnd?: boolean
  disableTouchAnimation?: boolean
  multiline?: boolean
  isNaviAndAsia?: boolean
  onTap?: Function
  onTapEnd?: Function
  active?: boolean
}

const ListItemNaviAnythingAnywhere: React.FC<IListItemNaviAnythingAnywhere> = (
  props: IListItemNaviAnythingAnywhere
) => {
  return (
    <div className={ClassNames("list-item-navi-anything-anywhere-wrapper", props.className)}>
      <BaseListItemSepearateTouchArea
        centerEndNeedsPadding={props.centerEndNeedsPadding}
        start={!props.hideStart ? props.startContent : null}
        centerStart={props.centerContent}
        centerEnd={!props.hideCenterEnd ? <div className="list-item__center-end">{props.centerEnd}</div> : null}
        end={!props.hideEnd ? props.endContent : null}
        hideStart={props.hideStart}
        hideCenterEnd={props.hideCenterEnd}
        hideEnd={props.hideEnd}
        disableTouchAnimation={props.disableTouchAnimation}
        multiline={props.multiline}
        isNaviAndAsia={props.isNaviAndAsia}
        onTap={props.onTap}
        onTapEnd={props.onTapEnd}
        active={props.active}
      />
    </div>
  )
}

export default ListItemNaviAnythingAnywhere
