// This is a definition of setting data. It is a structure for everything that
// could go into settings.

import objtools from "@pag/utils/object-tools"
import icon from "@pag/center/components/icon/icons"
import { ESourceNames } from "@pag/center/components/player/audioPlayer/types"
import SliderTypeEnum from "@pag/center/components/discreteNumericSlider/types"

// Because settings data isn’t editable (you cannot add new languages from the
// UI) all the objects that it produces a frozen. That allows us to compare
// them directly.

const obj = (parent = null) => Object.create(parent)

type Entry = {
  label: string
  value?: any
  min?: number
  step?: number
}

// @NOTE(kirill): Creates an immutable array of immutable objects, needed when
// there’s use for read-only definitions. If you try to write to it you’ll get
// an error. That would let you know that you have a bug. Otherwise you might
// end up with code that changes things that aren’t supposed to change.

function populate(array: any[], names: string[] = [ "label", "value" ]): Readonly<Entry[]> {
  return Object.freeze(array.map((element) => Object.freeze(
    Array.isArray(element)
    ? element.reduce(
      (result, item, index) => {
        result[names[index]] = item
        return result
      },
      obj()
    )
    : { label: element }
  )))
}

const langs = populate([
  [ "Settings_System_LanguageAndKeyboard_Language_Bosnian",    "bs_BA" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Czech",      "cs_CZ" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Danish",     "da_DK" ],
  [ "Settings_System_LanguageAndKeyboard_Language_German",     "de_DE" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Greek",      "el_GR" ],
  [ "Settings_System_LanguageAndKeyboard_Language_EnglishUSA", "en_US" ],
  [ "Settings_System_LanguageAndKeyboard_Language_EnglishGB",  "en_GB" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Spanish",    "es_ES" ],
  [ "Settings_System_LanguageAndKeyboard_Language_French",     "fr_FR" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Croatian",   "hr_HR" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Italian",    "it_IT" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Hungarian",  "hu_HU" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Dutch",      "nl_NL" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Norwegian",  "no_NO" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Polish",     "pl_PL" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Portuguese", "pt_PT" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Romanian",   "ro_RO" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Russian",    "ru_RU" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Slovenian",  "sl_SI" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Slovakian",  "sk_SK" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Serbian",    "sr_RS" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Finnish",    "fi_FI" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Swedish",    "sv_SE" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Turkish",    "tr_TR" ],
  [ "Settings_System_LanguageAndKeyboard_Language_Ukrainian",  "uk_UA" ],

  // @TODO(kirill): Those are in translation dump but not in the video. The other thing
  // is the ones with `undefined`, we have labels for them but no translations.

  // [ "Settings_System_LanguageAndKeyboard_Language_Arabic",           "ar_SA"   ],
  // [ "Settings_System_LanguageAndKeyboard_Language_Azerbaijani",      undefined ],
  // [ "Settings_System_LanguageAndKeyboard_Language_Cantonese",        "yue_HK"  ],
  // [ "Settings_System_LanguageAndKeyboard_Language_Chinese",          "zh_CN"   ],
  // [ "Settings_System_LanguageAndKeyboard_Language_EnglishArabic",    undefined ],
  // [ "Settings_System_LanguageAndKeyboard_Language_EnglishChina",     "en_CN"   ],
  // [ "Settings_System_LanguageAndKeyboard_Language_EnglishJapan",     "en_JP"   ],
  // [ "Settings_System_LanguageAndKeyboard_Language_EnglishKorea",     "en_KR"   ],
  // [ "Settings_System_LanguageAndKeyboard_Language_EnglishTaiwan",    "en_TW"   ],
  // [ "Settings_System_LanguageAndKeyboard_Language_FrenchCanada",     "fr_CA"   ],
  // [ "Settings_System_LanguageAndKeyboard_Language_Japanese",         "ja_JP"   ],
  // [ "Settings_System_LanguageAndKeyboard_Language_Korean",           "ko_KR"   ],
  // [ "Settings_System_LanguageAndKeyboard_Language_PortugueseBrazil", "pt_BR"   ],
  // [ "Settings_System_LanguageAndKeyboard_Language_SpanishMexico",    "es_MX"   ],
  // [ "Settings_System_LanguageAndKeyboard_Language_Taiwanese",        "zh_TW"   ],
])

const keyboard_langs = populate([
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Bosnian",       "bs_BA" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Czech",         "cs_CZ" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Danish",        "da_DK" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_GERMAN_QWERTZ", "de_DE" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Greek",         "el_GR" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_English_US",    "en_US" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_English_GB",    "en_GB" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Spanish_ES",    "es_ES" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_French",        "fr_FR" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Croatian",      "hr_HR" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Italian",       "it_IT" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Hungarian",     "hu_HU" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Dutch",         "nl_NL" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Norwegian",     "no_NO" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Polish",        "pl_PL" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Portuguese",    "pt_PT" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Russian",       "ru_RU" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Slovenian",     "sl_SI" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Slovakian",     "sk_SK" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Serbian",       "sr_RS" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Finnish",       "fi_FI" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Swedish",       "sv_SE" ],
  [ "Settings_System_LanguageAndKeyboard_Keyboard_Turkish",       "tr_TR" ],

  // [ "Settings_System_LanguageAndKeyboard_Keyboard_Arabic",                 undefined ],
  // [ "Settings_System_LanguageAndKeyboard_Keyboard_Chinese_Simplified",     undefined ],
  // [ "Settings_System_LanguageAndKeyboard_Keyboard_Chinese_Traditional",    undefined ],
  // [ "Settings_System_LanguageAndKeyboard_Keyboard_French_CA",              undefined ],
  // [ "Settings_System_LanguageAndKeyboard_Keyboard_Japanese_Full_Hiragana", undefined ],
  // [ "Settings_System_LanguageAndKeyboard_Keyboard_Japanese_Hiragana",      undefined ],
  // [ "Settings_System_LanguageAndKeyboard_Keyboard_Korean",                 undefined ],
  // [ "Settings_System_LanguageAndKeyboard_Keyboard_Portuguese_BR",          undefined ],
  // [ "Settings_System_LanguageAndKeyboard_Keyboard_Spanish_MX",             undefined ],
  // [ "Settings_System_LanguageAndKeyboard_Keyboard_Spanish_SA",             undefined ],
  // [ "Settings_System_LanguageAndKeyboard_Keyboard_Taiwanese",              undefined ],
])

// @NOTE(kirill): Time is frozen around here.
const today = (function () {
  const now = new Date()
  now.setHours(9)
  now.setMinutes(11)
  return new Date(now)
}())

const timezones = populate([
  "GMT +01:00 Berlin"
])

const timeFormats = populate([
  "Settings_DateAndTime_SetTimeFormat_24h",
  "Settings_DateAndTime_SetTimeFormat_12h"
])

const dateFormats = populate([
  "Settings_System_DateAndTime_SetDateManually_SlideOut1",
  "Settings_System_DateAndTime_SetDateManually_SlideOut2",
  "Settings_System_DateAndTime_SetDateManually_SlideOut3"
])

const timeTemp = populate([
  "Settings_System_TimeAndTemperature_Time",
  "Settings_System_TimeAndTemperature_Temperature"
])

const speedUnits = populate([
  "Settings_System_Units_SpeedAndDistance_Speed_kmh",
  "Settings_System_Units_SpeedAndDistance_Speed_mph"
])

const distanceUnits = populate(
  [
    [
      "Settings_System_Units_SpeedAndDistance_Distance_km",
      1000 // 1 km
    ],
    [
      "Settings_System_Units_SpeedAndDistance_Distance_mi",
      1609.344 // 1 mile
    ],
    [
      "Settings_System_Units_SpeedAndDistance_Distance_m",
      0
    ],
    [
      "Settings_System_Units_SpeedAndDistance_Distance_ft",
      0
    ],
  ],
  [ "label", "min" ]
)

const speedAndDistanceUnits = populate([
  "Settings_System_Units_SpeedAndDistance_kmh",
  "Settings_System_Units_SpeedAndDistance_mph",
])

const chargeSpeedUnits = populate([
  "Settings_System_Units_Charge_km_min",
  "Settings_System_Units_Charge_mi_min"
])

const temperatureUnits = populate(
  [
    [
      "Settings_System_Units_Temperature_Celius",
      0.5
    ],
    [
      "Settings_System_Units_Temperature_Fahrenheit",
      1
    ]
  ],
  [ "label", "step" ]
)

const pressureUnits = populate([
  "Settings_System_Units_Pressure_bar",
  "Settings_System_Units_Pressure_psi",
  "Settings_System_Units_Pressure_kpa"
])

const consumptionUnits = populate([
  "Settings_System_Units_Consumption_kwh_100km",
  "Settings_System_Units_Consumption_km_kwh",
  "Settings_System_Units_Consumption_kwh_100mi",
  "Settings_System_Units_Consumption_mi_kwh"
])

const touchTones = populate([
  "Settings_TouchTone_Off",
  "Settings_TouchTone_Quiet",
  "Settings_TouchTone_Medium",
  "Settings_TouchTone_Loud"
])

const displayScope = populate([
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_Assistance",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_G-Force",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_All-wheel",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_PDCC",
  // "Settings_Display_InstrumentCluster_ConfigureDisplayScope_TyreInfo",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_Map",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_ExtendedMap",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_NightViewAssist",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_Reduced",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_Navigation",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_Trip",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_SportChrono",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_DriveMode",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_Media",
  "Settings_Display_InstrumentCluster_ConfigureDisplayScope_Online",
])

const displayCustomizedView = populate([
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_Altitude",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_Arrival",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_Compass",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_Date",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_Decelaration",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_DrivingTimeAndDistance",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_LateralAcceleration",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_LongitudinalAcceleration",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_PhoneInfo",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_StationOrTrack",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_Voltage",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_BatteryTemp",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_EmptyLine",
  "Settings_Display_InstrumentCluster_CustomisedView_Slideout_SOC"
])

const hapticFeedback = populate([
  "Settings_HapticFeedback_Off",
  "Settings_HapticFeedback_Low",
  "Settings_HapticFeedback_Med",
  "Settings_HapticFeedback_High"
])

const focusMovementSpeed = populate([
  "Settings_Display_CenterConsoleOperatingPanel_FocusMovementSpeed_Slow",
  "Settings_Display_CenterConsoleOperatingPanel_FocusMovementSpeed_Moderate",
  "Settings_Display_CenterConsoleOperatingPanel_FocusMovementSpeed_Fast"
])

const hudView = populate([
  "Settings_Display_HUD_View_Slideout1",
  "Settings_Display_HUD_View_Slideout2",
  "Settings_Display_HUD_View_Slideout3",
  "Settings_Display_HUD_View_Slideout4",
  "Settings_Display_HUD_View_Slideout5",
  "Settings_Display_HUD_View_Slideout6",
  "Settings_Display_HUD_View_Slideout7"
])

const hudDayNightView = populate([
  "Settings_Display_HUD_DayAndNightView_Slideout1",
  "Settings_Display_HUD_DayAndNightView_Slideout2",
  "Settings_Display_HUD_DayAndNightView_Slideout3"
])

const hudIncomingCalls = populate([
  "Settings_Display_HUD_IncomingCalls_Slideout1",
  "Settings_Display_HUD_IncomingCalls_Slideout2"
])

const hudCustomAdjustMainArea = populate([
  "Settings_Display_HUD_Custom_Adjust_MainArea_1",
  "Settings_Display_HUD_Custom_Adjust_MainArea_2"
])

const phoneBookSortingCriteria = populate([
  "Settings_Phone_PhoneBookSettings_SortingCriteria_Slideout1",
  "Settings_Phone_PhoneBookSettings_SortingCriteria_Slideout2",
  "Settings_Phone_PhoneBookSettings_SortingCriteria_Slideout3"
])

const chargingStopsPower = populate([
  "Settings_Navigation_RouteOptions_PorscheCharging_MinChargingPower_KW100",
  "Settings_Navigation_RouteOptions_PorscheCharging_MinChargingPower_KW150",
  "Settings_Navigation_RouteOptions_PorscheCharging_MinChargingPower_KW250",
  "Settings_Navigation_RouteOptions_PorscheCharging_MinChargingPower_NoRestriction"
])

const parkAssistVolume = populate([
  "Settings_Volume_ParkAssist_Quiet",
  "Settings_Volume_ParkAssist_Medium",
  "Settings_Volume_ParkAssist_Loud"
])

const laneDepartureVolume = populate([
  "Settings_Volume_LaneDeparture_Off",
  "Settings_Volume_LaneDeparture_Quiet",
  "Settings_Volume_LaneDeparture_Medium",
  "Settings_Volume_LaneDeparture_Loud",
])

const navigationAvoid = populate(
  [
    [ "Settings_Navigation_RouteOptions_Avoid_Freeway",              icon.freeway                   ],
    [ "Settings_Navigation_RouteOptions_Avoid_TollRoads",            icon.freeway_toll              ],
    [ "Settings_Navigation_RouteOptions_Avoid_Ferries",              icon.avoid_ferry_car_train_not ],
    [ "Settings_Navigation_RouteOptions_Avoid_Tunnels",              icon.tunnel                    ],
    [ "Settings_Navigation_RouteOptions_Avoid_SeasonalRestrictions", icon.altitude                  ]
  ],
  [ "label", "icon" ]
)

const navigationDayAndNightView = populate([
  "Settings_Navigation_NavigationSettings_MapSettings_DayAndNightView_Day",
  "Settings_Navigation_NavigationSettings_MapSettings_DayAndNightView_Night",
  "Settings_Navigation_NavigationSettings_MapSettings_DayAndNightView_Auto"
])

const privacyModes = populate([
  "Settings_PrivacyAndPorscheConnect_PrivateMode_Slideout1",
  "Settings_PrivacyAndPorscheConnect_PrivateMode_Slideout2"
])

const vehicleSpoilerPosition = populate([
  "Settings_Vehicle_ManualCleaningPositionSpoiler_Retracted",
  "Settings_Vehicle_ManualCleaningPositionSpoiler_Extended"
])

const vehicleParkingBrake = populate([
  "Settings_Vehicle_ParkingBrake_Closed",
  "Settings_Vehicle_ParkingBrake_Open"
])

const vehicleDoorUnlocking = populate([
  "Settings_Vehicle_VehicleLockingSystems_DoorUnlocking_Slideout1",
  "Settings_Vehicle_VehicleLockingSystems_DoorUnlocking_Slideout2",
  // "Settings_Vehicle_VehicleLockingSystems_DoorUnlocking_Slideout3",
  "Settings_Vehicle_VehicleLockingSystems_DoorUnlocking_Slideout4"
])

const vehicleAutoUnlock = populate([
  "Settings_Vehicle_VehicleLockingSystems_AutoUnlock_Slideout1",
  "Settings_Vehicle_VehicleLockingSystems_AutoUnlock_Slideout2",
  "Settings_Vehicle_VehicleLockingSystems_AutoUnlock_Slideout3"
])

const vehicleTyrePressureSelection = populate([
  "Settings_Vehicle_TyrePressureMonitoring_Selection_Slideout1",
  "Settings_Vehicle_TyrePressureMonitoring_Selection_Slideout2",
  "Settings_Vehicle_TyrePressureMonitoring_Selection_Slideout3"
])

const vehicleTyrePressureLoad = populate([
  "Settings_Vehicle_TyrePressureMonitoring_FullLoad",
  "Settings_Vehicle_TyrePressureMonitoring_PartLoad"
])

const vehicleShortcutButtons = populate([
  "Settings_Vehicle_ShortcutButtons_Slideout1",
  "Settings_Vehicle_ShortcutButtons_Slideout2",
  "Settings_Vehicle_ShortcutButtons_Slideout3",
  "Settings_Vehicle_ShortcutButtons_Slideout4"
])

const assistanceWarningTime = populate([
  "Settings_AssistanceSystems_NightViewAssist_WarningTime_Slideout1",
  "Settings_AssistanceSystems_NightViewAssist_WarningTime_Slideout2",
  "Settings_AssistanceSystems_NightViewAssist_WarningTime_Slideout3"
])

const assistanceSpeedLimitsAdoption = populate([
  "Settings_AssistanceSystems_AdaptiveSpeedLimiter_AdoptionOfSpeedlimits_SlideOut1",
  "Settings_AssistanceSystems_AdaptiveSpeedLimiter_AdoptionOfSpeedlimits_SlideOut2",
  "Settings_AssistanceSystems_AdaptiveSpeedLimiter_AdoptionOfSpeedlimits_SlideOut3",
  "Settings_AssistanceSystems_AdaptiveSpeedLimiter_AdoptionOfSpeedlimits_SlideOut4"
])

const assistanceParkAssistVolume = populate([
  "Settings_AssistanceSystems_ParkAssist_Volume_Slideout1",
  "Settings_AssistanceSystems_ParkAssist_Volume_Slideout2",
  "Settings_AssistanceSystems_ParkAssist_Volume_Slideout3"
])

const assistanceCollisionWarning = populate([
  "Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning_Slideout1",
  "Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning_Slideout2",
  "Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning_Slideout3",
  "Settings_AssistanceSystems_WarnAndBrakeAssist_CollisionWarning_Slideout4"
])

const assistanceDistanceWarning = populate([
  "Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning_Slideout1",
  "Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning_Slideout2",
  "Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning_Slideout3",
  "Settings_AssistanceSystems_WarnAndBrakeAssist_DistanceWarning_Slideout4"
])

const assistanceLaneDepartureWarning = populate([
  "Settings_AssistanceSystems_LaneDepartureWarning_Volume_Slideout1",
  "Settings_AssistanceSystems_LaneDepartureWarning_Volume_Slideout2",
  "Settings_AssistanceSystems_LaneDepartureWarning_Volume_Slideout3"
])

const assistanceLaneChangeBrightness = populate([
  "Settings_AssistanceSystems_LaneChangeAssist_Brightness_Slideout1",
  "Settings_AssistanceSystems_LaneChangeAssist_Brightness_Slideout2",
  "Settings_AssistanceSystems_LaneChangeAssist_Brightness_Slideout3"
])

const driveRangeModeAirConditioning = populate([
  "Settings_RangeDriveMode_Configuration_Air_Conditioning_EcoModus",
  "Settings_RangeDriveMode_Configuration_Air_Conditioning_FanOnly"
])

const notificationCenterAllow = populate([
  "Settings_NotificationCenter_Allow_Slideout1",
  "Settings_NotificationCenter_Allow_Slideout2",
  "Settings_NotificationCenter_Allow_Slideout3",
  "Settings_NotificationCenter_Allow_Slideout4",
  "Settings_NotificationCenter_Allow_Slideout5",
  "Settings_NotificationCenter_Allow_Slideout6",
  "Settings_NotificationCenter_Allow_Slideout7"
])

const calendarWeekStart = populate([
  "Calendar_Settings_StartWeek_Slideout1",
  "Calendar_Settings_StartWeek_Slideout2",
  "Calendar_Settings_StartWeek_Slideout3",
  "Calendar_Settings_StartWeek_Slideout4",
  "Calendar_Settings_StartWeek_Slideout5",
  "Calendar_Settings_StartWeek_Slideout6",
  "Calendar_Settings_StartWeek_Slideout7",
  "Calendar_Settings_StartWeek_Slideout8"
])

const mediaSources = populate(
  [
    [ "MediaAppRootRouter_SourceQuickFilterList_Favorites",     ESourceNames.FAVORITES,      icon.star,                 undefined                      ],
    [ "MediaAppRootRouter_SourceQuickFilterList_Radio",         ESourceNames.RADIO,          icon.fm_dab_radio,         undefined                      ],
    [ "MediaAppRootRouter_SourceQuickFilterList_Spotify",       ESourceNames.SPOTIFY,        icon.logo_spotify,         icon.logo_spotify_color        ],
    [ "MediaAppRootRouter_SourceQuickFilterList_Sdars",         ESourceNames.SIRIUS_XM,      icon.logo_sirius_xm_short, undefined                      ],
    [ "MediaAppRootRouter_SourceQuickFilterList_ApplePodcasts", ESourceNames.APPLE_PODCASTS, icon.logo_apple_podcasts,  icon.logo_apple_podcasts_color ],
    [ "MediaAppRootRouter_SourceQuickFilterList_Carplay",       ESourceNames.APPLE_CARPLAY,  icon.apple_carplay,        undefined                      ],
    [ "MediaAppRootRouter_SourceQuickFilterList_AndroidAuto",   ESourceNames.ANDROID_AUTO,   icon.logo_android_auto,    undefined                      ],
    [ "MediaAppRootRouter_SourceQuickFilterList_OnlineMedia",   ESourceNames.APPLE_MUSIC,    icon.logo_apple_music,     icon.logo_apple_music_color    ],
    [ "MediaAppRootRouter_SourceQuickFilterList_OnlineRadio",   ESourceNames.ONLINE_RADIO,   icon.online_radio,         undefined                      ],
    [ "MediaAppRootRouter_SourceQuickFilterList_Usb",           ESourceNames.USB,            icon.usb,                  undefined                      ],
    [ "MediaAppRootRouter_SourceQuickFilterList_Bluetooth",     ESourceNames.BLUETOOTH,      icon.source_bluetooth,     undefined                      ]
  ],
  [ "label", "name", "icon", "icon-color" ]
)

// @NOTE(kirill): Should be removed.
const ambient_colors = objtools.populate(
  "label", "var-name"
)(
  [ "Comfort_Color_Cool_White",  "cool-white"  ],
  [ "Comfort_Color_Warm_White",  "warm-white"  ],
  [ "Comfort_Color_Deep_Orange", "deep-orange" ],
  [ "Comfort_Color_Poppy",       "poppy"       ],
  [ "Comfort_Color_Topaz_Blue",  "topaz-blue"  ],
  [ "Comfort_Color_Atlantis",    "atlantis"    ],
  [ "Comfort_Color_Lime",        "lime"        ],
  [ "Comfort_Color_Dynamic",     "dynamic"     ]
)

const climateFootwellTemperature = objtools.populate(
  "label"
)(
  [ "Settings_Climate_FootwellTemperature_Warmer" ],
  [ "Settings_Climate_FootwellTemperature_Normal" ],
  [ "Settings_Climate_FootwellTemperature_Colder" ]
)

const brightness = Object.freeze({
  min: 9,
  max: 0,
  suffix: "",
  middlePoint: 0,
  middle: 0,
})

const volume = Object.freeze({
  min: 0,
  max: 5,
  suffix: "",
  middlePoint: 0,
  middle: 0,
})

// SPEED
const msInKmh = 5 / 18
const kmhToMs = (num: number): number => num * msInKmh

const speedDeviation = Object.freeze({
  min: 0,
  max: kmhToMs(10),
  suffix: "Settings_System_Units_Speed"
})

const speedLimit = Object.freeze({
  min: 0,
  max: kmhToMs(200),
  step: 10,
  middlePoint: 0,
  sliderType: SliderTypeEnum.KmPerHour,
  suffix: "Settings_System_Units_Speed"
})

const adaptiveSpeedLimit = Object.freeze({
  min: kmhToMs(30),
  max: kmhToMs(210),
  step: 10,
  middlePoint: 0,
  sliderType: SliderTypeEnum.KmPerHour,
  suffix: "Settings_System_Units_Speed"
})

const rangeModeSpeedLimit = Object.freeze({
  min: kmhToMs(90),
  max: kmhToMs(140),
  step: 10,
  middlePoint: 0,
  sliderType: SliderTypeEnum.KmPerHour,
  suffix: "Settings_System_Units_Speed"
})

const charge = Object.freeze({
  middle: 0,
  min: 3,
  max: 80,
  suffix: "%",
  unit: "%",
  sliderType: SliderTypeEnum.Charge
})

const navigationAnnouncements = Object.freeze({
  min: 0,
  max: 11,
  suffix: ""
})

const lightAfterGlow = Object.freeze({
  min: 10,
  max: 240,
  step: 10,
  middlePoint: 0,
  suffix: "s"
})

const fadeOutDuration = Object.freeze({
  min: 0,
  max: 90,
  step: 5,
  middlePoint: 0,
  suffix: "s",
  sliderType: SliderTypeEnum.Default,
  unit: "s"
})

const lightBrightness = Object.freeze({
  min: 0,
  max: 100,
  step: 5,
  suffix: "%"
})

const ambientBrightness = Object.freeze({
  min: 0,
  max: 100,
  step: 5,
  sliderType: SliderTypeEnum.Percentage,
  unit: "%"
})

const heating_balance = Object.freeze({
  min: -3,
  max: 3,
  middle: 0.5,
  topLabel: "Car_Comfort_Heating_Slider_Top",
  bottomLabel: "Car_Comfort_Heating_Slider_Bottom",
  sliderType: SliderTypeEnum.WithoutUnitOfMeasure
})

const ventilation_balance = Object.freeze({
  min: -3,
  max: 3,
  middle: 0.5,
  topLabel: "Car_Comfort_Ventilation_Slider_Top",
  bottomLabel: "Car_Comfort_Ventilation_Slider_Bottom",
  sliderType: SliderTypeEnum.WithoutUnitOfMeasure
})

const contrast = Object.freeze({
  min: -9,
  max: 9,
  step: 1,
  suffix: ""
})

const volumeReduction = Object.freeze({
  min: 0,
  max: 5,
  step: 0.5,
  suffix: ""
})

const chronoMaxDelta = Object.freeze({
  min: 0.5,
  max: 3,
  step: 0.5,
  suffix: "%"
})

const chronoDeviation = Object.freeze({
  min: 5,
  max: 30,
  step: 5,
  suffix: "%"
})

export default Object.freeze({
  langs,
  keyboard_langs,
  today,
  timezones,
  timeFormats,
  dateFormats,
  timeTemp,
  speedAndDistanceUnits,
  distanceUnits,
  chargeSpeedUnits,
  speedUnits,
  temperatureUnits,
  pressureUnits,
  consumptionUnits,
  ambient_colors,
  touchTones,
  brightness,
  displayScope,
  displayCustomizedView,
  hapticFeedback,
  focusMovementSpeed,
  speedDeviation,
  speedLimit,
  hudView,
  hudDayNightView,
  hudIncomingCalls,
  hudCustomAdjustMainArea,
  phoneBookSortingCriteria,
  volume,
  parkAssistVolume,
  laneDepartureVolume,
  charge,
  chargingStopsPower,
  navigationAvoid,
  navigationAnnouncements,
  navigationDayAndNightView,
  privacyModes,
  vehicleSpoilerPosition,
  vehicleParkingBrake,
  vehicleShortcutButtons,
  vehicleDoorUnlocking,
  vehicleAutoUnlock,
  vehicleTyrePressureSelection,
  vehicleTyrePressureLoad,
  lightAfterGlow,
  fadeOutDuration,
  lightBrightness,
  contrast,
  assistanceWarningTime,
  adaptiveSpeedLimit,
  rangeModeSpeedLimit,
  assistanceSpeedLimitsAdoption,
  volumeReduction,
  assistanceParkAssistVolume,
  assistanceCollisionWarning,
  assistanceDistanceWarning,
  assistanceLaneDepartureWarning,
  assistanceLaneChangeBrightness,
  ambientBrightness,
  heating_balance,
  ventilation_balance,
  driveRangeModeAirConditioning,
  climateFootwellTemperature,
  chronoMaxDelta,
  chronoDeviation,
  notificationCenterAllow,
  calendarWeekStart,
  mediaSources
})
