import React from "react"

import "./scrollPositionIndicatorPixel.scss"

interface IScrollPositionIndicatorPixel {
  handleBarHeight: number
  handleBarPosition: number
  alfanumeric?: boolean
}
const ScrollPositionIndicatorPixel: React.FC<IScrollPositionIndicatorPixel> = (props) => {
  const handleStyle = {
    height: props.handleBarHeight + "px",
    transform: "translateY(" + props.handleBarPosition + "px)"
  }

  return (
    <div className={"scrollposition-indicator__wrapper" + (props.alfanumeric ? " scrollposition-indicator__wrapper--alfanumeric" : "")}>
      <div className="scrollposition-indicator__rail"></div>
      <div style={handleStyle} className="scrollposition-indicator__handle"></div>
    </div>
  )
}

export default ScrollPositionIndicatorPixel
