import object from "@pag/utils/object-tools"
import icon from "@pag/center/components/icon/icons"
import { Device, Service } from "./types"

const services: readonly Service[] = object.populate(
  "icon"
)(
  [ icon.cell_phone        ],
  [ icon.note              ],
  [ icon.message_outline   ],
  [ icon.connectivity      ],
  [ icon.apple_carplay     ],
  [ icon.logo_android_auto ],
  [ icon.connect_app       ],
  [ icon.wlan              ],
  [ icon.bluetooth         ],
  [ icon.ipod              ]
)

const [
  phone,
  media,
  messages,
  connectivity,
  carplay,
  android_auto,
  connect_app,
  hotspot,
  headset,
  ipod
] = services

function get_id() {
  return crypto.getRandomValues(new Uint16Array(1))[0]
}

const devices: readonly Device[] = object.populate(
    "name",                   "icon",          "services",                               "id"
)(
  [ "John Smith's iPhone",    icon.cell_phone, [ phone, media, messages, carplay ],      get_id() ],
  [ "Maria Bianchi's iPhone", icon.cell_phone, [ phone, media, messages, carplay ],      get_id() ],
  [ "John Doe's Phone",       icon.cell_phone, [ phone, media, messages, android_auto ], get_id() ],
)

export default Object.freeze({
  services,
  devices,

  phone_service: phone,
  media_service: media,
  carplay_service: carplay,
  connectivity_service: connectivity,
  android_auto_service: android_auto,
  connect_app_service: connect_app,
  hotspot_service: hotspot,
  headset_service: headset,
  ipod_service: ipod
});
