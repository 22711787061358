import { IconType } from "@pag/center/components/icon/types"
import icon from "@pag/center/components/icon/icons"
import { WarnMessageQuickActions } from "./warn-message-quick-actions"

export enum WarnMessageColor {
  white = 0,
  yellow,
  red,
  green,
  grey
}

export type WarnMessage = {
  warnID: number
  filter?: EFilter
  color: WarnMessageColor
  text: string
  detailText: string
  icon: IconType | undefined
  prio: number
  quickActions: WarnMessageQuickActions[]
  receipted?: boolean
  clicked?: boolean
}

enum EFilter {
  CAR = "car",
  INFO = "info",
  MESSAGE = "message"
}

export const WARN_MESSAGES = new Map([
  [
    55,
    {
      warnID: 55,
      filter: EFilter.CAR,
      text: "Function on Demand",
      detailText: "Porsche Entry & Drive",
      color: WarnMessageColor.white,
      icon: icon.update_center,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Update]
    }
  ],
  [
    1,
    {
      warnID: 1,
      text: "Bremsflüssigkeit niedrig, Fahrzeug verkehrssicher abstellen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.brake_failure,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    5,
    {
      warnID: 5,
      text: "Motorleistung reduziert Weiterfahrt möglich Werkstatt aufsuchen",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.car_limousine_warning,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    7,
    {
      warnID: 7,
      text: "Bremskraftverteilung gestört, Fahrzeug verkehrssicher abstellen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.warning_filled,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    8,
    {
      warnID: 8,
      text: "PSM ausgefallen. Werkstatt aufsuchen, angepasste Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.esp_psm_off,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    9,
    {
      warnID: 9,
      text: "ABS/PSM ausgefallen, angepasste Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.abs_failure,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    16,
    {
      warnID: 16,
      text: "Motorsteuerung gestört Weiterfahrt möglich Werkstatt aufsuchen",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.car_limousine_warning,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    18,
    {
      warnID: 18,
      text: "Getriebe ausgefallen, Fahrzeug verkehrssicher abstellen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.pos_lever,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    19,
    {
      warnID: 19,
      text: "Spoiler ausgefallen, Geschwindigkeit reduzieren",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.car_limousine_spoiler,
      prio: 2,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    20,
    {
      warnID: 20,
      text: "Spoiler gestört, angepasste Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.car_limousine_spoiler,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    22,
    {
      warnID: 22,
      text: "Reichweite beachten",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.charging_station,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Ladestation]
    }
  ],
  [
    26,
    {
      warnID: 26,
      text: "Bremsbelag verschlissen. Bremsbelag wechseln, weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.brake_worn_linings,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    28,
    {
      warnID: 28,
      text: "Batterie schwach, Motor starten und längere Zeit laufen lassen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.voltage,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    33,
    {
      warnID: 33,
      text: "Bordnetz gestört, Service notwendig",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.voltage,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    35,
    {
      warnID: 35,
      text: "Airbagsystem Systemfehler, Werkstatt aufsuchen",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.airbag_warning,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    36,
    {
      warnID: 36,
      text: "Fußgängerschutz ausgelöst. Motorhaube schließen, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    37,
    {
      warnID: 37,
      text: "Fußgängerschutz Systemfehler. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    44,
    {
      warnID: 44,
      text: "Heckklappe offen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: null,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    53,
    {
      warnID: 53,
      text: "Außentemperaturanzeige defekt",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.car_limousine_outside_temp,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    54,
    {
      warnID: 54,
      filter: EFilter.CAR,
      text: "Notification_Center_Warn_Message_Washerfluid",
      detailText: "Notification_Center_Warn_Message_Washerfluid_detail",
      color: WarnMessageColor.white,
      icon: icon.front_window_wiping_water,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    56,
    {
      warnID: 56,
      text: "Lenkung gesperrt",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.steering_wheel,
      prio: 2,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    57,
    {
      warnID: 57,
      text: "Startknopf gestört, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.key,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    58,
    {
      warnID: 58,
      text: "Schlüssel nicht gefunden/gestört, Position des Schlüssels ändern",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.key,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    60,
    {
      warnID: 60,
      text: "Startknopf defekt, Fahrzeug verkehrssicher abstellen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.key,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    61,
    {
      warnID: 61,
      text: "Batterie im Schlüssel schwach, Schlüsselbatterie austauschen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.key,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    67,
    {
      warnID: 67,
      text: "Störung Kühlsystem, bitte Werkstatt aufsuchen",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.engine_coolant_level,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    77,
    {
      warnID: 77,
      text: "Fahrzeugniveau extrem tief. Nicht losfahren, Fahrbereitschaft abwarten.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.car_limousine_niveau,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    78,
    {
      warnID: 78,
      text: "Fahrwerk gestört. Werkstatt aufsuchen, angepasste Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.pasm_warning,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    79,
    {
      warnID: 79,
      text: "Fahrzeugniveau extrem hoch, angepasste Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.car_limousine_niveau,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    84,
    {
      warnID: 84,
      text: "Fahrwerk gestört. Angepasste Weiterfahrt möglich, Betriebsanleitung beachten.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.pdcc_j1,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    85,
    {
      warnID: 85,
      text: "Fahrwerk ausgefallen. Angepasste Weiterfahrt möglich, Betriebsanleitung beachten.",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.pdcc_j1,
      prio: 2,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    86,
    {
      warnID: 86,
      text: "Reifen prüfen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.warning_tire_pressure,
      prio: 2,
      // tslint:disable-next-line:max-line-length
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf,
        WarnMessageQuickActions.Reifendruckkontrolle
      ]
    }
  ],
  [
    87,
    {
      warnID: 87,
      text: "Luft auffüllen",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.warning_tire_pressure,
      prio: 5,
      // tslint:disable-next-line:max-line-length
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf,
        WarnMessageQuickActions.Reifendruckkontrolle
      ]
    }
  ],
  [
    89,
    {
      warnID: 89,
      text: "Reifendruck wird nicht überwacht, System lernt ab {{tyrePressureSpeedAndUnit}}",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_tire_pressure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Pannenruf,
        WarnMessageQuickActions.Reifendruckkontrolle
      ]
    }
  ],
  [
    90,
    {
      warnID: 90,
      text: "Reifendruckkontrolle gestört, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_tire_pressure,
      prio: 5,
      // tslint:disable-next-line:max-line-length
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf,
        WarnMessageQuickActions.Reifendruckkontrolle
      ]
    }
  ],
  [
    91,
    {
      warnID: 91,
      text: "System kurzzeitig nicht aktiv. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_tire_pressure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Pannenruf,
        WarnMessageQuickActions.Reifendruckkontrolle
      ]
    }
  ],
  [
    93,
    {
      warnID: 93,
      text: "Radwechsel erkannt, neue Auswahl treffen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_tire_pressure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Pannenruf,
        WarnMessageQuickActions.Reifendruckkontrolle
      ]
    }
  ],
  [
    94,
    {
      warnID: 94,
      text: "Allradsystem gestört, angepasste Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon["4WD"],
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    99,
    {
      warnID: 99,
      text: "Inspektion in %6 Tagen / %7 %8",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.service,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    100,
    {
      warnID: 100,
      text: "Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.service,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    104,
    {
      warnID: 104,
      text: "Blinker vorne links defekt, Blinker überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    105,
    {
      warnID: 105,
      text: "Standlicht vorne links defekt, Standlicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    106,
    {
      warnID: 106,
      text: "Abblendlicht vorne links defekt, Abblendlicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    107,
    {
      warnID: 107,
      text: "Fernlicht links defekt, Fernlicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    108,
    {
      warnID: 108,
      text: "Nebelscheinwerfer links defekt, Nebelscheinwerfer überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    109,
    {
      warnID: 109,
      text: "Blinker links an der Seite defekt, Blinker überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    110,
    {
      warnID: 110,
      text: "Blinker vorne rechts defekt, Blinker überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    111,
    {
      warnID: 111,
      text: "Standlicht vorne rechts defekt, Standlicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    112,
    {
      warnID: 112,
      text: "Abblendlicht vorne rechts defekt, Abblendlicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    113,
    {
      warnID: 113,
      text: "Fernlicht rechts defekt, Fernlicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    114,
    {
      warnID: 114,
      text: "Nebelscheinwerfer rechts defekt, Nebelscheinwerfer überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    115,
    {
      warnID: 115,
      text: "Blinker rechts an der Seite defekt, Blinker überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    116,
    {
      warnID: 116,
      text: "Bremslicht links defekt, Bremslicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    117,
    {
      warnID: 117,
      text: "Schlusslicht links defekt, Schlusslicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    118,
    {
      warnID: 118,
      text: "Rückfahrlicht links defekt, Rückfahrlicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    119,
    {
      warnID: 119,
      text: "Nebelschlusslicht links defekt, Nebelschlusslicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    120,
    {
      warnID: 120,
      text: "Bremslicht rechts defekt, Bremslicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    121,
    {
      warnID: 121,
      text: "Schlusslicht rechts defekt, Schlusslicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    122,
    {
      warnID: 122,
      text: "Rückfahrlicht rechts defekt, Rückfahrlicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    123,
    {
      warnID: 123,
      text: "Nebelschlusslicht rechts defekt, Nebelschlusslicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    124,
    {
      warnID: 124,
      text: "Kennzeichenlicht hinten defekt, Kennzeichenlicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    125,
    {
      warnID: 125,
      text: "Bremslicht oben defekt, Bremslicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    126,
    {
      warnID: 126,
      text: "Blinker hinten rechts defekt, Blinker überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    127,
    {
      warnID: 127,
      text: "Blinker hinten links defekt, Blinker überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    128,
    {
      warnID: 128,
      text: "Tagfahrlicht links defekt, Tagfahrlicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    129,
    {
      warnID: 129,
      text: "Tagfahrlicht rechts defekt, Tagfahrlicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    130,
    {
      warnID: 130,
      text: "Anhängerblinker links defekt, Anhängerblinker überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    131,
    {
      warnID: 131,
      text: "Anhängerblinker rechts defekt, Anhängerblinker überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    132,
    {
      warnID: 132,
      text: "Anhängerbremslicht defekt, Anhängerbremslicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    133,
    {
      warnID: 133,
      text: "Anhängerschlusslicht links defekt, Anhängerschlusslicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    134,
    {
      warnID: 134,
      text: "Anhängerschlusslicht rechts defekt, Anhängerschlusslicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    138,
    {
      warnID: 138,
      text: "Fahrlichtsteuerung gestört. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.beam_levelling_control,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    139,
    {
      warnID: 139,
      text: "Leuchtweitenregulierung gestört. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    140,
    {
      warnID: 140,
      text: "Wischer defekt, Service notwendig",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    141,
    {
      warnID: 141,
      text: "Fahrzeugbeleuchtung gestört, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    142,
    {
      warnID: 142,
      text: "Regen- oder Lichtsensor defekt, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    148,
    {
      warnID: 148,
      text: "Parkbremse im Servicemodus",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.parking_brake,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    157,
    {
      warnID: 157,
      text: "ACC/PAS nicht verfügbar, Sensor verschmutzt. Bitte Sensor reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.acc,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    159,
    {
      warnID: 159,
      text: "ACC nicht verfügbar, elektrische Parkbremse lösen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.acc,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    164,
    {
      warnID: 164,
      text: "Kontakt Fahrertür defekt, vor dem Aussteigen P einlegen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.warning_filled,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    171,
    {
      warnID: 171,
      text: "ACC/PAS nicht verfügbar, Sensor verschmutzt. Bitte Sensor reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.acc,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    172,
    {
      warnID: 172,
      text: "ACC/PAS nicht verfügbar. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.acc,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    176,
    {
      warnID: 176,
      text: "Komponentenschutz aktiv, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 6,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    177,
    {
      warnID: 177,
      text: "ACC nicht verfügbar, PSM inaktiv",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.acc,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    179,
    {
      warnID: 179,
      text: "ACC/PAS nicht verfügbar. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.acc,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    181,
    {
      warnID: 181,
      text: "Getriebe gestört, Rückwärtsgang eventuell nicht verfügbar",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.pos_lever,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    188,
    {
      warnID: 188,
      text: "Systemfehler. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.electricity,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    189,
    {
      warnID: 189,
      text: "Systemfehler",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.electricity,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    190,
    {
      warnID: 190,
      text: "Airbagsystem Systemfehler. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.airbag_warning,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    196,
    {
      warnID: 196,
      text: "Fahrwerk ausgefallen, Fahrzeug verkehrssicher abstellen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.car_limousine_niveau,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    198,
    {
      warnID: 198,
      text: "Allradsystem ausgefallen, nur Heckantrieb verfügbar. Angepasste Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon["4WD"],
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    200,
    {
      warnID: 200,
      text: "Systemfehler",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.electricity,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    207,
    {
      warnID: 207,
      text: "Quersperre gestört, Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.cross_lock,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    208,
    {
      warnID: 208,
      text: "Quersperre gestört, angepasste Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.cross_lock,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    209,
    {
      warnID: 209,
      text: "Quersperre ausgefallen, angepasste Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.cross_lock,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    210,
    {
      warnID: 210,
      text: "Quersperre überlastet, angepasste Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.cross_lock,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    214,
    {
      warnID: 214,
      text: "Kombiinstrument-Akustik ausgefallen. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    215,
    {
      warnID: 215,
      text: "ParkAssistent-Akustik ausgefallen. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    217,
    {
      warnID: 217,
      text: "ParkAssistent nicht verfügbar. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    218,
    {
      warnID: 218,
      text: "ParkAssistent nicht verfügbar, Sensor verschmutzt. Bitte Sensor reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    219,
    {
      warnID: 219,
      text: "Airbag-Kontrolleuchte defekt, Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.airbag_warning,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    230,
    {
      warnID: 230,
      text: "Getriebetemperatur zu hoch, Belastung reduzieren",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.pos_lever,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    234,
    {
      warnID: 234,
      text: "Bitte sofort Batterie laden, eingeschränkte Fahrleistung möglich",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.warning_filled,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Ladestation,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    235,
    {
      warnID: 235,
      text: "Ladeklappe geöffnet, Ladeklappe schließen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    253,
    {
      warnID: 253,
      text: "SWA nicht verfügbar, Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.lane_change_assist,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    254,
    {
      warnID: 254,
      text: "SWA nicht verfügbar, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.lane_change_assist,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    255,
    {
      warnID: 255,
      text: "SWA nicht verfügbar, Sensor verschmutzt. Bitte Sensor reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.lane_change_assist,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    257,
    {
      warnID: 257,
      text: "Lenkunterstüzung ausgefallen. Erhöhter Krauftaufwand notwendig, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.steering_wheel,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    258,
    {
      warnID: 258,
      text: "Lenkunterstüzung eingeschränkt. Weiterfahrt möglich, Werkstatt aufsuchen.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.steering_wheel,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    259,
    {
      warnID: 259,
      text: "Lenkunterstüzung gestört. Erhöhter Krauftaufwand notwendig, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.steering_wheel,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    265,
    {
      warnID: 265,
      text: "Hybridfunktionen nicht verfügbar Elektrosystem überhitzt Nicht weiterfahren",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.car_limousine_warning_hybrid,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    267,
    {
      warnID: 267,
      text: "Bremskraftverteilung gestört, Fahrzeug verkehrssicher abstellen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.esp_psm_off,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    294,
    {
      warnID: 294,
      text: "Bordnetz gestört, Fahrzeug verkehrssicher abstellen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.voltage,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    300,
    {
      warnID: 300,
      text: "Fernlichtassistent nicht verfügbar. Weiterfahrt möglich, Fernlicht manuell betätigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    301,
    {
      warnID: 301,
      text: "Fernlichtassistent nicht verfügbar, keine Kamerasicht. Eventuell Scheibe reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    302,
    {
      warnID: 302,
      text: "Tempolimitanzeige nicht verfügbar, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.traffic_sign_recognition_j1,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    303,
    {
      warnID: 303,
      text: "Tempolimitanzeige zur Zeit eingeschränkt, keine Kamerasicht. Eventuell Scheine reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.traffic_sign_recognition_j1,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    307,
    {
      warnID: 307,
      text: "Tempolimitanzeige eingeschränkt, keine Navigationsdaten verfügbar",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.traffic_sign_recognition_j1,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    313,
    {
      warnID: 313,
      text: "Türsicherung gestört",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.childlock,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    315,
    {
      warnID: 315,
      text: "Bitte sofort Batterie laden",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.charging_station,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Ladestation,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    316,
    {
      warnID: 316,
      text: "Bitte E-Power aktivieren",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.warning_filled,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    323,
    {
      warnID: 323,
      text: "Tempolimitanzeige nicht verfügbar. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.traffic_sign_recognition_j1,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    324,
    {
      warnID: 324,
      text: "Tempolimitanzeige nicht verfügbar, Sie befinden sich außerhalb des Einsatzgebietes",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.traffic_sign_recognition_j1,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    325,
    {
      warnID: 325,
      text: "Fernlichtassistent nicht verfügbar, keine Kamerasicht. Eventuell Scheibe reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    326,
    {
      warnID: 326,
      text: "Motor kann nicht gestartet werden Ladestecker entfernen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.car_limousine_warning_hybrid,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    328,
    {
      warnID: 328,
      text: "Spurhalteassistent eingeschränkt, keine Kamerasicht. Eventuell Scheibe reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.lane_departure_warning_system_j1,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    329,
    {
      warnID: 329,
      text: "Spurhalteassistent eingeschränkt. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.lane_departure_warning_system_j1,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    330,
    {
      warnID: 330,
      text: "Spurhalteassistent nicht verfügbar. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.lane_departure_warning_system_j1,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    332,
    {
      warnID: 332,
      text: "Schlüssel nicht gefunden. Schlüssel in Ablagefach legen, siehe Betriebsanleitung.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.key,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    334,
    {
      warnID: 334,
      text: "Hochvoltsystem nicht spannungsfrei",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.voltage_high,
      prio: 1,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    336,
    {
      warnID: 336,
      text: "Bitte sofort Batterie laden",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.charging_station,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Ladestation,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    346,
    {
      warnID: 346,
      text: "E-Sound gestört. Weiterfahrt möglich, Werkstatt aufsuchen.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.car_limousine_sound_off,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    348,
    {
      warnID: 348,
      text: "Elektrische Parkbremse gestört. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.parking_brake,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    349,
    {
      warnID: 349,
      text: "Elektrische Parkbremse gestört. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.parking_brake,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    350,
    {
      warnID: 350,
      text: "Getriebe zu heiß. Fahrzeug verkehrssicher abstellen, Getriebe abkühlen lassen.",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.pos_lever,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    360,
    {
      warnID: 360,
      text: "Lenkung verriegelt, Werkstatt aufsuchen",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.steering_wheel,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    367,
    {
      warnID: 367,
      text: "P nicht verfügbar Fahrzeug kann wegrollen, elektrische Parkbremse einlegen",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.warning_filled,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    368,
    {
      warnID: 368,
      text: "Getriebe gestört, Rückwärtsgang nicht verfügbar",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.pos_lever,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    369,
    {
      warnID: 369,
      text: "Paddlenotbetrieb",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.pos_lever,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    374,
    {
      warnID: 374,
      text: "Hinterachslenkung ausgefallen, angepasste Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.hal_1_2,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    379,
    {
      warnID: 379,
      text: "Hinterachslenkung gestört, angepasste Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.hal_1_2,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    380,
    {
      warnID: 380,
      text: "Fahrzeugbeleuchtung defekt, Außenlicht hinten überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    381,
    {
      warnID: 381,
      text: "Spiegelblinker rechts defekt, Blinker überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    382,
    {
      warnID: 382,
      text: "Spiegelblinker links defekt, Blinker überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    383,
    {
      warnID: 383,
      text: "Mittleres Nebelschlusslicht defekt, Nebelschlusslicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    384,
    {
      warnID: 384,
      text: "Mittleres Rückfahrlicht defekt, Rückfahrlicht überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    387,
    {
      warnID: 387,
      text: "Nachtsichtassistent nicht verfügbar",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.night_vision,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    388,
    {
      warnID: 388,
      text: "Fehler Nachtsichtassistent, Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.night_vision,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    389,
    {
      warnID: 389,
      text: "Mehrere Lichter hinten defekt, Bremslicht und Blinker links überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    390,
    {
      warnID: 390,
      text: "Mehrere Lichter hinten defekt, Bremslicht und Blinker rechts überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    391,
    {
      warnID: 391,
      text: "Alle Bremslichter links defekt, Bremslichter überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    392,
    {
      warnID: 392,
      text: "Alle Bremslichter rechts defekt, Bremslichter überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    399,
    {
      warnID: 399,
      text: "Antriebssteuerung gestört, Fahrzeug verkehrssicher abstellen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.car_limousine_warning,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    412,
    {
      warnID: 412,
      text: "Bordnetz gestört, kein Wiederstart möglich. Service notwendig.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.voltage,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    414,
    {
      warnID: 414,
      text: "Batteriestand kritisch, Fahrleistung ist eingeschränkt",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Ladestation,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    422,
    {
      warnID: 422,
      text: "Notruffunktion temporär nicht verfügbar, Servicemodus aktiv",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.sos,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    424,
    {
      warnID: 424,
      text: "Notruffunktion, Servicemodus aktiviert",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.sos,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    431,
    {
      warnID: 431,
      text: "Porsche InnoDrive nicht verfügbar. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.pid,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    432,
    {
      warnID: 432,
      text: "Porsche InnoDrive nicht verfügbar. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.acc,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    434,
    {
      warnID: 434,
      text: "Tempostat: Fehler",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.tempomat,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    449,
    {
      warnID: 449,
      text: "Bordnetz gestört, Service notwendig",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.voltage,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    450,
    {
      warnID: 450,
      text: "Bordnetz gestört, Service notwendig",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.voltage,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    451,
    {
      warnID: 451,
      text: "Bordnetz gestört, Fahrzeug verkehrssicher abstellen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.voltage,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    452,
    {
      warnID: 452,
      text: "Motorlauf für elektrische Versorgung notwendig. Bordnetz gestört, Fahrzeug verkehrssicher abstellen.",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.voltage,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    468,
    {
      warnID: 468,
      text: "Kamera-System gestört, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    469,
    {
      warnID: 469,
      text: "Kamera-System eingeschränkt, keine Kamerasicht. Eventuell Scheibe reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    470,
    {
      warnID: 470,
      text: "Kamera-System nicht verfügbar. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    475,
    {
      warnID: 475,
      text: "Tagfahrlicht oder Standlicht vorne links überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    476,
    {
      warnID: 476,
      text: "Tagfahrlicht oder Standlicht vorne rechts überprüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    477,
    {
      warnID: 477,
      text: "Batterie schwach, Service notwendig",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.voltage,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    481,
    {
      warnID: 481,
      text: "PSM Sport, Fahrstabilisierung eingeschränkt",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.esp_psm_off,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    482,
    {
      warnID: 482,
      text: "Bremskraftverstärkung ausgefallen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.brake_failure,
      prio: 2,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    483,
    {
      warnID: 483,
      text: "Bremskraftverstärkung gestört",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.brake_failure,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    484,
    {
      warnID: 484,
      text: "Kombiinstrument ausgefallen, Service notwendig",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.warning_filled,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    486,
    {
      warnID: 486,
      text: "Tempolimitanzeige nicht verfügbar, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.traffic_sign_recognition_j1,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    487,
    {
      warnID: 487,
      text: "PAS gestört, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.ass_j1,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    488,
    {
      warnID: 488,
      text: "PAS eingeschränkt, Sensor verschmutzt. Bitte Sensor reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.ass_j1,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    490,
    {
      warnID: 490,
      text: "PAS eingeschränkt, siehe Betriebsanleitung",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.ass_j1,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    492,
    {
      warnID: 492,
      text: "Antrieb ist noch aktiv Zündung ausschalten",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.warning_filled,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    497,
    {
      warnID: 497,
      text: "Ausparkwarner eingeschränkt PSM inaktiv",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.back_out_assistant,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    498,
    {
      warnID: 498,
      text: "Kreuzungsassistent nicht verfügbar. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.cross_over_assistant,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    499,
    {
      warnID: 499,
      text: "Kreuzungsassistent nicht verfügbar, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.cross_over_assistant,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    500,
    {
      warnID: 500,
      text: "Kreuzungsassistent nicht verfügbar. Sensor verschmutzt, bitte Sensor reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.cross_over_assistant,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    501,
    {
      warnID: 501,
      text: "Ausstiegswarnung nicht verfügbar. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.exit_warning,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    502,
    {
      warnID: 502,
      text: "Ausparkwarner nicht verfügbar. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.back_out_assistant,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    503,
    {
      warnID: 503,
      text: "SWA/ASW nicht verfügbar. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    504,
    {
      warnID: 504,
      text: "SWA/APW nicht verfügbar. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    505,
    {
      warnID: 505,
      text: "ASW/APW nicht verfügbar. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    506,
    {
      warnID: 506,
      text: "SWA/ASW/APW nicht verfügbar. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    507,
    {
      warnID: 507,
      text: "SWA/ASW/APW nicht verfügbar, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    508,
    {
      warnID: 508,
      text: "SWA/ASW/APW nicht verfügbar, Sensor verschmutzt. Bitte Heckstoßfänger reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    543,
    {
      warnID: 543,
      text: "ParkAssistent vorne ausgefallen. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    544,
    {
      warnID: 544,
      text: "ParkAssistent hinten ausgefallen. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    545,
    {
      warnID: 545,
      text: "ParkAssistent nicht verfügbar, Sensor vorne verschmutzt. Bitte Sensor reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    546,
    {
      warnID: 546,
      text: "ParkAssistent nicht verfügbar, Sensor hinten verschmutzt. Bitte Sensor reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    557,
    {
      warnID: 557,
      text: "Eingriff wurde abgebrochen, siehe Betriebsanleitung",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.emergency_assist,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    558,
    {
      warnID: 558,
      text: "Nothaltefunktion nicht verfügbar. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.emergency_assist,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    559,
    {
      warnID: 559,
      text: "Nothaltefunktion gestört. Weiterfahrt möglich, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.emergency_assist,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    562,
    {
      warnID: 562,
      text: "Aktive Spurführung gestört, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.steering_wheel,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    563,
    {
      warnID: 563,
      text: "Aktive Spurführung gestört, Sensor verschmutzt. Bitte Sensor reinigen.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.steering_wheel,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Tankstelle,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    589,
    {
      warnID: 589,
      text: "ACC nicht verfügbar. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.acc,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    598,
    {
      warnID: 598,
      text: "Aktive Einparkunterstützung nicht verfügbar, Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.ipa,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    599,
    {
      warnID: 599,
      text: "Aktive Einparkunterstützung gestört. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.ipa,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    601,
    {
      warnID: 601,
      text: "Aktive Einparkunterstützung nicht verfügbar, Sensorik eingeschränkt. Siehe Betriebsanleitung.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.ipa,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    604,
    {
      warnID: 604,
      text: "Aktive Einparkunterstützung nicht verfügbar, Luftfederniveau zu hoch",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.ipa,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    611,
    {
      warnID: 611,
      text: "Parkvorgang abgebrochen, aktive Einparkunterstützung gestört. Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.ipa,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    615,
    {
      warnID: 615,
      text: "Manövrierassistent gestört. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.maneuver_assist_j1,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    616,
    {
      warnID: 616,
      text: "Manövrierassistent nicht verfügbar. Zustand temporär, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.maneuver_assist_j1,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    622,
    {
      warnID: 622,
      text: "Manövrierassistent hinten gestört. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.maneuver_assist_j1,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    623,
    {
      warnID: 623,
      text: "Manövrierassistent vorne gestört. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.maneuver_assist_j1,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    626,
    {
      warnID: 626,
      text: "Manövrierassistent eingeschränkt, Ultraschallsensorik vorne nicht verfügbar. Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.maneuver_assist_j1,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    627,
    {
      warnID: 627,
      text: "Manövrierassistent eingeschränkt, Ultraschallsensorik hinten nicht verfügbar. Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.maneuver_assist_j1,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    628,
    {
      warnID: 628,
      text: "Manövrierassistent eingeschränkt, Ultraschallsensorik nicht verfügbar. Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.maneuver_assist_j1,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    629,
    {
      warnID: 629,
      text: "Manövrierassistent eingeschränkt, Kamera vorne nicht verfügbar. Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.maneuver_assist_j1,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    630,
    {
      warnID: 630,
      text: "Manövrierassistent eingeschränkt, Kamera hinten nicht verfügbar. Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.maneuver_assist_j1,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    631,
    {
      warnID: 631,
      text: "Manövrierassistent eingeschränkt, Kamera nicht verfügbar. Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.maneuver_assist_j1,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    637,
    {
      warnID: 637,
      text: "Manövrierassistent nicht verfügbar, Luftfederniveau zu hoch",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.maneuver_assist_j1,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    638,
    {
      warnID: 638,
      text: "Bordsteinwarnung gestört. Weiterfahrt möglich, Service notwendig.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.parking_assistant,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    639,
    {
      warnID: 639,
      text: "Bordsteinwarnung nicht verfügbar Zustand temporär Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.parking_assistant,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    643,
    {
      warnID: 643,
      text: "Bordsteinwarnung nicht verfügbar, Sensorik eingeschränkt. Siehe Betriebsanleitung.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.parking_assistant,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    645,
    {
      warnID: 645,
      text: "Bordsteinwarnung nicht verfügbar, Luftfederniveau zu hoch",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.parking_assistant,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    663,
    {
      warnID: 663,
      text: "Notruffunktion, Servicevertrag abgelaufen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.sos,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    664,
    {
      warnID: 664,
      text: "Notruffunktion gestört, Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.sos,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    665,
    {
      warnID: 665,
      text:
        "Wegfahrsperre aktiv, Deaktivierung der Wegfahrsperre via Porsche Car Connect App oder Porsche Connect Portal",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.warning_filled,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    693,
    {
      warnID: 693,
      text: "Fahrzeugstart nicht möglich, Batterietemperatur zu niedrig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.propulsion_battery_temperature,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    694,
    {
      warnID: 694,
      text: "Getriebe gestört, eingeschränkte Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.pos_lever,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    695,
    {
      warnID: 695,
      text: "Getriebe gestört, eingeschränkte Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.pos_lever,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    698,
    {
      warnID: 698,
      text: "Zum Einlegen einer Fahrstufe Ladestecker entfernen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.plug,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    699,
    {
      warnID: 699,
      text: "Getriebe ausgefallen, Fahrzeug verkehrssicher abstellen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.pos_lever,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    712,
    {
      warnID: 712,
      text: "Fahrzeug nicht vollständig geladen. Ladeinfrastruktur mit reduzierter Leistung.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.charging_station_warning,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Ladestation]
    }
  ],
  [
    713,
    {
      warnID: 713,
      text: "Fahrzeug nicht vollständig geladen, Werkstatt aufsuchen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.charging_station_warning,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    714,
    {
      warnID: 714,
      text: "Ladeklappe nicht vollständig geschlossen, Ladeklappe prüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    726,
    {
      warnID: 726,
      text: "Schlüssel nicht erkannt. Auf Störsignale prüfen oder Position des Schlüssels ändern.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.key,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    727,
    {
      warnID: 727,
      text: "Fahrzeug automatisch entriegelt, Schlüssel aus Fahrzeuginnenraum entfernen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.key,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    728,
    {
      warnID: 728,
      text: "Fahrzeug automatisch entriegelt, Schlüssel aus Kofferraum entfernen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.key,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    731,
    {
      warnID: 731,
      text: "Kofferraum-Notentriegelung gestört Weiterfahrt möglich Service notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    735,
    {
      warnID: 735,
      text: "Aktive Einparkunterstützung eingeschränkt verfügbar, Laserscanner eingeschränkt. Siehe Betriebsanleitung.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.ipa,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    736,
    {
      warnID: 736,
      text: "Garagenpilot nicht verfügbar, Laserscanner gestört. Service notwendig, Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.ipa,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    739,
    {
      warnID: 739,
      text: "Ausfall Bremsenkühlung Geschwindigkeit reduzieren Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.warning_filled,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    745,
    {
      warnID: 745,
      text: "Staupilot temporär nicht verfügbarSensorik eingeschränkt",
      detailText: "",
      color: WarnMessageColor.white,
      icon: null,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    746,
    {
      warnID: 746,
      text: "Staupilot gestört, Service Notwendig, Weiterfahrt möglich",
      detailText: "",
      color: WarnMessageColor.white,
      icon: null,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    747,
    {
      warnID: 747,
      text: "Heckleuchte links defektService notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    748,
    {
      warnID: 748,
      text: "Heckleuchte rechts defektService notwendig",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.light_failure,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    761,
    {
      warnID: 761,
      text: "Restladezustand beim Erreichen bekannter Ladesäulen gering",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.charging_station,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Ladestation]
    }
  ],
  [
    762,
    {
      warnID: 762,
      text: "Restladezustand beim Erreichen bekannter Ladesäulen kritisch",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.charging_station,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Ladestation]
    }
  ],
  [
    763,
    {
      warnID: 763,
      text: "Bitte Reichweite beachten",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.charging_station,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Ladestation]
    }
  ],
  [
    764,
    {
      warnID: 764,
      text: "Bitte sofort laden",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.charging_station,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Ladestation]
    }
  ],
  [
    765,
    {
      warnID: 765,
      text: "Ladezustand der Batterie kritischManuellen Neustart durchführen,um Fahrzeug verkehrssicher abzustellen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.charging_station,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    772,
    {
      warnID: 772,
      text: "Intelligentes ortsbasiertes Fahrwerk ausgefallenSiehe Betriebsanleitung",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    777,
    {
      warnID: 777,
      text: "Laden nicht möglichLadeinfrastruktur prüfen",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.charging_station_warning,
      prio: 5,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Ladestation,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    779,
    {
      warnID: 779,
      text: "Antriebsleistung reduziert Weiterfahrt möglich Werkstatt aufsuchen",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.car_limousine_warning,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    780,
    {
      warnID: 780,
      text: "Antriebssteuerung gestört Weiterfahrt möglich Werkstatt aufsuchen",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.car_limousine_warning,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    781,
    {
      warnID: 781,
      text: "Antriebssystem gestört Elektroantrieb überhitzt Fahrzeug verkehrssicher abstellen",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.electric_motor_failure_cn,
      prio: 1,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    783,
    {
      warnID: 783,
      text: "Antriebsleistung reduziert",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.limited_performance_mode_turtle_cn,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    784,
    {
      warnID: 784,
      text: "Antriebsleistung eingeschränktBitte Fahrweise anpassen",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.limited_performance_mode_turtle_cn,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    792,
    {
      warnID: 792,
      text: "Übernahme Tempolimit eingeschränkt verfügbar",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.lim,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    793,
    {
      warnID: 793,
      text: "Übernahme Tempolimit nicht verfügbar",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.lim,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    794,
    {
      warnID: 794,
      text: "Übernahme Tempolimit in diesem Land nicht verfügbar",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.lim,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    797,
    {
      warnID: 797,
      text: "Assistenzsysteme eingeschränkt. PSM inaktiv. Bremsruck nicht verfügbar.",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    800,
    {
      warnID: 800,
      text: "P-Taste defekt, Zentraldisplay beachten. Werkstatt aufsuchen. Weiterfahrt möglich.",
      detailText: "",
      color: WarnMessageColor.red,
      icon: icon.parking_brake,
      prio: 1,
      // tslint:disable-next-line:max-line-length
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf,
        WarnMessageQuickActions.Parkbremse
      ]
    }
  ],
  [
    809,
    {
      warnID: 809,
      text: "Systemupdate wird durchgeführt. Fahrzeug nicht fahrbereit. Siehe Betriebsanleitung.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.update_error,
      prio: 4,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    811,
    {
      warnID: 811,
      text: "Systemupdate fehlgeschlagen. Fahrzeugfunktionen eingeschränkt. Siehe Betriebsanleitung.",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.update_error,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    813,
    {
      warnID: 813,
      text: "Assistenzsysteme nicht mehr verfügbar Bitte Fahrweise anpassen und Hinweis im Zentraldisplay beachten",
      detailText: "",
      color: WarnMessageColor.white,
      icon: icon.warning_filled,
      prio: 5,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung, WarnMessageQuickActions.Pannenruf]
    }
  ],
  [
    824,
    {
      warnID: 824,
      text: "Störung Kühlsystem, bitte Werkstatt aufsuchen",
      detailText: "",
      color: WarnMessageColor.yellow,
      icon: icon.engine_coolant_level,
      prio: 4,
      quickActions: [
        WarnMessageQuickActions.Bedienungsanleitung,
        WarnMessageQuickActions.Werkstatt,
        WarnMessageQuickActions.Pannenruf
      ]
    }
  ],
  [
    843,
    {
      warnID: 843,
      text: "Softwareupdate für Staupilot notwendig. Siehe Betriebsanleitung",
      detailText: "",
      color: WarnMessageColor.white,
      icon: null,
      prio: 7,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ],
  [
    849,
    {
      warnID: 849,
      text: "Staupilot temporär nicht aktivierbar.Aktivierbedingungen nicht erfüllt",
      detailText:
        "Aufgrund der aktuellen Fahrsituation kann der Staupilot nicht aktiviert werdenOder die Funktion ist im Car Menü ausgeschaltet",
      color: WarnMessageColor.white,
      icon: null,
      prio: 7,
      quickActions: [WarnMessageQuickActions.Bedienungsanleitung]
    }
  ]
])
