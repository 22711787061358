import { IconType } from "@pag/center/components/icon/types"

export const UPDATE_LAUNCHER_TILE_ORDER = "UPDATE_LAUNCHER_TILE_ORDER"

export type LauncherTileState = {
  tiles: LauncherTile[]
}

export type LauncherTile = Readonly<{
  text: string
  url?: string
  icon: IconType | IconType[]
  settings_list?: any
  count?: number
}>

export type updateLauncherTileOrderAction = {
  type: typeof UPDATE_LAUNCHER_TILE_ORDER
  payload: {
    tiles: LauncherTile[]
  }
}

export type LauncherTileActionType = updateLauncherTileOrderAction
