import i18n from "i18next"

import objtools from "@pag/utils/object-tools"
import { Profile, Timer } from "./types"
import { EChargingSubTabItemNames } from "./enums"

const make_label = (label: string) => Object.freeze({ label })
const make_list = (heading: string, ...entries: Array<any[]>) => Object.freeze({
    heading: make_label(heading),
    entries: objtools.populate("label", "second_line")(entries)
})

const timer_list_item_second_line = make_label("Charging_Departure_ListItem_Timer_SecondLine")
const add_timer_list_item = make_label("Charging_Departure_ListItem_Timer_AddNew")

const tabs = objtools.populate(
    "text",                                                 "itemName"
)(
  [ "ChargingRouter_SubTabBar_pag3-i18n-label_Overview",    EChargingSubTabItemNames.OVERVIEW  ],
  [ "ChargingRouter_SubTabBar_pag3-i18n-label_Departure",   EChargingSubTabItemNames.DEPARTURE ],
  [ "ChargingRouter_SubTabBar_pag3-i18n-label_Parkclimate", EChargingSubTabItemNames.CLIMATE   ]
)

const edit_list = make_list(
    "Charging_Departure_Edit_Heading",
    [ "Charging_Departure_Edit_ListItem_DepartureTime" ],
    [ "Charging_Departure_Edit_ListItem_PreconditionForDeparture" ],
    [ "Charging_Departure_Edit_ListItem_ExtendedSettings" ],
)

const edit_time_list = make_list(
    "Charging_Departure_DepartureTime_Heading",
    [ "Charging_Departure_DepartureTime_ListItem_Time"    ],
    [ "Charging_Departure_DepartureTime_ListItem_Date"    ],
    [ "Charging_Departure_DepartureTime_ListItem_Repeat"  ],
    [ "Charging_Departure_DepartureTime_ListItem_Weekday" ],
)

const extended_settings_list = make_list(
    "Charging_Departure_ExtendedSettings_Headline",
    [ "Charging_Departure_SmartCharging_ListItem"                                                      ],
    [ "Charging_Departure_PreferredChargingTime_ListItem"                                              ],
    [ "Charging_Departure_CurrentLocation_ListItem"                                                    ],
    [ "Charging_Departure_SaveLocation_ListItem",         "Charging_Departure_SaveLocation_SecondLine" ]
)

const smart_charging_values = objtools.populate(
    "label", "details"
)(
    [ "Charging_Departure_SmartCharging_Automatic", "Charging_Departure_SmartCharging_Automatic_Detail" ],
    [ "Charging_Departure_SmartCharging_Manual",    "Charging_Departure_SmartCharging_Manual_Detail"    ],
    [ "Charging_Departure_SmartCharging_Off",       "Charging_Departure_SmartCharging_Off_Detail"       ]
)

const weekdays_values: Readonly<readonly { label: string, short_label: string, date_index: number }[]> = objtools.populate(
    "label",         "short_label", "date_index"
)(
    [ "TC_monday",    "TC_mon",     1 ],
    [ "TC_tuesday",   "TC_tue",     2 ],
    [ "TC_wednesday", "TC_wed",     3 ],
    [ "TC_thursday",  "TC_thu",     4 ],
    [ "TC_friday",    "TC_fri",     5 ],
    [ "TC_saturday",  "TC_sat",     6 ],
    [ "TC_sunday",    "TC_sun",     0 ]
)

const location_actions = objtools.populate(
    "label", "end_message"
)(
    [ "Charging_Departure_Location_Delete", "Charging_Departure_Location_Delete_Helptext" ],
    [ "Charging_Departure_Location_Save",   "Charging_Departure_Location_Save_Helptext"   ]
)

const defaultTimerState = {
    targetCharge: 85,
    charge: true,
    precool: true,
    temperature: 22,
    date: (function () {
        const date = new Date()
        date.setDate(date.getDate() + 1)
        date.setHours(10)
        date.setMinutes(0)
        return date
    }()),
    repeating: true,
    weekdays: []
}

const createTimerId = (function () {
    let count = -1
    return function () {
        count += 1
        const id = Object.create(null)
        const uuid = `timer-${count}`
        Object.defineProperty(id, "valueOf", {
            value: () => uuid,
            enumerable: false,
            writable: false
        })
        return Object.freeze(id)
    }
}())

function newTimer(): Timer {
    const state = Object.assign(Object.create(null), defaultTimerState)
    state.date = new Date(state.date)
    Object.defineProperty(state, "id", {
        value: createTimerId(),
        enumerable: false,
        writable: false
    })
    return Object.seal(state)
}

const defaultProfileState = {
    name: "ChargingRouter_SubTabBar_pag3-i18n-ChargingManagementProfileOverview_label_NewProfile",
    address: undefined,
    minCharge: 85,
    optimized: true,
    start: new Date(0),
    end: (function () {
        const date = new Date(0)
        date.setUTCHours(6)
        return date
    }()),
}

// @NOTE(kirill): We use objects as IDs. React requires lists to have a unique string for its elements so we insert a
// poor man’s uuid into ID-object `valueOf` method. To get the string ID just convert it with String().

// @NOTE(kirill): We are in trouble whenever we need more than nine quadrillion of timers.

const createProfileId = (function () {
    let count = -1
    return function () {
        count += 1
        const id = Object.create(null)
        const uuid = `profile-${count}`
        Object.defineProperty(id, "valueOf", {
            value: () => uuid,
            enumerable: false
        })
        return Object.freeze(id)
    }
}())

function newProfile(id?: object | undefined): Profile {
    const state = Object.assign(Object.create(null), defaultProfileState)
    Object.defineProperty(state, "id", {
        value: Object.freeze(id !== undefined ? id : createProfileId())
    })
    state.name = i18n.t(defaultProfileState.name)
    state.start = new Date(state.start)
    state.end = new Date(state.end)
    return Object.seal(state)
}

const getForemostDate = (timer: Timer): Date => {
  const now = new Date()
  now.setHours(9)
  now.setMinutes(11)

  return (
    timer.repeating
    ? (function () {
      if (timer.weekdays.length === 0) {
        return timer.date
      }

      const is_today = timer.weekdays.find((weekday) => weekday.date_index === now.getDay()) !== undefined
      if (
        is_today &&
        (
          now.getHours() < timer.date.getHours() ||
          (
              now.getHours() === timer.date.getHours() &&
              now.getMinutes() <= timer.date.getMinutes()
          )
        )
      ) {
        const date = new Date(now)
        date.setHours(timer.date.getHours())
        date.setMinutes(timer.date.getMinutes())
        return date
      }

      const current_week_day_index = timer.weekdays.findIndex((weekday) => weekday.date_index > now.getDay())
      if (current_week_day_index !== -1) {
        const week_day_index = timer.weekdays[current_week_day_index].date_index
        const date = new Date(now)
        date.setHours(timer.date.getHours())
        date.setMinutes(timer.date.getMinutes())
        date.setDate(date.getDate() + (week_day_index - date.getDay()))
        return date
      }

      // at this point it's next week
      {
        const week_day_index = timer.weekdays[0]
        const date = new Date(now)
        date.setHours(timer.date.getHours())
        date.setMinutes(timer.date.getMinutes())
        date.setDate(date.getDate() + ((7 - date.getDay()) + week_day_index.date_index))
        return date
      }
    }())
    : timer.date
  )
}

const getForemostTimer = (timers: Timer[]): Timer | undefined => {
  const now = new Date()
  now.setHours(9)
  now.setMinutes(11)

  let timer: Timer | undefined

  timers.forEach(function (candidate) {
    const next_date = getForemostDate(candidate)
    if (
      next_date > now &&
      (
        timer === undefined ||
        timer.date > next_date
      )
    ) {
      timer = candidate
    }
  })

  return timer
}

export default Object.freeze({
    tabs,
    timer_list_item_second_line,
    add_timer_list_item,
    edit_list,
    edit_time_list,
    extended_settings_list,
    location_actions,
    smart_charging_values,
    weekdays_values,

    newTimer,
    newProfile,

    getForemostDate,
    getForemostTimer
})
