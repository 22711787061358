import {
  SET_CURRENT_RADIO_BUTTON_LIST_ITEM,
  SET_VISIBLE_SETTINGS_LIST,
  UPDATE_DETAIL_SCREEN_OVERLAY_VISIBLE,
  SET_PREFERENCE_VALUE,
  SET_ROUTE_OVERVIEW_VISIBLE
} from "./types"

export const setVisibleSettingsList = (visibleList: any) => ({
  type: SET_VISIBLE_SETTINGS_LIST,
  visibleList
})

export const setcurrentRadioButtonListItem = (itemName: string) => ({
  type: SET_CURRENT_RADIO_BUTTON_LIST_ITEM,
  itemName
})

export const updateDetailScreenOverlayVisible = (visible: boolean) => ({
  type: UPDATE_DETAIL_SCREEN_OVERLAY_VISIBLE,
  visible
})

export const setPreferenceValue = (key: string, value: any) => ({
  type: SET_PREFERENCE_VALUE,
  key,
  value
})

export const setRouteOverviewVisible = (value: boolean) => ({
  type: SET_ROUTE_OVERVIEW_VISIBLE,
  value
})
