import {
  SET_CLIMATE_ON,
  SET_CLIMATE_OFF,
  SET_ECO_MODE,
  SET_AC,
  SET_CLIMATE_EBF,
  SET_DRIVER_STYLE,
  SET_CODRIVER_STYLE,
  SET_DRIVER_AIRFLOW_ON,
  SET_DRIVER_AIRFLOW_OFF,
  SET_CODRIVER_AIRFLOW_ON,
  SET_CODRIVER_AIRFLOW_OFF,
  SET_HANDLE_INTENCITY,
  SET_HANDLE_POSITION,
  SET_AUX_COOLING,
  SET_TEMPERATURE,
  SET_REMOTE,
  SET_AUX_ZONE,
  State,
  Action,
  SET_ACTIVE_LIST_ITEM,
} from "./types"
import data from "./data"

const initialState: State = {

  // Precool/heat

  aux_cooling: data.aux_precondition[0],
  temperature: 22,
  remote: false,
  aux_zones: data.aux_zones.slice(0, 2), // driver, codriver
  active_list_item: undefined,

  // Airflow

  active: true,
  eco: true,
  ac: true,
  ebf: false,
  outside_temp: 25,

  driver_airflow: [],

  driver_style: undefined,
  
  driver_flow_center_intencity: 1,
  driver_flow_center_position: data.LEFT_CENTER_SIDE_DIFFUSE,

  driver_flow_side_intencity: 1,
  driver_flow_side_position: data.LEFT_SIDE_DIFFUSE,

  codriver_airflow: [],

  codriver_style: undefined,
  
  codriver_flow_center_intencity: 1,
  codriver_flow_center_position: data.RIGHT_CENTER_SIDE_DIFFUSE,

  codriver_flow_side_intencity: 1,
  codriver_flow_side_position: data.RIGHT_SIDE_DIFFUSE
}

export default Object.freeze(function climateReducer(
  state: State = initialState,
  action: Action
): State {
  switch (action.type) {

  case SET_ACTIVE_LIST_ITEM:
    return {
      ...state,
      active_list_item: action.value
    }

  case SET_AUX_COOLING: {
    return (
      data.aux_precondition.includes(action.value)
      ? {
        ...state,
        aux_cooling: action.value
      }
      : state
    )
  }

  case SET_TEMPERATURE: {
    return {
      ...state,
      temperature: action.value
    }
  }

  case SET_AUX_ZONE: {
    return (
      data.aux_zones.includes(action.value)
      ? {
        ...state,
        aux_zones: (
          state.aux_zones.includes(action.value)
          ? state.aux_zones.filter((zone) => zone !== action.value)
          : state.aux_zones.concat([ action.value ])
        )
      }
      : state
    )
  }

  case SET_REMOTE: {
    return {
      ...state,
      remote: action.value
    }
  }

  case SET_CLIMATE_ON: {
    return {
      ...state,
      active: true,
      eco: true,
      ac: true
    }
  }

  case SET_CLIMATE_OFF: {
    return {
      ...state,
      active: false,
      eco: false,
      ac: false,
    }
  }
  
  case SET_ECO_MODE: {
    return {
      ...state,
      active: true,
      eco: action.value
    }
  }
  
  case SET_AC: {
    return {
      ...state,
      active: true,
      ac: action.value
    }
  }

  case SET_CLIMATE_EBF: {
    return {
      ...state,
      ebf: action.value,
    }
  }

  case SET_CODRIVER_STYLE: {
    return {
      ...state,
      codriver_airflow: [],
      codriver_style: action.style
    }
  }

  case SET_DRIVER_STYLE: {
    return {
      ...state,
      driver_airflow: [],
      driver_style: action.style
    }
  }
  
  case SET_DRIVER_AIRFLOW_ON: {
    return {
      ...state,
      driver_style: undefined,
      driver_airflow: (
        state.driver_airflow.includes(action.airflow)
        ? state.driver_airflow.slice()
        : state.driver_airflow.concat([ action.airflow ])
      )
    }
  }
  
  case SET_DRIVER_AIRFLOW_OFF: {
    return {
      ...state,
      driver_airflow: (
        state.driver_airflow.includes(action.airflow)
        ? state.driver_airflow.filter((item) => item !== action.airflow)
        : state.driver_airflow.slice()
      )
    }
  }
  
  case SET_CODRIVER_AIRFLOW_ON: {
    return {
      ...state,
      codriver_style: undefined,
      codriver_airflow: (
        state.codriver_airflow.includes(action.airflow)
        ? state.codriver_airflow.slice()
        : state.codriver_airflow.concat([ action.airflow ])
      )
    }
  }
  
  case SET_CODRIVER_AIRFLOW_OFF: {
    return {
      ...state,
      codriver_airflow: (
        state.codriver_airflow.includes(action.airflow)
        ? state.codriver_airflow.filter((item) => item !== action.airflow)
        : state.codriver_airflow.slice()
      )
    }
  }

  case SET_HANDLE_INTENCITY: {
    if (action.handle === data.driver_side_handle) {
      return {
        ...state,
        driver_flow_side_intencity: action.value
      }
    } else if (action.handle === data.driver_center_handle) {
      return {
        ...state,
        driver_flow_center_intencity: action.value
      }
    } else if (action.handle === data.codriver_side_handle) {
      return {
        ...state,
        codriver_flow_side_intencity: action.value
      }
    } else if (action.handle === data.codriver_center_handle) {
      return {
        ...state,
        codriver_flow_center_intencity: action.value
      }
    } else {
      return state
    }
  }

  case SET_HANDLE_POSITION: {
    if (action.handle === data.driver_side_handle) {
      return {
        ...state,
        driver_flow_side_position: action.value
      }
    } else if (action.handle === data.driver_center_handle) {
      return {
        ...state,
        driver_flow_center_position: action.value
      }
    } else if (action.handle === data.codriver_side_handle) {
      return {
        ...state,
        codriver_flow_side_position: action.value
      }
    } else if (action.handle === data.codriver_center_handle) {
      return {
        ...state,
        codriver_flow_center_position: action.value
      }
    } else {
      return state
    }
  }

  default: 
    return state
  }
})
