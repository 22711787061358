import {
  SET_CAR_VALUE,
  SET_CAR_VALUE_LIST,
  State
} from "./types"
import data from "./data"
import initial from "@pag/center/config/initial"

const {
  drive_mode_gravel,
  drive_mode_sport,
  drive_mode_sport_plus,
  chassis_normal,
  chassis_sport,
  chassis_sport_plus,
  level_high,
  level_normal,
  level_low,
  level_lowered,
  recuperation_on,
  recuperation_off
} = data

type Action = {
  [index: string]: any
  type: string
}

export default function reducer(
  state = initial.car,
  action: Action
): State {
  switch (action.type) {

  case SET_CAR_VALUE:
    {
      const new_state = Object.assign({}, state, {
        [action.key]: action.value
      })

      if (action.key === "Car_Drive_DriveMode_ListItem") {
        if (action.value === drive_mode_gravel) {
          new_state.Car_Drive_Level_ListItem = level_high
          new_state.Car_Drive_Recuperation_ListItem = recuperation_on
        } else if (action.value === drive_mode_sport) {
          new_state.Car_Drive_Chassis_ListItem = chassis_sport
          new_state.Car_Drive_Recuperation_ListItem = recuperation_on
        } else if (action.value === drive_mode_sport_plus) {
          new_state.Car_Drive_Chassis_ListItem = chassis_sport_plus
          new_state.Car_Drive_Recuperation_ListItem = recuperation_off
        } else {
          new_state.Car_Drive_Chassis_ListItem = chassis_normal
          new_state.Car_Drive_Recuperation_ListItem = recuperation_off
        }

        if (new_state.Car_Drive_Chassis_ListItem === chassis_sport) {
          new_state.Car_Drive_Level_ListItem = level_low
        } else if (new_state.Car_Drive_Chassis_ListItem === chassis_sport_plus) {
          new_state.Car_Drive_Level_ListItem = level_lowered
        } else {
          new_state.Car_Drive_Level_ListItem = level_normal
        }
      }

      return new_state
    }

  case SET_CAR_VALUE_LIST:
    {
      const new_state = Object.assign({}, state);
      Object.keys(action.values).forEach(function (key) {
        new_state[key] = action.values[key]
      });
      return new_state
    }

  default:
    return state
  }
}
