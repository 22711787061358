import object_tools from "@pag/utils/object-tools"
import icon from "@pag/center/components/icon/icons"
import { Contact, PhoneNumber, PhoneNumberKind, Call, CallKind } from "@pag/center/views/phoneScreen/types"

const today_date = new Date()
const this_year = today_date.getFullYear()
const this_month = today_date.getMonth()
const this_day = today_date.getDate()

const today = (hour = 0, minute = 0, second = 0) => (
  new Date(this_year, this_month, this_day, hour, minute, second)
)

const populate_phone_number_kinds = object_tools.populate(
    "label"
)
const [ mobile_tel, private_tel ] = populate_phone_number_kinds(
  [ "Phone_SlideOut_PhoneNumber_Mobile"  ],
  [ "Phone_SlideOut_PhoneNumber_Private" ]
) as Readonly<PhoneNumberKind[]>

const populate_phone_numbers = object_tools.populate_loose(
    "number",          "kind",     "contact"
)
const phone_numbers = populate_phone_numbers(
  [ "+03284920523",    mobile_tel  ],
  [ "+393920250190",   undefined   ],
  [ "+49151223844",    mobile_tel  ],
  [ "+49151223844",    private_tel ],
  [ "+4915122395844",  undefined   ],
  [ "+491703279617",   undefined   ],
  [ "015142395344",    mobile_tel  ],
  [ "016453942911711", mobile_tel  ],
  [ "017087817052",    undefined   ]
) as Readonly<PhoneNumber[]>

const populate_contacts = object_tools.populate(
    "firstName", "lastName",  "phone_numbers"
)
const contacts = populate_contacts(
  [ "Maria",     "Bianchi", [ phone_numbers[2],
                              phone_numbers[3] ] ],
  [ "John",      "Doe",     [ phone_numbers[6] ] ],
  [ "Andreas",   "Falk",    [ phone_numbers[7] ] ],
  [ "Li",        "Ming",    [ phone_numbers[0] ] ]
) as Readonly<Contact[]>

contacts.forEach((contact) => Object.freeze(contact.phone_numbers))
phone_numbers.forEach((number) => {
  number.contact = contacts.find((contact) => contact.phone_numbers.includes(number))
  Object.freeze(number)
})

const populate_call_types = object_tools.populate(
    "icon"
)
const [ incoming, outgoing, missed ] = populate_call_types(
  [ icon.phone_incoming_call ],
  [ icon.phone_outgoing_call ],
  [ icon.phone_missed_call   ]
) as Readonly<CallKind[]>

const populate_calls = object_tools.populate(
    "kind",   "number",         "date"
)
const calls = populate_calls(
  [ outgoing, phone_numbers[4], today(13, 0) ],
  [ outgoing, phone_numbers[5], today(14, 0) ],
  [ outgoing, phone_numbers[5], today(14, 1) ],
  [ outgoing, phone_numbers[5], today(14, 2) ],
  [ missed,   phone_numbers[3], today(13, 2) ],
  [ incoming, phone_numbers[8], today(10, 2) ],
  [ missed,   phone_numbers[1], today(14, 2) ]
) as Readonly<Call[]>

const favorites = Object.freeze([
  phone_numbers[2],
  phone_numbers[6],
  phone_numbers[7]
]) as Readonly<PhoneNumber[]>

export default Object.freeze({
  contacts,
  calls,
  favorites
})
