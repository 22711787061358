import object_tools from "@pag/utils/object-tools"
import radio_lists from "@pag/center/components/player/audioPlayer/lists/radio"
import icon from "@pag/center/components/icon/icons"
import porsche_wappen from "@pag/center/assets/porsche-wappen.png"

const populate_list = object_tools.populate(
    "label",                                            "icon",            "list"
)

const overview = populate_list(
  [ "Media_Apple_Music_List_Overview_Category_For_You", icon.apple_love,   []                                       ],
  [ "Media_Apple_Music_List_Overview_Category_Radio",   icon.radio,        Object.freeze(radio_lists.radio.slice()) ],
  [ "Porsche Playlists",                                porsche_wappen,    []                                       ]
)

export default overview
