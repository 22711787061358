import React from "react"

import "./distanceDestination.scss"

interface IDistanceDestination {
  formattedDistance: string
}

const DistanceDestination: React.FC<IDistanceDestination> = (props: IDistanceDestination) => (
  <div className="distance-destination-wrapper">
    <div className="distance__value">{props.formattedDistance}</div>
  </div>
)

export default DistanceDestination
